<template>
  <section class="content">
    <slot/>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>