import { domain } from "../../domain";

export {
    role,
    createRole,
    deleteRole,
}

function role(per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/get-role?id=${id}`;
    } else if(name){
        return `${domain}/pos/get-role?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}`
    } else {
        return `${domain}/pos/get-role?per_page=${per_page}&page=${page}&orderBy=${orderBy}`;
    }
}

const createRole = `${domain}/pos/insert-role`;

function deleteRole(id){
    return `${domain}/pos/get-permission?id=${id}`;
}



