<template>
  <div v-if="checkNavBar">
    <li class="nav-item" v-if="!dropdown && checkPermission(permission)">
      <router-link class="nav-link " :to="`${link}`" :class="{ active : isActive() }" >
        <i :class="`nav-icon ${icon} mr-2 pl-1`" v-if="icon"></i>
        <p> {{ $t(name) }} </p>
      </router-link>
    </li>
    <li :id="name" class="nav-item" :class="{'menu-open' : isTreeActive()}" v-if="dropdown && checkAllPermission()">
      <a class="nav-link" :class="{'acitve bg-primary' : isTreeActive()}" @click="handleClick(name)" style="cursor:pointer">
          <i :class="`nav-icon ${icon} pl-1`" v-if="icon"></i> &nbsp;
          <p>
              {{ $t(name) }}
              <i class="right fas fa-angle-left"></i>
          </p>
      </a>
      <ul class="nav nav-treeview" :style="`${isTreeActive() ? 'display:block' : 'display:none'}`">
          <li class="nav-item" v-for="(item, index) in subItems" :key="index">
              <router-link v-if="checkPermission(item.permission)" :id="`${item.label}`" :to="`${item.link}`" :target="item.link == '/pos/add-sale' ? '_blank' : ''" class="nav-link" :class="{'active' : isSubActive(item.link)}">
                  <i :class="`nav-icon ${item.icon} pl-1 mr-1`" v-if="item.icon"></i>
                  <p>{{$t(item.label)}}</p>
              </router-link>
          </li>
      </ul>
    </li>
  </div>
</template>

<script>
import { getSidebar } from "@/networks/services/dashboard/navbar.service.js"

export default {
  components : {  },
  props: {
    permission: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    link: {
      type: [String, Object],
      default: null,
    },
    name: {
      type: [String, Object],
      default: null,
    },
    subItems: {
      type: [String, Object],
      default: null,
    },
    dropdown: {
      type: [Boolean, Object],
      default: false,
    },
    navBar: {
        type: [Array, Object],
        default: [],
    },
    checkNavBar: {
        type: [Boolean],
        default: false,
    },
  },
  data(){
    return {
      navbars : [],
      check : false,
    }
  },
  methods: {
    isActive() {
      if (typeof this.link === "object") {
        return this.$route.name === this.link.name;
      } else {
        return this.$route.path === this.link;
      }
    },
    isSubActive(path = null) {
      if (typeof this.subItems === "object") {
          const index = this.subItems.map(object => object.link).indexOf(this.$route.path);
          if(this.subItems[index]){
            return (this.$route.path === this.subItems[index].link) && (this.subItems[index].link === path);
          }
          else if(path == '/role'){
            const key = this.subItems.map(object => object.link).indexOf(path);
            if(this.subItems[key]){
              return (this.subItems[key].link == path) && (this.$route.path == '/role-permission')
            }
          } 
          else if(path == '/company-list'){
            const key = this.subItems.map(object => object.link).indexOf(path);
            if(this.subItems[key]){
              return (this.subItems[key].link == path) && (this.$route.path == '/company-list-detail/'+this.$route.params.id)
            }
          } 
          else if(path == '/company-role'){
            const key = this.subItems.map(object => object.link).indexOf(path);
            if(this.subItems[key]){
              return (this.subItems[key].link == path) && (this.$route.path == '/company-role-permission')
            }
          } 
          else if(path == '/product'){
            const key = this.subItems.map(object => object.link).indexOf(path);
            if(this.subItems[key]){
              return (this.subItems[key].link == path) && (this.$route.path == '/product/attribute')
            }
          }
          else if(path == '/company-info'){
            const key = this.subItems.map(object => object.link).indexOf(path);
            if(this.subItems[key]){
              return (this.subItems[key].link == path) && (this.$route.path == '/edit-company-info')
            }
          } 
          else {
            return false;
          }
      }
    },
    isTreeActive() {
      if (typeof this.subItems === "object") {
        const index = this.subItems.map(object => object.link).indexOf(this.$route.path);
          if(this.subItems[index]){
            return this.$route.path === this.subItems[index].link;
          }else{
            if(this.subItems[0].link == '/role' && (this.$route.path == '/role-permission')){
              return true;
            } 
            else if(this.subItems[0].link == '/company-list' && (this.$route.path == '/company-list-detail/'+this.$route.params.id)){
              return true;
            }
            else if(this.subItems[0].link == '/company-list' && (this.$route.path == '/company-role-permission')){
              return true;
            }
            else if(this.subItems[0].link == '/supplier' && (this.$route.path == '/product/attribute')){
              return true;
            }
            else if(this.subItems[0].link == '/about-me' && (this.$route.path == '/edit-company-info')){
              return true;
            }
            else {
              return false;
            }
          }
      }
    },
    handleClick(e){
      // document.querySelector(`#${e}`).classList.toggle('menu-open');
      // document.querySelector(`#${e} ul`).classList.toggle('d-block');

      if($(`[id='${e}'] ul`).css('display') == 'block') {
          $(`[id='${e}']`).removeClass('menu-open');
          $(`[id='${e}']`).removeClass('menu-is-opening');
          // $(`#${e} ul`).css({'display' : 'none'});
          $(`[id='${e}'] ul`).fadeOut();
        } else {
          $(`[id='${e}']`).addClass('menu-open');
          $(`[id='${e}']`).addClass('menu-is-opening');
          // $(`#${e} ul`).css({'display' : 'block'});
          $(`[id='${e}'] ul`).fadeIn();
        }
    },
    checkPermission(per){
      let check = false;
      check = this.navbars.includes(per)
      return check;
    },
    checkAllPermission(){
      for(let i = 0; i<=this.subItems.length; i++){
        if(this.subItems[i]){
          if(this.navbars.includes(this.subItems[i].permission)){
            return true;
          }
        }
      }
      return false;
    },
    async getData(){
      // this.navbars = await getSidebar();
      // this.check = true;
      // console.log('check');

      this.navbars = this.navBar;
      this.check = this.checkNavBar;

      // console.log(this.navBars)
    }
  },
  mounted(){
    //  $('[data-widget="treeview"]').Treeview('init');
    this.getData();

  }
}
</script>

<style>
</style>