import { domain } from "../../domain";

export {
    expense, 
    create,
    // store,
    // update
}

function expense(expense_category_id=null,per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/get-expense?id=${id}`;
    } else if(name){
        return `${domain}/pos/get-expense?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}&expense_category_id=${expense_category_id}`
    } else {
        return `${domain}/pos/get-expense?per_page=${per_page}&page=${page}&orderBy=${orderBy}&expense_category_id=${expense_category_id}`;
    }
}

const create = `${domain}/pos/create-expense`;
// const store = `${domain}/pos/store-product-discount`;
// const update = `${domain}/pos/update-product-discount`;



