<template>
    <div class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
        <form action="ohyes" method="POST" id='create_form' @submit.prevent="form_submit()">
            <input id="create_status" type="hidden" value="error">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Create Product Category</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true" >&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group mb-1">
                        <label for="com">
                            Main Category<span class="text-danger">*</span>
                        </label>
                        <select v-model="main_cat" id="main" @change="changeMainCat()" class="border-page-color selectPicker form-control" data-size="5" data-live-search="true" required>
                            <option value="">PLEASE SELECT ONE</option>
                            <option :value="main.id" v-for="main in mainCategories.filter(e => e.parent_id == 0)" :key="main.id">{{ main.name }}</option>
                        </select>
                    </div>
                    <div class="form-group mb-1">
                        <label for="com">
                            Sub Category<span class="text-danger">*</span>
                        </label>
                        <select v-model="sub_cat" id="sub" class="border-page-color selectPicker form-control" data-size="5" data-live-search="true" required>
                            <option value="">PLEASE SELECT ONE</option>
                            <template v-if="subCat.length > 0">
                                <option :value="sub.id" v-for="sub in subCat" :key="sub.id">{{ sub.name }}</option>
                            </template>
                        </select>
                    </div>
                    <div class="form-group mb-1">
                        <label>
                            Name <span class="text-danger">*</span>
                        </label>
                        <input v-model="name" type="text" name="name" class="form-control" required>
                    </div>
                </div>
                <div class="modal-footer">
                    <button :disabled="isCreate" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isCreate" src="../../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" 
                        @click="triggerReset()">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>  
</template> 
<script>
import { frm_submit } from '@/networks/services/helper/button'
import { user } from '@/networks/services/auth/user.service'

export default {
    props : {
        system_cat_id: {
            type: [String, Object, Number],
            default: null,
        },
        mainCategories : {
            type : [Object, Array],
            default : null
        }
    },
    data(){
        return {
            name : null,
            tbl : "company_categories",
            per : "company_category",
            main_cat : '',
            sub_cat : '',
            subCat : [],
            isCreate : false,
        }
    },
    methods: {
        async selectOption(){
            await this.$nextTick();
            $('.selectPicker').selectpicker('refresh');       
            $('.selectPicker').selectpicker(); 
        },
        triggerReset(){
            this.main_cat = '';
            this.sub_cat = '';
            this.subCat = [];
            this.isCreate = false;
        },
        async changeMainCat(){
            if(this.main_cat == '') {
                this.subCat = [];
                this.sub_cat = '';
            }
            else {
                this.subCat = this.mainCategories.filter(e => e.parent_id == this.main_cat);
            }
            this.selectOption();
        },
        async form_submit(){
            this.isCreate = true;
            let com_id = user('company_id');
            var sys_cat_id = null;
            if(com_id == 0){
                sys_cat_id = 0;
            } else {
                sys_cat_id = this.system_cat_id;
            }
            let data = {
                name : this.name,
                system_category_id : sys_cat_id,
                company_id : com_id,
                main_category_id : this.sub_cat,
                tbl : this.tbl,
                per : this.per
            };
            await frm_submit(data);
            this.triggerReset();
            this.$emit("createModal",$('#create_status').val()); 

        },
    },
    mounted(){
        $(document).ready(function() {
            $('.chosen2').select2();
        });
    }
}
</script>
