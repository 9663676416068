<template>
    <div class="card">
        <div class="card-header">
            <button v-if="btnBack" @click="$router.back()" class="btn btn-dark btn-sm mb-2 mr-2"><i class="fas fa-reply-all"></i>&nbsp; BACK</button>
            <button v-if="btnCreate" class="btn btn-system btn-sm mb-2 mr-2" data-toggle='modal' data-target='#createModal'><i class="fa fa-plus-circle"></i> Add New Photo</button>
            <span class="badge badge-info float-right">{{$t("product_photo")}}</span>
        </div>
        <div class="card-body pt-0">
            <!-- no data -->
            <div v-if="!product.photo && !product_photos.length" class="card-body text-center">
                <span class="txt-system">No Data...</span>
            </div>
            <div class="row">
                <div v-if="product.photo" class="col-lg-2 col-md-4 col-sm-6 col-6 mt-3 img photo-default" :class="`${btnDelete ? 'icon-delete' : ''}`" data-delete="&#10008;"  data-default="Default" @click="actionImg($event,true,product.id)" ref="action">
                    <img :src="resource + product.photo" alt="" class="w-100 rounded outline-system">
                </div>
                <div v-for="(product_photo, index) in product_photos" :key="product_photo.id" class="col-lg-2 col-md-4 col-sm-6 col-6 mt-3 img" :class="`${btnDelete ? 'icon-delete' : ''} ${btnEdit ? 'icon-edit' : ''}`" data-delete="&#10008;"  data-edit="&#9998;" ref="action"  @click="actionImg($event,false,product_photo.id,index)">
                    <img :src="resource + product_photo.photo" alt="" class="w-100 rounded outline-system">
                </div>
            </div>
            <!-- Processing loader  -->
            <div v-if="pageLoad" class="card position-absolute " style="z-index:10; left: 45%; top: 50%;">
                <div class="card-body">
                    <span class="txt-system">Processing...</span>
                </div>
            </div>
            <input type="hidden" id="hiddenDeleteImgDefault" @click="removeImg($event,true)" :value="id">
            <input type="hidden" id="hiddenDeleteImg" @click="removeImg($event,false)" :value="id">
        </div> 
    </div>      
    <AddProductPhotoViewVue v-if="btnCreate" :product_id="product.id" @updateModal="JustUpdateModal($event)" />
    <EditProductPhotoView v-if="btnEdit" :eproduct_photo="ephoto" @updateModal="JustUpdateModal($event)"/>
</template>
<script>
import { resource } from '@/networks/domain'
import AddProductPhotoViewVue from './AddProductPhotoView.vue';
import EditProductPhotoView from '@/components/product/EditProductPhotoView'
import { remove , deleteImg} from '@/networks/services/helper/button';
export default{
    emits: ["updateData"],
    components :{
        AddProductPhotoViewVue , 
        EditProductPhotoView
    },
    props: {
      btnBack : { Boolean },
      pageLoad : { Boolean },
      btnDelete : { Boolean },
      btnEdit : { Boolean },
      btnCreate : { Boolean },
      product : { 
        type: [String, Object, Array],
        default: null, 
      },
      product_photos : { 
        type: [String, Object, Array],
        default: null, 
      },
    },
    data() {
        return {
            id : 0,
            data : [],
            resource : resource,
            loading : false,
            ephoto : '',
        }
    },
    methods: {
        img(obj){
            return resource+obj;
        },
        actionImg(event,is_default,id,index = null){
            this.id = id;
            if(!this.btnDelete){ return }
            if(!this.btnEdit){ return }

            //delete IMG
            if(this.$refs.action.length){
                if(
                    event.offsetX >= this.$refs.action[0].clientWidth - 32 && 
                    event.offsetX <= this.$refs.action[0].clientWidth - 12 && 
                    event.offsetY >= 3 && event.offsetY <= 22 && 
                    $(event.target).hasClass('icon-delete')
                ){
                    alertify.confirm('Delete', 'Are you sure to do this stuff ?', function(){ 
                            if(is_default){
                                $(`#hiddenDeleteImgDefault`).trigger("click"); 
                            } else {
                                $(`#hiddenDeleteImg`).trigger("click");       
                            }
                        }
                        , function(){}
                    );
                }
            } else {
                if(
                    event.offsetX >= this.$refs.action.clientWidth - 32 && 
                    event.offsetX <= this.$refs.action.clientWidth - 12 && 
                    event.offsetY >= 3 && event.offsetY <= 22 && 
                    $(event.target).hasClass('icon-delete')
                ){
                    alertify.confirm('Delete', 'Are you sure to do this stuff ?', function(){ 
                            if(is_default){
                                $(`#hiddenDeleteImgDefault`).trigger("click"); 
                            } else {
                                $(`#hiddenDeleteImg`).trigger("click");       
                            }
                        }
                        , function(){}
                    );
                }
            }
            //edit  IMG
            if(event.offsetX >= 14 && event.offsetX <=34 && event.offsetY >=3 && event.offsetY <=21 && $(event.target).hasClass('icon-edit')){  
                this.ephoto = resource + this.product_photos[index].photo;
                $('#eid').val(this.id);
                $('#editModal').modal();
            }
        },
        async removeImg(event,is_default){
            let id = event.target.value;

            if(is_default){
                let obj = {
                    tbl : 'pos_products',
                    per : 'pos_product',
                    photo : '',
                    id : id
                };
                let result =  await deleteImg(obj);
                if(result == "success"){
                    let data = {
                        value : "success",
                    }
                    this.$emit("updateData",data);
                } 
                if(result == "is_permission_no"){
                    let data = {
                        value : "is_permission_no",
                    }
                    this.$emit("updateData",data);
                }
            } 
            else {
                let obj = {
                    tbl : 'pos_product_photos',
                    per : 'pos_product',
                };
                let result =  await remove(id,obj);
                if(result == "success"){
                    let data = {
                        value : "success",
                    }
                    this.$emit("updateData",data);
                } 
                if(result == "is_permission_no"){
                    let data = {
                        value : "is_permission_no",
                    }
                    this.$emit("updateData",data);
                }
            }
        },
        JustUpdateModal(event){
            this.$emit("updateData",event); 
        },
    }
}
</script>
