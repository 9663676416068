import { domain } from "../../domain";

export {
    companyList,
    getSystemCat,
    createCompany,
    updateCompany,
    companyDetail,
    companyService,
    serviceSystem,
    createCompanySystemService,
    deleteCompanySystemService,
    deleteCompany,
    UpdateCompanyInfo,
    getComService
}

function companyList(per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/get-company-list?id=${id}`;
    } else if(name){
        return `${domain}/get-company-list?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}`
    } else {
        return `${domain}/get-company-list?per_page=${per_page}&page=${page}&orderBy=${orderBy}`;
    }
}

const getSystemCat = `${domain}/get-system-cat`;


const createCompany = `${domain}/create-company`;

const updateCompany = `${domain}/update-company`;

const serviceSystem = `${domain}/get-system-service`;

const createCompanySystemService = `${domain}/create-company-system-service`;

const UpdateCompanyInfo = `${domain}/update-company-info`;

const getComService = `${domain}/get-comServer`;

function deleteCompanySystemService(id){
    return `${domain}/delete-company-system-service?id=${id}`;
}

function deleteCompany(id){
    return `${domain}/delete-company?id=${id}`;
}

function companyService(per_page=null,page=null,id=null,name=null,orderBy=null,company_id=null){
    if(id){
        return `${domain}/get-company-service?id=${id}`;
    } else if(name){
        return `${domain}/get-company-service?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}&company_id=${company_id}`
    } else {
        return `${domain}/get-company-service?per_page=${per_page}&page=${page}&orderBy=${orderBy}&company_id=${company_id}`;
    }
}

function companyDetail(id){
    return `${domain}/get-company-detail?id=${id}`;
}





