import { domain } from "../../domain";

export {
    productDiscount, 
    create,
    store,
    update
}

function productDiscount(pos_product_id=null,per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/product-discount?id=${id}`;
    } else if(name){
        return `${domain}/pos/product-discount?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}&pos_product_id=${pos_product_id}`
    } else {
        return `${domain}/pos/product-discount?per_page=${per_page}&page=${page}&orderBy=${orderBy}&pos_product_id=${pos_product_id}`;
    }
}

const create = `${domain}/pos/create-product-discount`;
const store = `${domain}/pos/store-product-discount`;
const update = `${domain}/pos/update-product-discount`;



