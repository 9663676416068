<template>
<div class="receipt bg-white">
    <!-- reciept -->
    <div class="row mb-2">
        <!-- <div class="col-12 add-sale">
            Add Sale
        </div> -->
        <div class="col-12 add-sale p-0">
            <div class="input-group h-100 p-0 m-0">
                <div class="input-group-append">
                    <span class="input-group-text" id="customer" style="border:0px">Customer</span>
                </div>
                <div class="form-control h-100" style="border:0px;">
                    <!-- <select disabled id="product_id" class="receipt-select form-control  border-0 txt-kh" data-size="5" data-width="100%" data-live-search="true" required>
                        <option value="">Please Selete One</option>
                    </select> -->
                    
                </div>
                <div class="input-group-append m-0">
                    <button 
                        disabled
                        class="btn btn-outline-system txt-system" 
                        style="border:0px; pointer-events: none;" 
                        type="button" 
                        id="button-addon2" 
                        data-toggle='modal' data-target='#createModal'
                    >
                        <i class="fa-solid fa-user-plus"></i>
                    </button>
                </div>
            </div>
            </div>
    </div>
    <div class="row">
        <div class="col-12 receipt-content p-0">
            <div class="no-sale">
                <p class="no-sale-content">Please ! Select Sale Product.</p>
            </div>
        </div>
    </div>
    <div v-if="is_condition" class="row">
        <div class="col-12 p-0">
            <button class="btn-outline-system">Submit</button>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return {
            is_condition : false,
        }
    },
}
</script>

<style>

</style>
