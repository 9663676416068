<template>
  
    <div class="page404">
    <div class="container mt-xl-5 bg-white">
      <router-link to="/login" class="btn btn-danger mb-3" style="color:white !important">Back</router-link>
      <h1 class="text-black">Unauthenticated .</h1>
      <div class="card">
        <div class="card-body">
        <div class="error-page">
            <h2 class="headline text-danger">401</h2>
            <div class="error-content">
                <h3 class="mb-3">
                    <i class="fa fa-exclamation-triangle text-danger"></i> 
                    Oops! User is Unauthenticated.
                </h3>
                <p class="txt-kh">
                    រកមិនឃើញទិន្នន័យអ្នកប្រើប្រាស់ សូមចុចប៊ូតុង <span class="text-danger">Back</span> ដើម្បីត្រលប់ទៅចូលប្រើប្រាស់សារជាថ្មី!
                </p>
                <p>
                    The system you are working on is unauthenticated, please press on button ` Back ` for go to login page again!
                </p>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
 
</template>

<script>

export default {

  mounted(){
    if(localStorage.getItem('posUser')){
      localStorage.removeItem('posUser')
    }
  }
}
</script>

<style>

</style>

