<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text',
    }, 
    id: {
      type: String,
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: null,
    required: {
      default: false
    },
    disabled: {
      default: false
    },
    classes:{
        default: 'form-control'
    },
    iconPosition:{
        default: 'first'
    },
    icon:{
        default: ''
    }
  },
};
</script>
<template>
    <div class="form-group">
        
        <label :for="id">{{label}} 
            <span v-show="required"><i  class="text-danger">*</i></span>
        </label>
        <div class="input-group mb-3">
            
            <div class="input-group-prepend" v-if="iconPosition !='last' && icon">
                <span class="input-group-text" :class="icon" id="basic-addon1">
                </span>
            </div>
            <textarea
                :id="id"
                :type="type"
                :disabled="disabled"
                :placeholder="placeholder == '' ? `Please enter ${label.toLowerCase()}` : `Please enter ${placeholder.toLowerCase()}`"
                :required="required"
                :class="classes"
                :rows="3"
            />
            <div class="input-group-append" v-if="iconPosition=='last' && icon">
                <span class="input-group-text" id="basic-addon1">@</span>
            </div>
        </div>
  </div>
</template>