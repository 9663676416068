import { domain } from "../../../domain";

export {
    stockOut
}

function stockOut(selectDate,fromDate,toDate){
    return `${domain}/pos/pos-report-stockOut?selectDate=${selectDate}&fromDate=${fromDate}&toDate=${toDate}`;
}


