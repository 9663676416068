<template>
    <div class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
        <form action="ohyes" method="POST" id='create_form' @submit.prevent="form_submit()">
            <input id="create_status" type="hidden" value="error">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Create System Category</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true" >&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group mb-1">
                        <label for="com">
                            SYSTEM <span class="text-danger">*</span>
                        </label>
                        <select v-model="system_id" id="com" class="border-page-color selectPicker form-control" data-live-search="true" required>
                            <option value="">PLEASE SELECT ONE</option>
                            <option :value="system.id" v-for="system in systems" :key="system.id">{{ system.name }}</option>
                        </select>
                    </div>
                    <div class="form-group mb-1">
                        <label>
                            Name <span class="text-danger">*</span>
                        </label>
                        <input v-model="name" type="text" name="name" class="form-control" required>
                    </div>
                    <div class="form-group mb-1">
                            <label for="">Product Image</label>
                            <div class="w-100">
                                <input id="photo" @change="changeImg('photo',$event)" type="file">
                                <button type="button" @click="resetImg('photo')" v-if="pho" title="Reset Photo" class="border-0 rounded outline-0 float-right btn-outline-dark"><i class="fas fa-redo-alt"></i></button>
                                <img v-if="phoo" :src="photo" class="my-2 w-25 rounded">
                            </div>
                        </div>  
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-system btn-xs">
                        <i class="fa fa-save"></i> Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" 
                        @click="triggerReset()">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>  
</template> 
<script>
import { frm_submit } from '@/networks/services/helper/button'

export default {
    props : {
        systems: {
            type: [String, Object],
            default: null,
        },
    },
    data(){
        return {
            name : null,
            tbl : "system_categories",
            per : "system_category",
            system_id : "",
            photo : '',
            pho: false,
            phoo: null,
        }
    },
    methods: {
        triggerReset(){
            // $('#createModal').on('hidden.bs.modal', function () {
            //     $(this).find('form').trigger('reset');
            // })
            this.photo = '';
            this.pho = false;
            this.phoo = null;
        },
        resetImg(value){
            if(value == "photo"){
                this.photo = '';
                this.pho = false;
                this.phoo = null;
                $('#photo').val('');
            }
        },
        changeImg(value,obj){
            if(value == "photo"){
                this.photo = URL.createObjectURL(obj.target.files[0]);
                this.phoo = obj.target.files[0];
                this.pho = true;
            }
        },
        async form_submit(){
            var data = new FormData();
            data.append('name',this.name);
            data.append('system_id',this.system_id);
            data.append('tbl',this.tbl);
            data.append('per',this.per);
            data.append('photo',this.phoo);
            await frm_submit(data);
            this.triggerReset();
            this.$emit("createModal",$('#create_status').val()); 

        },
    },
    mounted(){
        $(document).ready(function() {
            $('.chosen2').select2();
        });
    }
}
</script>
