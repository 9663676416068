<template>
   <div class="loading-parent">
      <!-- <div class="spinner-grow txt-system loading-child" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow txt-system loading-child" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow txt-system loading-child" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow txt-system loading-child" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow txt-system loading-child" role="status">
        <span class="sr-only">Loading...</span>
      </div> -->
      <div class="d-flex align-items-center loading-child">
          <strong class="txt-system">{{$t('loading')}}</strong>  &nbsp;&nbsp;&nbsp;&nbsp;
          <div class="txt-system spinner-border load ml-auto" role="status" aria-hidden="true"></div>
      </div>
    </div>
    <!-- <div class="loading-parent">
      <div class="d-flex align-items-center loading-child">
          <div class="preloader-component">
            <div class="status">
              <div class="spinner-chase">
                <div class="chase-dot"></div>
                <div class="chase-dot"></div>
                <div class="chase-dot"></div>
                <div class="chase-dot"></div>
                <div class="chase-dot"></div>
                <div class="chase-dot"></div>
              </div>
            </div>
          </div>
      </div>
    </div> -->
</template>

<script>
export default {

}
</script>

<style>
#preloader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999; }

#status {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  top: 50%;
  margin: -20px -20px 0 0; }

.spinner-chase {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-animation: spinner-chase 2.5s infinite linear both;
          animation: spinner-chase 2.5s infinite linear both; }

.chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-animation: chase-dot 2.0s infinite ease-in-out both;
          animation: chase-dot 2.0s infinite ease-in-out both; 
}
.chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #556ee6;
  border-radius: 100%;
  -webkit-animation: chase-dot-before 2.0s infinite ease-in-out both;
          animation: chase-dot-before 2.0s infinite ease-in-out both; }
.chase-dot:nth-child(1) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
  .chase-dot:nth-child(1):before {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s; }
.chase-dot:nth-child(2) {
  -webkit-animation-delay: -1.0s;
          animation-delay: -1.0s; }
  .chase-dot:nth-child(2):before {
    -webkit-animation-delay: -1.0s;
            animation-delay: -1.0s; }
.chase-dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
  .chase-dot:nth-child(3):before {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s; }
.chase-dot:nth-child(4) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
  .chase-dot:nth-child(4):before {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s; }
.chase-dot:nth-child(5) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
  .chase-dot:nth-child(5):before {
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s; }
.chase-dot:nth-child(6) {
-webkit-animation-delay: -0.6s;
        animation-delay: -0.6s; }
.chase-dot:nth-child(6):before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
        

.preloader-component {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  width: 100%;
}
.status {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

</style>