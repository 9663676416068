<template>
    <div class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModal" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
        <form action="ohyes" method="POST" id='create_form' @submit.prevent="form_submit()">
            <input id="create_status" type="hidden" value="error">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Create Product</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true" >&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <!-- <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label>
                                Code <span class="text-danger">*</span>
                            </label>
                            <input type="text" name="code" :value="code" readonly class="form-control">
                        </div> -->
                        <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label for="category_id">
                                Category <span class="text-danger">*</span>
                            </label>
                            <select v-model="cat_id" id="category_id" class="border-page-color selectPicker form-control" data-size="5" data-live-search="true" required>
                                <option value="">PLEASE SELECT ONE</option>
                                <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
                            </select>
                        </div>
                        <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label for="brand">
                                Brand <span class="text-danger">*</span>
                            </label>
                            <select v-model="brand_id" id="brand" class="border-page-color selectPicker form-control" data-live-search="true" required>
                                <option value="">PLEASE SELECT ONE</option>
                                <option :value="brand.id" v-for="brand in brands" :key="brand.id">{{ brand.name }}</option>
                            </select>
                        </div>
                        <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label>
                                Barcode <span class="text-danger">*</span>
                            </label>
                            <input v-model="barcode" type="text" name="name" class="form-control" required
                                @keyup="checkBarcode()"
                            >
                            <p v-if="bar" :class="`${is_barcode == 0 ? 'text-success' : 'text-danger'} p-0 m-0`"><small>{{ is_barcode == 0 ? 'Good' : 'Duplicate' }}</small></p>
                        </div>
                        <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label>
                                Name <span class="text-danger">*</span>
                            </label>
                            <input v-model="name" type="text" name="name" class="form-control" required>
                        </div>
                        <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label>
                                Alert for low stock <span class="text-danger">*</span>
                            </label>
                            <input v-model="alert" type="number" step="0.001" min="0" name="on_hand" class="form-control" required
                                
                            >
                        </div>
                        <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label>
                                Unit Price ($)<span class="text-danger">*</span>
                            </label>
                            <input v-model="unit_price" type="number" step="0.001" min="0" name="on_hand" class="form-control" required
                                
                            >
                        </div>
                        <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label for="vat">
                                VAT <span class="text-danger">*</span>
                            </label>
                            <select v-model="vat" id="category_id" class="border-page-color selectPicker form-control" data-live-search="false" required>
                                <option value="">PLEASE SELECT ONE</option>
                                <option :value="vat.id" v-for="vat in vats" :key="vat.id">{{ vat.name }} @{{ vat.value }}%</option>
                            </select>
                        </div>
                        <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label>
                                Original Price / Item ($) <span class="text-danger">*</span>
                            </label>
                            <input v-model="original_price" type="number" min="0" step="0.001" name="on_hand" class="form-control"
                                
                                required
                            >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8 col-sm-12">
                            <div class="row my-2">
                                <div class="col-12">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" :checked="is_onhand" class="custom-control-input" id="customSwitch1" @click="isCheckToOpen('on_hand')">
                                        <label class="custom-control-label" for="customSwitch1">Now, Do you have product on hands ?</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="is_onhand">
                                <div class="col-lg-6 col-sm-12 form-group mb-1">
                                    <label>
                                        On Hand / Item <span class="text-danger">*</span>
                                    </label>
                                    <input v-model="on_hand" type="number" step="0.001" min="0" name="on_hand" class="form-control"
                                        
                                        required
                                    >
                                </div>
                            </div>
                            <div class="row my-2">
                                <div class="col-12">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" :checked="is_stock" class="custom-control-input" id="customSwitch2" @click="isCheckToOpen('stock')">
                                        <label class="custom-control-label" for="customSwitch2">Wholesales ?</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="is_stock">
                                <div class="col-lg-4 col-sm-12 form-group mb-1">
                                    <label for="com">
                                        Unit Type <span class="text-danger">*</span>
                                    </label>
                                    <select v-model="unit_type_id" id="unit_type_id" class="border-page-color selectPicker form-control txt-kh" data-live-search="true" required>
                                        <option value="">PLEASE SELECT ONE</option>
                                        <option :value="unit_type.id" v-for="unit_type in unit_types" :key="unit_type.id">{{ unit_type.name }}</option>
                                    </select>
                                </div>
                                <div class="col-lg-4 col-sm-12 form-group mb-1">
                                    <label for="num">
                                        {{$t("Quantity")}} <span class="text-danger">*</span>
                                    </label>
                                    <input v-model="quality" type="number" step="0.001" min="0" id="num" class="form-control" required>
                                </div>
                                <div class="col-lg-4 col-sm-12 form-group mb-1">
                                    <label>
                                        Stock Unit Price Per Item ($) <span class="text-danger">*</span>
                                    </label>
                                    <input v-model="stock_unit_price_per_item" type="number" min="0" step="0.001" name="stock_price" class="form-control"
                                        required
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-12 form-group my-2">
                            <label for="">Product Image</label>
                            <div class="w-100">
                                <input id="photo" @change="changeImg('photo',$event)" type="file">
                                <button type="button" @click="resetImg('photo')" v-if="pho" title="Reset Photo" class="border-0 rounded outline-0 float-right btn-outline-dark"><i class="fas fa-redo-alt"></i></button>
                                <img v-if="phoo" :src="photo" class="my-2 w-25 rounded">
                            </div>
                        </div>  
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="triggerReset()" type="button" class="btn btn-outline-dark btn-xs">
                        <i class="fas fa-sync"></i> Reset
                    </button>
                    <button :disabled="isCreate" id="save" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isCreate" src="../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" 
                        @click="triggerReset()">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>  
</template> 
<script>
import axios from 'axios'
import { config } from '@/networks/config'
import { checkBarcode , store } from '@/networks/services/pos/product.service'
export default {
    props : {
        categories: {
            type: [String, Object],
            default: null,
        },
        unit_types: {
            type: [String, Object],
            default: null
        },
        code : {
            type: [String, Object, Number],
            default : 0
        },
        vats : {
            type : [Object, Array],
            default : []
        },
        brands : {
            type : [Object, Array],
            default : []
        }
    },
    data(){
        return {
            isCreate : false,
            name : '',
            tbl : "pos_products",
            per : "pos_product",
            unit_type_id : '',
            is_barcode : 0,
            bar : false,
            barcode : '',
            on_hand : '',
            alert : '',
            original_price : '',
            unit_price : '',
            cat_id: '',
            photo : '',
            pho: false,
            phoo: null,
            is_onhand : false,
            is_stock : false,
            quality : '',
            vat : 1,
            stock_unit_price_per_item : '',
            brand_id : '',
        }
    },
    methods: {
        async triggerReset(){
            this.name = '';
            this.bar = false;
            this.barcode = '';
            this.on_hand = '';
            this.alert = '';
            this.original_price = '';
            this.unit_price = '';
            this.unit_type_id = '';
            this.cat_id = '';
            this.photo = '';
            this.pho = false;
            this.phoo = null;
            this.is_onhand = false;
            this.is_stock = false;
            this.quality = '';
            this.vat = 1;
            this.stock_unit_price_per_item = '';
            this.brand_id = '';
            
            await this.$nextTick();
            $('.selectPicker').selectpicker('refresh');       
            $('.selectPicker').selectpicker(); 
        },
        async isCheckToOpen(value){
            if(value == "on_hand"){
                if(this.is_onhand == false){
                    this.is_onhand = true;
                } else {
                    this.is_onhand = false;
                }
            }
            if(value == "stock"){
                if(this.is_stock == false){
                    this.is_stock = true;
                    await this.$nextTick();
                    $('.selectPicker').selectpicker('refresh');       
                    $('.selectPicker').selectpicker(); 
                } else {
                    this.is_stock = false;
                }
            }
        },
        resetImg(value){
            if(value == "photo"){
                this.photo = '';
                this.pho = false;
                this.phoo = null;
                $('#photo').val('');
            }
        },
        changeImg(value,obj){
            if(value == "photo"){
                this.photo = URL.createObjectURL(obj.target.files[0]);
                this.phoo = obj.target.files[0];
                this.pho = true;
            }
        },
        async checkBarcode(){
            if(this.barcode != ''){
                this.bar = true;
                let data = {
                    barcode : this.barcode
                }
                let is_barcode = await axios.post(checkBarcode, data)
                                        .catch(error => {
                                            console.log(error.response);
                                        });
                if(is_barcode.data.status == "401"){
                    alertify.set('notifier','position', 'top-right');
                    alertify.success(`${is_barcode.data.message}`);
                    localStorage.removeItem('posUser');
                    this.$router.push('/Unauthenticated');
                } else {
                    this.is_barcode = is_barcode.data.data;
                }
            } else {
                this.bar = false;
            }
        },
        async form_submit(){
            config();
            this.isCreate = true;
            if(this.is_barcode == 0){
                $('#save').prop('disabled', true);
            }

            var data = new FormData();
            data.append('code',this.code);
            data.append('company_category_id',this.cat_id);
            data.append('barcode',this.barcode);    
            data.append('name',this.name);
            data.append('alert',this.alert);
            data.append('unit_price',this.unit_price);
            data.append('is_onhand',this.is_onhand ? 1 : 0);
            data.append('on_hand',this.on_hand);
            data.append('original_price',this.original_price);
            data.append('is_stock',this.is_stock ? 1 : 0);
            data.append('pos_unit_type_id',this.unit_type_id);
            data.append('quality_per_stock',this.quality);
            data.append('photo',this.phoo);
            data.append('vat_id',this.vat);
            data.append('brand_id',this.brand_id);
            data.append('stock_unit_price_per_item',this.stock_unit_price_per_item);

            if(this.is_barcode == 0){
                const result = await axios
                                    .post(`${store}`,data)
                                    .catch(error => {
                                        console.log(error.response);
                                    });
                this.isCreate = false;
                this.triggerReset();
                $('#save').prop('disabled', false);

                if(result.data.status == "success"){
                    $('#createModal').modal('hide');
                    $("#create_form")[0].reset();
                    alertify.set('notifier','position', 'top-right');
                    alertify.success(`${result.data.message}`);
                    this.$emit("createModal","success");   
                } else if(result.data.status == "401"){
                    alertify.set('notifier','position', 'top-right');
                    alertify.success(`${result.data.message}`);
                    localStorage.removeItem('posUser');
                    this.$router.push('/Unauthenticated');
                } 
                else if(result.data.status == "is_permission_no"){
                    $('#createModal').modal('hide');
                    $("#create_form")[0].reset();
                    alertify.set('notifier','position', 'top-right');
                    alertify.error(`You are no permission to do this stuff!`);
                    this.$emit("createModal","is_permission_no");   
                } 
                else if(result.data.status == "error"){
                    $('#createModal').modal('hide');
                    $("#create_form")[0].reset();
                    $("#create_status").val("error");
                    alertify.set('notifier','position', 'top-right');
                    alertify.error(`${result.data.message}`); 
                } else {
                    console.log(result)
                } 
            } else {
                this.barcode = '';
                this.isCreate = false;
            }
        }
        
    },
    mounted(){
        $(document).ready(function() {
            $('.chosen2').select2();
        });
    }
}
</script>
