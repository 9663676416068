<template>
    <div class="card">
        <div class="card-header">
            <button v-if="btnBack" @click="$router.back()" class="btn btn-dark btn-sm mb-2 mr-2"><i class="fas fa-reply-all"></i>&nbsp; BACK</button>
            <button v-if="btnUpdate" @click="$router.back()" class="btn btn-system btn-sm mb-2 mr-2"><i class="fas fa-tools"></i>&nbsp; Submit</button>
            <router-link v-if="btnEdit" to="/edit-company-info" class="btn btn-success btn-sm mb-2"><i class="fas fa-edit"></i>&nbsp; Edit</router-link>
            <span class="badge badge-info float-right">{{$t("Company Info")}}</span>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group row">
                        <label for="date" class="col-sm-4">
                        {{$t("EN NAME")}}
                            <span class="float-right">:</span>
                        </label>
                        <div class="col-sm-8">
                            {{ companyInfo.en_name }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="date" class="col-sm-4">
                        {{$t("KH NAME")}}
                            <span class="float-right">:</span>
                        </label>
                        <div class="col-sm-8 txt-kh">
                            {{ companyInfo.kh_name }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="date" class="col-sm-4">
                        {{$t("EMAIL")}} 
                            <span class="float-right">:</span>
                        </label>
                        <div class="col-sm-8">
                            {{ companyInfo.email }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="date" class="col-sm-4">
                        {{$t("ADDRESS")}}
                            <span class="float-right">:</span>
                        </label>
                        <div class="col-sm-8 txt-kh">
                            {{ companyInfo.address }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="date" class="col-sm-4">
                        {{$t("PHONE LINE 1")}}
                            <span class="float-right">:</span>
                        </label>
                        <div class="col-sm-8">
                            {{ companyInfo.phone_line1 }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="date" class="col-sm-4">
                        {{$t("PHONE LINE 2")}}
                            <span class="float-right">:</span>
                        </label>
                        <div class="col-sm-8">
                            {{ companyInfo.phone_line2 }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="date" class="col-sm-4">
                        {{$t("PHONE LINE 3")}}
                            <span class="float-right">:</span>
                        </label>
                        <div class="col-sm-8">
                            {{ companyInfo.phone_line3 }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="date" class="col-sm-4">
                        {{$t("PHONE LINE 4")}}
                            <span class="float-right">:</span>
                        </label>
                        <div class="col-sm-8">
                            {{ companyInfo.phone_line4 }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div v-if="category" class="form-group row">
                        <label for="date" class="col-sm-4">
                            Shop Type
                            <span class="float-right">:</span>
                        </label>
                        <div class="col-sm-8">
                            {{ companyInfo.system_category_name }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="date" class="col-sm-4">
                        Latitude
                            <span class="float-right">:</span>
                        </label>
                        <div class="col-sm-8 txt-kh">
                            {{ companyInfo.latitude }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="date" class="col-sm-4">
                        Longitude
                            <span class="float-right">:</span>
                        </label>
                        <div class="col-sm-8 txt-kh">
                            {{ companyInfo.longitude }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="date" class="col-sm-4">
                        {{$t("INVOICE HEADER")}}
                            <span class="float-right">:</span>
                        </label>
                        <div class="col-sm-8 txt-kh">
                            {{ companyInfo.invoice_footer }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="date" class="col-sm-4">
                        {{$t("INVOICE FOOTER")}}
                            <span class="float-right">:</span>
                        </label>
                        <div class="col-sm-8 txt-kh">
                            {{ companyInfo.invoice_footer }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="date" class="col-sm-4">
                        {{$t("LOGO")}}
                            <span class="float-right">:</span>
                        </label>
                        <div class="col-sm-8">
                            <img v-if="companyInfo.logo" :src="img(companyInfo.logo)" class="rounded-circle w-25">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="date" class="col-sm-4">
                        {{$t("Banner")}}
                            <span class="float-right">:</span>
                        </label>
                        <div class="col-sm-8">
                            <img v-if="companyInfo.banner" :src="img(companyInfo.banner)" class="w-100 rounded">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  
</template>
<script>
import { resource } from '@/networks/domain'
export default{
    props: {
        companyInfo: {
            type: [ String, Object],
            default: null,
        },
        btnBack : {
            type: [String, Object, Boolean, Number],
            default : false,
        },
        btnEdit : {
            type : [String, Object, Boolean, Number],
            default : false,
        },
        btnUpdate : {
            type : [String, Object, Boolean, Number],
            default : null
        },
        category : {
            type : [String, Object, Boolean, Number],
            default : true
        }
    },
    methods: {
        img(obj){
            return resource+obj;
        }
    }
}
</script>
