<template>
  <content-header title="User Detail" icon="fas fa-user"/>
  <content>
    <loading v-if="isTrue"/>
    <div v-else class="container-fluid">
        <div class="card">



        <!-- button action -->
            <div class="card-header">
                <router-link to="/user" class="btn btn-sm btn-dark mb-2"><i class="fas fa-reply-all"></i> {{$t("back")}}</router-link> &nbsp;
                <button @click="reloadData()" type="button" class="btn btn-warning btn-sm mb-2"><i class="fa fa-exchange-alt mr-1"></i>{{$t("reload_data")}}</button>      
            </div>
            <div class="card-body">
                <div class="container bootstrap snippet">

                    <div class="row">
                        <div class="col-lg-3 col-12 mb-5"><!--left col-->
                            <div class="text-center">
                                <img v-if="!photo" src="@/assets/avatar.png" class="avatar img-circle img-thumbnail" alt="avatar">
                                <img v-else :src="photo" class="avatar img-circle img-thumbnail" alt="avatar">
                                <br> <br>
                                <h6>Upload a different photo...</h6>
                                <input type="file" @change="file_upload($event)" class="text-center center-block">
                            </div>
                        </div>
                        <div class="col-lg-9 col-12">                
                          
                            <form action="##" @submit.prevent="submit()">
                                <div class="row mb-2">
                                    <div class="col-lg-6 col-12 mb-2">
                                        <label class="form-group m-0" for="username"><h4>{{$t("username")}}</h4></label>
                                        <input v-model="username" type="text" class="form-control" id="username">
                                    </div>
                                    <div class="col-lg-6 col-12 mb-2">
                                        <label class="form-group m-0" for="nickname"><h4>{{$t("nickname")}}</h4></label>
                                        <input v-model="nickname" type="text" class="form-control" id="nickname">
                                    </div>
                                    <div class="col-lg-6 col-12 mb-2">
                                        <label class="form-group m-0" for="status"><h4>{{$t("status")}}</h4></label>
                                        <select v-model="status" id="status" class="selectPicker form-control">
                                            <option value="1">Inactive</option>
                                            <option value="0">Active</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6 col-12 mb-2">
                                        <label class="form-group m-0" for="role"><h4>{{$t("role")}}</h4></label>
                                        <select v-model="role" id="role" class="selectPicker form-control" data-live-search="true">
                                            <option :value="role.id" v-for="role in roles" :key="role.id"> {{ role.name }}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6 col-12 mb-2">
                                        <label class="form-group m-0" for="password"><h4>{{$t("new_password")}}</h4></label>
                                        <input v-model="password" id="password" type="password" class="form-control" placeholder="*******" title="enter your first name if any." autocomplete="new_password">
                                        <div class="text-danger">
                                            <small>If you don't want to change the password, please don't input*</small>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-12 mb-2">
                                        <label class="form-group m-0" for="confirm_password"><h4>{{$t("password_confirm")}}</h4></label>
                                        <input v-model="password_confirmation" id="confirm_password" type="password" class="form-control" placeholder="*******" title="enter your first name if any." autocomplete="confirm_password">
                                        <div class="text-danger">
                                            <small>If you don't want to change the password, please don't input*</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <hr>
                                        <button v-if="edit" :disabled="isUpdate" type="submit" class="btn btn-sm btn-system">
                                          <img v-if="isUpdate" src="../../assets/sniper.jpeg" alt="" width="12">
                                          <i v-else class="fas fa-tools"></i> {{$t("update")}}
                                        </button> &nbsp;
                                        <button type="button" @click="reset()" class="btn btn-sm btn-outline-dark">{{$t("reset")}}</button>
                                    </div>
                                </div>
                            </form>    
                            
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
  </content>
  <content-header/>
</template>

<script>
import axios from 'axios'
import { userDetail , updateUser} from '@/networks/services/auth/user.service.js'
import { checkPermission } from '@/networks/services/helper/helper.js'
import Loading from '@/components/loading/Loading'
import { config } from '@/networks/config'
import { resource } from '@/networks/domain'




export default {
  components: { 
    Loading, 
  },
  props : {
    id : {
      type : [String, Number],
      default : ''
    }
  },
  data(){
    return {
      user: {},
      roles:[],
      password: '',
      username : '',
      nickname : '',
      status : '',
      role : '',
      photo: '',
      params : {
        id : this.id,
      },
      permission_no: false,
      isTrue: true,
      pageLoad: false,
      edit: false,
      list: false,
      password_confirmation : '',
      img : '',
      isUpdate : false,
    }
  },
  computed:{
    selectOptions(){
        let opts = this.table.totalIndexPage;
        return opts
    }
  },
  methods : {
    file_upload(obj){   
        this.photo = URL.createObjectURL(obj.target.files[0]);
        this.img = obj.target.files[0];
    },
    reset(){
      this.photo = '';
      this.img = '';
      this.isUpdate = false;
      $('input[type="file"]').val('');
      this.getData();
    },
    reloadData(){
        this.isTrue = true;
        this.isUpdate = false;
        this.getData();
    },
    async submit(){
      this.isUpdate = true;
      config();
      let data = new FormData;
        data.append("id",this.params.id);
        data.append("photo",this.img);
        data.append("password",this.password);
        data.append("password_confirmation",this.password_confirmation);
        data.append("nick_name",this.nickname);
        data.append("role_id",this.role);
        data.append("username",this.username);
        data.append("is_block",this.status);


        
        const result = await axios
                                .post(`${updateUser}`,data)
                                .catch(error => {
                                    console.log(error.response);
                                });
        this.isUpdate = false;
        if(result.data.status == "success"){
            // this.getData(false);
            // this.$router.push('/login');
            alertify.set('notifier','position', 'top-right');
            alertify.success(`${result.data.message}`);
        } else if(result.data.status == "401"){
            alertify.set('notifier','position', 'top-right');
            alertify.error(`${result.data.message}`);
            localStorage.removeItem('posUser');
            this.$router.push('/Unauthenticated');
        } 
        else if(result.data.status == "is_permission_no"){
            alertify.set('notifier','position', 'top-right');
            alertify.error(`You are no permission to do this stuff!`);
        } 
        else if(result.data.status == "error"){
            alertify.set('notifier','position', 'top-right');
            alertify.error(`${result.data.message}`); 
        } else {
            console.log(result)
        } 
    },
    async reloadSelectOption(){
      await this.$nextTick();
      $('.selectPicker').selectpicker('refresh');
      $('.selectPicker').selectpicker();
    },
    async getData(){
      if(localStorage.getItem('posUser')){
        config();
        axios
          .get(`${userDetail(this.params.id)}`)
          .then(response => {
            if(response.data.status === 'success'){

                this.pageLoad = false;
                
                //data
                this.user = response.data.user;
                this.roles = response.data.roles;
                this.username = response.data.user.username;
                this.nickname = response.data.user.nick_name;
                this.role = response.data.user.role_id;
                this.status = response.data.user.is_block;
                this.photo =  response.data.user.photo ? `${resource}${response.data.user.photo}` : '';

        
                //permission
                this.list = response.data.list;
                this.create = response.data.create;
                this.deleteAll = response.data.delete;
                this.edit = response.data.edit;

      

                this.isTrue = false;
                
            } else if(response.data.status === 401) {
              localStorage.removeItem('posUser');
              this.$router.push('/Unauthenticated');
            } else if(response.data.status == "is_permission_no"){
              this.permision_no = true;
            }
            else {
              console.log('something wrong with api');
            }
          })
          .then(()=>{
            this.reloadSelectOption();
          })
          .catch(error => {
              this.$router.push('/user');
            }
          );
      } else {
        this.$router.push('/Unauthenticated')
      }
    },
  },
  created(){
      this.getData();
  },
  mounted(){
    $("#Security").addClass("menu-open");
    $("#Security>a").addClass("active");
    $("#Security>ul").css({'display' : 'block'});
    $("#User").addClass("active");
  }
}
</script>

<style>

</style>