<template>
    <div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <form action="uytyut" method="POST" id='edit_form' @submit.prevent="form_update($event)">
              <input type="hidden" name="id" id='eid' value="">
              <input id="edit_status" type="hidden">
              <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Edit Product Discount</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="ereference">
                                    Reference
                                </label>
                                <input v-model="edata.reference" id="ereference" type="text" name="name" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="eproduct">
                                    Product <span class="text-danger">*</span>
                                </label>
                                <select v-model="edata.pos_product_id" id="eproduct" class="border-page-color selectPicker form-control" data-live-search="true" required>
                                    <option :value="product.id" v-for="product in products" :key="product.id">{{ product.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="ediscount">
                                    Discount <span class="text-danger">*</span>
                                </label>
                                <input v-model="edata.discount" id="ediscount" type="text" name="name" class="form-control" required
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                >
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="etype">
                                    Type <span class="text-danger">*</span>
                                </label>
                                <select v-model="edata.discount_type" id="etype" class="border-page-color selectPicker form-control txt-kh" data-live-search="false" required>
                                    <option value="$">$</option>
                                    <option value="%">%</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="estart_date">
                                    Start Date <span class="text-danger">*</span>
                                </label>
                                <input v-model="edata.start_date" id="estart_date" type="date" name="name" class="form-control" required>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="eend_date">
                                    End Date <span class="text-danger">*</span>
                                </label>
                                <input v-model="edata.end_date" id="eend_date" type="date" name="name" class="form-control" required>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="edescription">
                                    Description
                                </label>
                                <textarea v-model="edata.description" id="edescription" class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="modal-footer">
                    <button type="submit" id="update" class="btn btn-system btn-xs">
                        <img v-if="isCreate" src="../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Update
                    </button>
                    <button @click="triggerReset()" type="button" class="btn btn-danger btn-xs" data-dismiss="modal">
                        <i class="fa fa-times"></i> Close
                    </button>
                  </div>
              </div>
          </form>
        </div>
    </div>  
    </template>
    
    <script>
    import axios from 'axios'
    import { update } from '@/networks/services/pos/productDiscount.service'
    
    export default {
        props : {
            edata : {
                type : [Object , Array],
                default : []
            },
            products : {
                type : [Object, String , Array],
                default : null
            }
        },
        data(){
            return {
                isCreate : false,
                tbl : "pos_product_discounts",
                per : "pos_product_discount",
            }
        },
        methods: {  
            triggerReset(){
                $('#editModal').on('hidden.bs.modal', function () {
                    $(this).find('form').trigger('reset');
                })
            },
            async form_update(){
                let data = {
                    id: $('#eid').val(),
                    pos_product_id : this.edata.pos_product_id,
                    discount_type : this.edata.discount_type,
                    discount : this.edata.discount,
                    start_date : this.edata.start_date,
                    end_date : this.edata.end_date,
                    description : this.edata.description,
                    reference : this.edata.reference,
                };
                this.isCreate = true;
                $('#update').prop('disabled', true);

                // await frm_submit(data);
                const result = await axios
                                    .post(`${update}`,data)
                                    .catch(error => {
                                        console.log(error.response);
                                    });
                this.isCreate = false;
                this.triggerReset();
                $('#update').prop('disabled', false);

                if(result.data.status == "success"){
                    $('#editModal').modal('hide');
                    $("#edit_form")[0].reset();
                    alertify.set('notifier','position', 'top-right');
                    alertify.success(`${result.data.message}`);
                    this.$emit("editModal","success");   
                } else if(result.data.status == "401"){
                    alertify.set('notifier','position', 'top-right');
                    alertify.success(`${result.data.message}`);
                    localStorage.removeItem('posUser');
                    this.$router.push('/Unauthenticated');
                } else if(result.data.status == 'is_permission_no'){
                    $('#editModal').modal('hide');
                    $("#edit_form")[0].reset();
                    alertify.set('notifier','position', 'top-right');
                    alertify.error(`You are no permission to do this stuff!`);
                    this.$emit("editModal","is_permission_no");   
                } else if(result.data.status == "error"){
                    $('#editModal').modal('hide');
                    $("#edit_form")[0].reset();
                    alertify.set('notifier','position', 'top-right');
                    alertify.error(`${result.data.message}`);
                } else {
                    console.log(result)
                }
                this.triggerReset();
            },
        },
        mounted(){
            $(document).ready(function() {
                $('.chosen2').select2();
            });
        }
    }
    </script>
    
    <style>
    
    </style>