<template>
  <content-header title="Photo & Attribute" icon="fa fa-angle-double-right"/>
    <content>
        <div class="container-fluid">
            <loading v-if="isTrue"/>
            <ProductPhotoView 
                :btnBack="true" 
                :product="default_product" 
                :btnDelete="deleteAll" 
                :btnEdit="edit"  
                :btnCreate="create" 
                :product_photos = "product_photos"
                :pageLoad = "pageLoad"
                @updateData="updateData($event)" 
                v-if="!isTrue"
            />
            <ProductAttributeViewVue 
                :product="default_product"
                :pageLoad = "pageLoadAtt"
                :btnDelete="deleteAll" 
                :btnEdit="edit"  
                :btnCreate="create" 
                :attributes = "attributes"
                :product_attributes = "product_attributes"
                @updateDataAttribute="updateDataAttribute($event)"
                v-if="!isTrue"
            />
        </div>
    </content>
  <content-header/>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/loading/Loading.vue';
import { config } from '@/networks/config';
import { checkPermission } from '@/networks/services/helper/helper';
import ProductPhotoView from '@/components/product/ProductPhotoView.vue';
import ProductAttributeViewVue from '@/components/productAttribute/index.vue';
import { productAttribute } from '@/networks/services/pos/product.service';
export default {
  components : {
    ProductPhotoView, Loading , ProductAttributeViewVue
  },
  props : {
    id : {
        type : [Number, String],
        default : ''
    }
  },
  data(){
    return {
        isTrue : true,
        edit : false,
        create : false,
        list : false,
        deleteAll : false,
        default_product : {},
        product_photos : [],
        attributes : [],
        product_attributes : [],
        pageLoad : false,
        pageLoadAtt : false,
    }
  },
  methods : {
    async getData(){
        if(this.id == ''){
            this.$router.push('/product');
        }
        if(localStorage.getItem('posUser')){
            config();
            axios
                .get(`${productAttribute(this.id)}`)
                .then(response => {
                    if(response.data.status === 'success'){
                        this.isTrue = false;
                        this.pageLoad = false;
                        this.pageLoadAtt = false;
                        this.default_product = response.data.default_product;
                        this.product_photos = response.data.product_photos;
                        this.attributes = response.data.attributes;
                        this.product_attributes = response.data.product_attributes;

                        //permission
                        this.list = response.data.list;
                        this.create = response.data.create;
                        this.deleteAll = response.data.delete;
                        this.edit = response.data.edit;
                    } 
                    else if(RES.data.status === 401) {
                        alertify.set('notifier','position', 'top-right');
                        alertify.error(`${RES.data.message}`);
                        localStorage.removeItem('posUser');
                        this.$router.push('/Unauthenticated');
                    } else if(response.data.status == "is_permission_no"){
                        this.permision_no = true;
                    }
                    else {
                        console.log('something wrong with api');
                    }
                })
                .catch(error => {
                        console.log(error)    
                    }
                );
        } else {
            this.$router.push('/Unauthenticated')
        }
    },
    updateData(event){
        if(event.value == "success"){
            this.pageLoad = true;
            this.getData();
        } 
        else if(event.value == "is_permission_no"){
            this.pageLoad = true;
            this.getData();
        } else {
            return 
        }
    },
    updateDataAttribute(event){
        if(event.value == "success"){
            this.pageLoadAtt = true;
            this.getData(false);
        } 
        else if(event.value == "is_permission_no"){
            this.pageLoadAtt = true;
            this.getData();
        } else {
            return 
        }
    }
  },
  created(){
    this.getData();
  },
  mounted(){
    $("[id='Product Management']").addClass("menu-open");
    $("[id='Product Management']>a").addClass("active");
    $("[id='Product Management']>ul").css({'display' : 'block'});
    $("[id='Product']").addClass("active");
  }
}
</script>

<style>

</style>