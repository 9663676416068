import { domain } from "../../domain";

export {
    companyRole,
    createCompanyRole,
    deleteRole,
}

function companyRole(company_id=null,per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/get-company-role?id=${id}`;
    } else if(name){
        return `${domain}/pos/get-company-role?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}&company_id=${company_id}`
    } else {
        return `${domain}/pos/get-company-role?per_page=${per_page}&page=${page}&orderBy=${orderBy}&company_id=${company_id}`;
    }
}


const createCompanyRole = `${domain}/pos/insert-company-role`;

function deleteRole(id){
    return `${domain}/pos/get-permission?id=${id}`;
}



