<template>
  <content-header title="Company Info" icon="fas fa-info-circle"/>
  <content>
    <loading v-if="isTrue"/>
    <div v-else class="container-fluid">
      <companyInfoVue :category="false" :btnEdit="edit" :companyInfo="companyInfo"></companyInfoVue>  
    </div>
  </content>
  <content-header/>
</template>

<script>
import axios from 'axios'
import companyInfoVue from '@/components/company/companyInfo.vue'
import { userInfo } from '@/networks/services/auth/user.service'
import { checkPermission } from '@/networks/services/helper/helper.js'
import Loading from '@/components/loading/Loading'
import { config } from '@/networks/config'




export default {
  components: { 
    Loading , companyInfoVue
  },
  data(){
    return {
      companyInfo : [],
      permission_no: false,
      isTrue: true,
      pageLoad: false,
      create: false,
      deleteAll : false,
      edit: false,
      list: false,
    }
  },
  methods : {
    async getData(){
      if(localStorage.getItem('posUser')){
        config();
        axios
          .get(`${userInfo}`)
          .then(response => {
            if(response.data.status === 'success'){
              
              this.pageLoad = false;
                
              this.companyInfo = response.data.companyInfo

              //permission
              this.list = response.data.list;
              this.create = response.data.create;
              this.deleteAll = response.data.delete;
              this.edit = response.data.edit;

              this.isTrue = false;
                
            } else if(response.data.status === 401) {
              alertify.set('notifier','position', 'top-right');
              alertify.error(`${response.data.message}`);
              localStorage.removeItem('posUser');
              this.$router.push('/Unauthenticated');
            } else if(response.data.status == "is_permission_no"){
              this.permision_no = true;
            }
            else {
              console.log('something wrong with api');
            }
          })
          .catch(error => {
              console.log(error)
            }
          );
      } else {
        this.$router.push('/Unauthenticated')
      }
    },
  },
  created(){
      this.getData();
  },
  mounted(){
    
  }
}
</script>

<style>

</style>