import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ContentHeader from './components/ContentHeader.vue'
import Content from './components/Content.vue'
import language from './languages/lang.js'
import axios from 'axios'
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'

axios.defaults.headers.common['api_key'] = store.state.app.api_key;
axios.defaults.headers.common['type'] = store.state.app.type;      



window.addEventListener("online", 
        function(){
                alertify.set('notifier','position', 'top-right');
                alertify.success('Internet Connected');
        }
);

window.addEventListener("offline", 
        ()=> {
                alertify.set('notifier','position', 'top-right');
                alertify.error('Internet Disconnected');
        }
);

// window.OneSignal = window.OneSignal || [];
// OneSignal.push(function() {
//     var isPushSupported = OneSignal.isPushNotificationsSupported();
//     if(isPushSupported){
//         OneSignal.isPushNotificationsEnabled(function(isEnabled) {
//             if(!isEnabled){
//                 OneSignal.push(function(){
//                     OneSignal.showHttpPrompt();
//                     OneSignal.on('subscriptionChange', function (isSubscribed) {
//                         if(isSubscribed){
//                             OneSignal.getUserId(function(userId) {
//                                 // localStorage.player_id = userId;
//                                 console.log(userId)
//                                 // OneSignal.setSubscription(false);
//                             });
//                         }
//                     });
//                 });  
//             } else {
//                 console.log("already allow");
//             }
//         });
//     } else {
//         alert('Your Browser is not supported the notification!')
//     }
// });

//get data from api 

// OneSignal.push(function() {
//     OneSignal.on('notificationDisplay', function(event) {
//         console.log('OneSignal notification displayed:', event);
//     });
    
//     //This event can be listened to via the `on()` or `once()` listener
// });


const app = createApp(App)

app.component('content-header',ContentHeader)
        .component('content',Content)
        .use(store)
        .use(router)
        .use(language)
        // .use(OneSignalVuePlugin, {
        //         appId: store.state.app.appId,
        //       })
        .mount('#app');

        
