<template>
<div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <form action="uytyut" method="POST" id='edit_form' @submit.prevent="form_update($event)">
          <input type="hidden" name="id" id='eid' value="">
          <input id="edit_status" type="hidden">
          <div class="modal-content">
            <div class="modal-header bg-system">
                <strong class="modal-title text-white">Edit Company</strong>
                <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="text-white" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div v-if="edata" class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-12">
                            <label for="system_category">Category <span class="text-danger">*</span></label>
                            <select v-model="edata.system_category_id" id="esystem_category_id" class="selectPicker form-control" required>
                                <option value="">Please Select One</option>
                                <option :value="system_category.id" v-for="system_category in system_categories" :key="system_category.id">{{ system_category.name }}</option>
                            </select>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="een_name">
                                    EN Name <span class="text-danger">*</span>
                                </label>
                                <input v-model="edata.en_name" id="een_name" type="text" name="en_name" class="form-control" required>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="ekh_name">
                                    KH NAME 
                                </label>
                                <input v-model="edata.kh_name" id="ekh_name" type="text" name="kh_name" class="form-control" >
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="eemail">
                                    Email 
                                </label>
                                <input v-model="edata.email" id="eemail" type="text" name="email" class="form-control" >
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="ephone_line1">
                                    Phone Line 1 
                                </label>
                                <input v-model="edata.phone_line1" id="ephone_line1" type="text" name="phoneline1" class="form-control" >
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="ephone_line2">
                                    Phone Line 2 
                                </label>
                                <input v-model="edata.phone_line2" id="ephone_line2" type="text" name="phoneline2" class="form-control" >
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="ephone_line3">
                                    Phone Line 3 
                                </label>
                                <input v-model="edata.phone_line3" id="ephone_line3" type="text" name="phoneline3" class="form-control" >
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="ephone_line4">
                                    Phone Line 4 
                                </label>
                                <input v-model="edata.phone_line4" id="ephone_line4" type="text" name="phoneline4" class="form-control" >
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="einvoice_header">
                                    Invoice Header 
                                </label>
                                <textarea v-model="edata.invoice_header" id="einvoice_header" rows="3" name="invoiceHeader" class="form-control" > </textarea>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="einvoice_footer">
                                    Invoice Footer 
                                </label>
                                <textarea v-model="edata.invoice_footer" id="einvoice_footer" rows="3" name="InvoiceFooter" class="form-control"> </textarea>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="eaddress">
                                    Address 
                                </label>
                                <textarea v-model="edata.address" id="eaddress" rows="3" class="form-control"></textarea>
                            </div>     
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label>
                                    Latitude 
                                </label>
                                <input v-model="edata.latitude" type="text" id="elat" class="form-control"/>
                            </div>     
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label>
                                    Longitude 
                                </label>
                                <input v-model="edata.longitude" type="text" id="elng" class="form-control"/>
                            </div>     
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="elogo" class="d-block">
                                    Logo 
                                </label>
                                <input type="file" class="d-block mb-2" @change="Imglogo($event)">
                                <img id="elogo" src="" class="w-25 rounded-circle mt-2">
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">                      
                            <div class="form-group mb-1">
                                <label for="ebanner" class="d-block">
                                    Banner 
                                </label>
                                <input type="file" class="d-block mb-2" @change="ImgBanner($event)">
                                <img id="ebanner" src="" class="w-100 rounded mt-2">
                            </div>
                        </div>
                    </div>
                </div>
              <div class="modal-footer">
                <button :disabled="isUpdate" type="submit" class="btn btn-system btn-xs">
                    <img v-if="isUpdate" src="../../assets/sniper.jpeg" alt="" width="12">
                    <i v-else class="fa fa-save"></i> Update
                </button>
                <button @click="triggerReset()" type="button" class="btn btn-danger btn-xs" data-dismiss="modal">
                    <i class="fa fa-times"></i> Close
                </button>
              </div>
          </div>
      </form>
    </div>
</div>  
</template>

<script>
import { updateCompany } from '@/networks/services/dashboard/company.service';
import axios from 'axios';
import { config } from '@/networks/config';

export default {
    props: {
        system_categories: {
            type: [String, Object],
            default: null,
        },
        edata : {
            type : [Object, Array],
            default : {}
        }
    },
    data(){
        return {
            tbl : "companies",
            per : "company_list",
            lo: '',
            ban: '',
            isUpdate : false,
        }
    },
    methods: {  
        Imglogo(obj){
            let logo = URL.createObjectURL(obj.target.files[0]);
            $('#elogo').attr("src",logo);
            this.lo = obj.target.files[0];
        },
        ImgBanner(obj){
            let banner = URL.createObjectURL(obj.target.files[0]);
            $('#ebanner').attr("src",banner);
            this.ban = obj.target.files[0];
        },
        triggerReset(){
            this.isUpdate = false;
            this.log = '';
            this.ban = '';
        },
        async form_update(){
            config();
            this.isUpdate = true;
            var data = new FormData();
            data.append('id',$('#eid').val());
            data.append('system_category_id',$('#esystem_category_id').val());
            data.append('en_name',$('#een_name').val());
            data.append('kh_name',$('#ekh_name').val());
            data.append('address',$('#eaddress').val());
            data.append('email',$('#eemail').val());
            data.append('invoice_header',$('#einvoice_header').val());
            data.append('invoice_footer',$('#einvoice_footer').val());
            data.append('phone_line1',$('#ephone_line1').val());
            data.append('phone_line2',$('#ephone_line2').val());
            data.append('phone_line3',$('#ephone_line3').val());
            data.append('phone_line4',$('#ephone_line4').val());
            data.append('latitude',$('#elat').val());
            data.append('longitude',$('#elng').val());
            data.append('logo',this.lo);
            data.append('banner',this.ban);


            const result = await axios
                            .post(updateCompany,data)
                            .catch(error => {
                                console.log(error.response)
                            })
            this.triggerReset();
            if(result.data.status == "success"){
                $('#editModal').modal('hide');
                $("#edit_form")[0].reset();
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
                this.$emit("editModal","success");   
            } else if(result.data.status == "401"){
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
                localStorage.removeItem('posUser');
                this.$router.push('/Unauthenticated');
            } else if(result.data.status == 'is_permission_no'){
                $('#editModal').modal('hide');
                $("#edit_form")[0].reset();
                alertify.set('notifier','position', 'top-right');
                alertify.error(`You are no permission to do this stuff!`);
                this.$emit("editModal","is_permission_no");   
            } else if(result.data.status == "error"){
                $('#editModal').modal('hide');
                $("#edit_form")[0].reset();
                alertify.set('notifier','position', 'top-right');
                alertify.error(`${result.data.message}`);
            } else {
                console.log(result)
            }
            
            this.triggerReset();  
        },
    },
    mounted(){
        $(document).ready(function() {
            $('.chosen2').select2();
        });
    }
}
</script>

<style>

</style>