<template>
  <content-header title="Company Detail" icon="fa fa-angle-double-right"/>
  <content>
    <loading v-if="isTrue"/>
    <div v-else class="container-fluid">
      <companyInfoVue :btnBack="true" :companyInfo="companyInfo"></companyInfoVue>  


      <div class="card">

        <!-- button action -->
        <div class="card-header">
          <button :disabled="isCreate" v-if="create" class="btn btn-system btn-sm mb-2" @click="createModal()">
            <img v-if="isCreate" src="../../assets/sniper.jpeg" alt="" width="12">
            <i v-else class="fa fa-plus-circle"></i> CREATE
          </button>
          &nbsp;
          <button @click="reloadData()" type="button" class="btn btn-warning btn-sm mb-2"><i class="fa fa-exchange-alt mr-1"></i>RELOAD DATA</button>      

          
          &nbsp;
          <select v-model="table.orderBy" @change="btnOrderBy()" class="selectPicker form-control-sm float-sm-right mb-2 p-0">
            <option value="DESC">ORDER BY : NEWEST</option>
            <option value="ASC">ORDER BY : OLDEST</option>
          </select>
         
        </div>



        <div class="card-body">



          <!-- button search and option data  -->
          <div class="row">
            <div class="col-sm-12 col-md-6">
              Show
              <label> 
                <select v-model="table.per_page" @change="btnPage('per_page')"  data-live-search="false" class="selectPicker form-control form-control-sm">
                  <option :value="10*index+10" v-for="(p,index) in 5" :key="index">{{10*(index+1)}}</option>
                </select> 
              </label>
              entries
            </div>
            <div class="col-sm-12 col-md-6 col-12">
                <div class="float-md-right">
                  Search:
                  <label>
                    <input v-model="search" type="search" class="form-control form-control-sm">
                  </label>
                </div>
            </div>
          </div>




          <!-- table  -->
          <div class="table-responsive position-relative">
            <table class="table data-table table-sm table-bordered position-relative" style="width: 100%">
              <thead class="bg-system text-light">
                <tr>
                  <th class="d-none"></th>
                  <th>#</th>
                  <th>System Service Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="table.data.length">
                <tr v-for="(data, index) in table.data" :key="data.id" :id="`tr${data.id}table`">
                  <td class="d-none"></td>
                  <td>{{ table.from_index + index}}</td>
                  <td :data-col="`#`+ (table.from_index+index) + `. System Service Name`">{{ data.system_name }}
                    <button :data-row = "`tr${data.id}table`" onclick="toggleRow(this)" class="clicker btn btn-xs bg-system text-white rounded-circle">
                      <i class="fas fa-plus-circle"></i>
                    </button>
                  </td> 
                  <td data-col="Action">
                    <button v-if="deleteAll" type="button" @click="alertRemove(data.id,'single')" 
                        class='btn btn-danger btn-xs' title='delele'>
                        <img :id="`deleteImg${data.id}`" class="d-none" src="../../assets/sniper.jpeg" alt="" width="12">
                        <i :id="`deleteFa${data.id}`" class='fa fa-trash'></i>
                    </button>
                    <input :id="`hiddenDelete${data.id}`" type="hidden" @click="btnDelete(data.id)">
                  </td>
                </tr>    
              </tbody>
            </table>
          </div>
           <!-- Processing loader  -->
              
            <div v-if="pageLoad" class="card position-absolute " style="z-index:10; left: 45%; top: 50%;">
                <div class="card-body">
                <span class="txt-system">Processing...</span>
                </div>
            </div>

            <!-- no data -->
            <div v-if="!table.data.length" class="card">
                <div class="card-body text-center">
                  <span class="txt-system">No Data...</span>
                </div>
            </div>
    


          <!-- button table  -->
          <div class="row">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info py-2">Showing {{ table.from_index }} to {{ table.to_index }} of {{ table.totalItem }} entries</div>
            </div>
            <div class="col-sm-12 col-md-7">
              <div class="dataTables_paginate paging_simple_numbers float-md-right">
                <ul class="pagination">
                  <li  class="page-item mr-3">
                    <select @change="btnPage()" v-model="table.page" data-live-search="true" id="page" class="selectPicker border-page-color">
                     <template v-if="table.totalItem != 0">
                      <option v-for="(page,index) in selectOptions" :key="index" :value="index + 1">Page {{ index + 1 }} of {{ table.totalIndexPage }}</option>
                     </template>
                    </select>
                  </li>
                  <li @click="btnPage('prev',this.table.page-1)" class="border-top-left-radius border-bottom-left-radius" :class="table.page > 1 ? 'active' : ''">
                    <button  :disabled="table.page == 1 || table.totalIndexPage == 0"  type="button" class="link-page border-top-left-radius border-bottom-left-radius" :class="table.page == 1 || table.totalIndexPage == 0 ? 'txt-gray' : ''">&lt;&lt;</button>
                  </li>
                  <li @click="btnPage('next',this.table.page+1)" class="page-item">
                    <button :disabled="table.totalIndexPage == 1 || table.totalIndexPage == table.page" type="button" class="link-page-right" :class="table.totalIndexPage == 1 || table.totalIndexPage == table.page ? 'txt-gray' : ''">&gt;&gt;</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>




        </div>
      </div>
    </div>
  </content>
  <content-header/>
  <CreateCompanyServiceView v-if="create"  :services="service" @createModal="JustCreateModal($event)"></CreateCompanyServiceView>
</template>

<script>
import axios from 'axios'
import companyInfoVue from '@/components/company/companyInfo.vue'
import { companyService, serviceSystem , deleteCompanySystemService } from '@/networks/services/dashboard/company.service'
import { checkPermission } from '@/networks/services/helper/helper.js'
import Loading from '@/components/loading/Loading'
import { config } from '@/networks/config'
import { remove } from '@/networks/services/helper/button.js'
import CreateCompanyServiceView from '@/views/companys/CreateCompanyServiceView'
import { resource } from '@/networks/domain'




export default {
  components: { 
    Loading, CreateCompanyServiceView , companyInfoVue
  },
  data(){
    return {
      table : {
        page: 1,
        totalIndexPage: 0,
        totalItem: 0,
        per_page: 10,
        from_index:0,
        to_index:0,
        data: [],
        search: null,
        orderBy: 'DESC'
      },
      companyInfo : [],
      company_id : this.$route.params.id,
      isCreate : false,
      search: null,
      service: null,

      permission_no: false,
      isTrue: true,
      pageLoad: false,
      create: false,
      deleteAll : false,
      edit: false,
      list: false,
    }
  },
  computed:{
    selectOptions(){
        let opts = this.table.totalIndexPage;
        return opts
    }
  },
  watch: {
    search(after, before){  
      this.table.search = this.search
      this.pageLoad = true;
      this.table.page = 1;
      this.getData(false);  
    },
  }
  ,
  methods : {
    img(obj){
        return resource+obj;
    },
    reloadData(){
        this.table.orderBy = 'DESC';
        this.table.per_page = 10;
        this.table.page = 1;
        this.isTrue = true;
        this.getData(false);
    },
    btnOrderBy(){
      this.pageLoad = true;
      this.getData(false);
    },
    JustCreateModal(event){
      if(event == "success"){
        if(this.table.data.length){
            this.pageLoad = true;
            this.getData(false);
        } else {
            this.isTrue = true;
            this.getData(false);
        }
      } 
      if(event == "is_permission_no"){
        this.isTrue = true;
        this.getData(false);
      }
    },
    alertRemove(id,type=null){
      alertify.confirm('Delete', 'Are you sure to do this stuff ?', function(){ 
        if(type == "single"){
          $(`#hiddenDelete${id}`).trigger("click");
        }
        if(type == "plural"){
          $(`#${id}`).trigger("click");
        }
      }
      , function(){ });
    },
    async btnDelete(id){

        config();    
        const deleteService = await axios
                                .get(`${deleteCompanySystemService(id)}`)
                                .catch(error => { console.log(error)});


        if(deleteService.data.status == "success"){
            this.pageLoad = true;
            this.getData(false);
            alertify.set('notifier','position', 'top-right');
            alertify.success(`${deleteService.data.message}`);
        } else if(deleteService.data.status == "error"){
            alertify.set('notifier','position', 'top-right');
            alertify.error(`${deleteService.data.message}`);
        } else if(deleteService.data.status == '401'){

            alertify.set('notifier','position', 'top-right');
            alertify.error("Unauthenticated");
            localStorage.removeItem('posUser');
            this.$router.push('/Unauthenticated');

        } else if(deleteService.data.status = 'is_permission_no'){

            this.isTrue = true;
            this.getData(false); 
            alertify.set('notifier','position', 'top-right');
            alertify.error('You are no permission to do this stuff!');

        } else{
            console.log(deleteService);
        }
    },

    btnPage(e,value = null){
      this.pageLoad = true;
      if(e == 'prev'){
        this.table.page = value;
        this.getData(false);
      } 
      if(e == 'next'){
        this.table.page = value;
        this.getData(false);
      } else if(e == 'per_page'){
        this.table.page = 1;
        this.getData(false);
      } else {
        this.getData(false);
      }
    },    
    totalPage(){
      return this.table.totalIndexPage;
    },
    async createModal(){
        config();
   
        this.isCreate = true;
        
        const getService = await axios
                                .get(`${serviceSystem}`)
                                .catch(error => { console.log(error.response)});


        if(getService.data.status == "success"){
            this.service = getService.data.data;
            if(this.isCreate){
                this.isCreate = false;
                $('#createModal').modal('show');
            }

            this.reloadSelectOption();

        } else if(getService.data.status == '401'){

            alertify.set('notifier','position', 'top-right');
            alertify.error("Unauthenticated");
            localStorage.removeItem('posUser');
            this.$router.push('/Unauthenticated');

        } else if(getService.data.status = 'is_permission_no'){

            this.isTrue = true;
            this.getData(false); 
            alertify.set('notifier','position', 'top-right');
            alertify.error('You are no permission to do this stuff!');

        } else{
            console.log(getService);
        }
    },
    async reloadSelectOption(){
      await this.$nextTick();
      $('.selectPicker').selectpicker('refresh');
      $('.selectPicker').selectpicker();
    },
    async getData(id){
      if(localStorage.getItem('posUser')){
        config();
        if(id){
            this.createModal(false);
        }
        axios
          .get(`${companyService(this.table.per_page,this.table.page,id,this.table.search,this.table.orderBy,this.company_id)}`)
          .then(response => {
            if(response.data.status === 'success'){
              if(!id){
                this.pageLoad = false;
                
                //table
                this.companyInfo = response.data.companyInfo;
                this.table.totalIndexPage = response.data.data.last_page;
                this.table.data = response.data.data.data; 
                this.table.totalItem = response.data.data.total;
                this.table.from_index = response.data.data.from ? response.data.data.from : 0;
                this.table.to_index = response.data.data.to ? response.data.data.to : 0;

                //permission
                this.list = response.data.list;
                this.create = response.data.create;
                this.deleteAll = response.data.delete;
                this.edit = response.data.edit;
      

                this.isTrue = false;
                $('#checkBox').prop('checked',false);

              } else {
                $('#eid').val(response.data.data.id);
                $(`#esystem_category_id option[value = ${response.data.data.system_category_id}]`).attr("selected",true);
                $('#een_name').val(response.data.data.en_name);
                $('#ekh_name').val(response.data.data.kh_name);
                $('#eemail').val(response.data.data.email);
                $('#ephone_line1').val(response.data.data.phone_line1);
                $('#ephone_line2').val(response.data.data.phone_line2);
                $('#ephone_line3').val(response.data.data.phone_line3);
                $('#ephone_line4').val(response.data.data.phone_line4);
                $('#einvoice_header').val(response.data.data.invoice_header);
                $('#einvoice_footer').val(response.data.data.invoice_footer);
                $('#eaddress').val(response.data.data.address);
                $('#elogo').attr("src",`${resource+response.data.data.logo}`);
                $('#ebanner').attr("src",`${resource+response.data.data.banner}`);

                $(`#editImg${id}`).toggleClass('d-none');
                $(`#editFa${id}`).toggleClass('d-none');
                $(`[button=edit${id}]`).prop("disabled", false);
                $('#editModal').modal();
              }
                
            } else if(response.data.status === 401) {
              alertify.set('notifier','position', 'top-right');
              alertify.error(`${response.data.message}`);
              localStorage.removeItem('posUser');
              this.$router.push('/Unauthenticated');
            } else if(response.data.status == "is_permission_no"){
              this.permision_no = true;
            }
            else {
              console.log('something wrong with api');
            }
          })
          .then(()=>{
            this.reloadSelectOption();
          })
          .catch(error => {
              console.log(error)
            }
          );
      } else {
        this.$router.push('/Unauthenticated')
      }
    },
  },
  created(){
      this.getData(false);
  },
  mounted(){
    $("[id='Company Management']").addClass("menu-open");
    $("[id='Company Management']>a").addClass("active");
    $("[id='Company Management']>ul").css({'display' : 'block'});
    $("[id='Company List']").addClass("active");
  }
}
</script>

<style>

</style>