<template>
    <div class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
        <form action="ohyes" method="POST" id='create_form' @submit.prevent="form_submit()">
            <input id="create_status" type="hidden" value="error">
            <div class="modal-content modal-lg">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Create Company</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true" >&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-12">
                            <label for="system_category">Category <span class="text-danger">*</span></label>
                            <select v-model="cat_id" id="system_category" class="selectPicker form-control" required>
                                <option value="">Please Select One</option>
                                <option :value="system_category.id" v-for="system_category in system_categories" :key="system_category.id">{{ system_category.name }}</option>
                            </select>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label>
                                    EN Name <span class="text-danger">*</span>
                                </label>
                                <input v-model="en_name" type="text" name="en_name" class="form-control" required>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label>
                                    KH NAME 
                                </label>
                                <input v-model="kh_name" type="text" name="kh_name" class="form-control" >
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label>
                                    Email 
                                </label>
                                <input v-model="email" type="text" name="email" class="form-control" >
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label>
                                    Phone Line 1 
                                </label>
                                <input v-model="phone_line1" type="text" name="phoneline1" class="form-control" >
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label>
                                    Phone Line 2 
                                </label>
                                <input v-model="phone_line2" type="text" name="phoneline2" class="form-control" >
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label>
                                    Phone Line 3 
                                </label>
                                <input v-model="phone_line3" type="text" name="phoneline3" class="form-control" >
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label>
                                    Phone Line 4 
                                </label>
                                <input v-model="phone_line4" type="text" name="phoneline4" class="form-control" >
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label>
                                    Invoice Header 
                                </label>
                                <textarea v-model="invoice_header" rows="3" name="invoiceHeader" class="form-control" > </textarea>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label>
                                    Invoice Footer 
                                </label>
                                <textarea v-model="invoice_footer" rows="3" name="InvoiceFooter" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label>
                                    Address 
                                </label>
                                <textarea v-model="address" rows="3" class="form-control"></textarea>
                            </div>     
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label>
                                    Latitude 
                                </label>
                                <input type="text" v-model="lat" class="form-control"/>
                            </div>     
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label>
                                    Longitude 
                                </label>
                                <input type="text" v-model="lng" class="form-control"/>
                            </div>     
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label class="d-block">
                                    Logo 
                                </label>
                                <input type="file" class="d-block mb-2" @change="Imglogo($event)">
                                <img :src="logo" class="w-25 rounded-circle mt-2">
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">                      
                            <div class="form-group mb-1">
                                <label class="d-block">
                                    Banner 
                                </label>
                                <input type="file" class="d-block mb-2" @change="ImgBanner($event)">
                                <img :src="banner" class="w-100 rounded mt-2">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="triggerReset()" type="button" class="btn btn-outline-dark btn-xs">
                        <i class="fas fa-sync"></i> Reset
                    </button>
                    <button :disabled="isCreate" id="save" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isCreate" src="../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" 
                        @click="triggerReset()">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>  
</template> 
<script>
// import { frm_submit } from '@/networks/services/helper/button'
import { config } from '@/networks/config';
import axios from 'axios';
import { createCompany } from '@/networks/services/dashboard/company.service.js'
import Textarea from '@/components/forms/Textarea.vue';

export default {
  components: { Textarea },
    props: {
        system_categories: {
            type: [String, Object],
            default: null,
        },
    },
    data(){
        return {
            cat_id : '',
            en_name : '',
            kh_name : '',
            address : '',
            email : '',
            logo : '',
            banner : '',
            invoice_header : '',
            invoice_footer : '',
            phone_line1: '',
            phone_line2: '',
            phone_line3: '',
            phone_line4: '',
            lat : '',
            lng: '',
            lo: '',
            ban: '',
            tbl : "companies",
            per : "company_list",
            isCreate : false,
        }
    },
    methods: {
        Imglogo(obj){
            this.logo = URL.createObjectURL(obj.target.files[0]);
            this.lo = obj.target.files[0];
        },
        ImgBanner(obj){
            this.banner = URL.createObjectURL(obj.target.files[0]);
            this.ban = obj.target.files[0];
        },
        triggerReset(){
            $('input[type="file"]').val('');
            this.cat_id = '';
            this.en_name  = '';
            this.kh_name  = '';
            this.address  = '';
            this.email  = '';
            this.logo  = '';
            this.banner  = '';
            this.invoice_header  = '';
            this.invoice_footer  = '';
            this.phone_line1 = '';
            this.phone_line2 = '';
            this.phone_line3 = '';
            this.phone_line4 = '';
            this.lat = '';
            this.lng = '';
            this.lo = '';
            this.ban = '';
            this.isCreate = false;
        },
        async form_submit(){
            config();
            this.isCreate = true;
            var data = new FormData();
            data.append('system_category_id',this.cat_id);
            data.append('en_name',this.en_name);
            data.append('kh_name',this.kh_name);
            data.append('address',this.address);
            data.append('email',this.email);
            data.append('invoice_header',this.invoice_header);
            data.append('invoice_footer',this.invoice_footer);
            data.append('phone_line1',this.phone_line1);
            data.append('phone_line2',this.phone_line2);
            data.append('phone_line3',this.phone_line3);
            data.append('phone_line4',this.phone_line4);
            data.append('logo',this.lo);
            data.append('banner',this.ban);
            data.append('latitude',this.lat);
            data.append('longitude',this.lng);


            const result = await axios
                            .post(createCompany,data)
                            .catch(error => {
                                console.log(error.response)
                            })
            this.triggerReset();
            if(result.data.status == "success"){
                $('#createModal').modal('hide');
                $("#create_form")[0].reset();
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
                this.$emit("createModal","success");   
            } else if(result.data.status == "401"){
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
                localStorage.removeItem('posUser');
                this.$router.push('/Unauthenticated');
            } else if(result.data.status == 'is_permission_no'){
                alertify.set('notifier','position', 'top-right');
                alertify.error(`You are no permission to do this stuff!`);
                this.$emit("createModal","is_permission_no");   
            } else if(result.data.status == "error"){
                $('#createModal').modal('hide');
                $("#create_form")[0].reset();
                $("#create_status").val("error");
                alertify.set('notifier','position', 'top-right');
                alertify.error(`${result.data.message}`);
            } else {
                console.log(result)
            }
            
            this.triggerReset();

        },
    },
    mounted(){
        $(document).ready(function() {
            $('.chosen2').select2();
        });
    }
}
</script>
