import { domain } from "../../domain";

export {
    expenseCategory
}

function expenseCategory(per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/get-expense-category?id=${id}`;
    } else if(name){
        return `${domain}/pos/get-expense-category?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}`
    } else {
        return `${domain}/pos/get-expense-category?per_page=${per_page}&page=${page}&orderBy=${orderBy}`;
    }
}