<template>
    <nav class="mt-2">
        <ul v-if="checkNavBar" class="nav nav-pills nav-sidebar flex-column">
            <div v-for="(menu, index) in menus" :key="index">
                <div v-if="!menu.subItems">
                    <NavItem 
                      :permission="menu.permission" 
                      :name="menu.label" 
                      :link="menu.link" 
                      :icon="menu.icon" 
                      :dropdown="false"
                      :navBar="navBar"
                      :checkNavBar="checkNavBar"
                    />
                </div>
                <div v-if="menu.subItems">
                    <NavItem 
                      :name="menu.label" 
                      :subItems="menu.subItems" 
                      :link="menu.link" 
                      :icon="menu.icon" 
                      :dropdown="true"
                      :navBar="navBar"
                      :checkNavBar="checkNavBar"
                    />
                </div>
            </div>
        </ul>
    </nav>
</template>
<script>
import NavItem from '@/layouts/inc/NavItem'
export default {
    components: {
        NavItem,
    },
    props : {
      navBar: {
          type: [Array, Object],
          default: [],
      },
      checkNavBar: {
          type: [Boolean],
          default: false,
      },
    },
    data() {
    return {
      menus: 
      [
        {
          label: "Dashboard",
          icon: "fa fa-home",
          link: "/",
          permission : "home",
          default : "pos" 
        },
        // {
        //   label: "Dashboard",
        //   icon: "fa fa-home",
        //   link: "/detail",
        //   permission : "home",
        //   default : "pos" 
        // },
        {     
            label: "Security",
            icon: "fas fa-shield-alt",
            default: 'pos',
            subItems: [
              {  
                  label: "Role",
                  icon: "fas fa-user-shield",
                  link: "/role",
                  permission : "role"
              },
              {         
                label: "User",
                icon: "fas fa-user",
                link: "/user",
                permission : "user"
              },
              {         
                label: "User Log",
                icon: "fas fa-users-cog",
                link: "/user-log",
                permission : "user_log"
              },
            ]
        },
        {
          label: "Setting",
          icon: "fas fa-cogs",
          subItems: 
            [
              {         
                label: "My Profile",
                icon: "fas fa-user",
                link: "/about-me",
                permission : "about_me"
              }, 
              {   
                label: "Company Info",
                icon: "fas fa-info-circle",
                link: "/company-info",
                permission : "company_info"
              }, 
            ]
        },
        {
          label: "System Management",
          icon: "fas fa-cogs",
          default: 'system',
          subItems: 
            [
              {         
                label: "Permission",
                icon: "fas fa-user-secret",
                link: "/permission",
                permission : "permission"
              }, 
              {         
                label: "System Service",
                icon: "fas fa-dragon",
                link: "/system-service",
                permission : "system"
              },
              {         
                label: "System Category",
                icon: "fas fa-grip-horizontal",
                link: "/system-category",
                permission : "system_category"
              }, 
              {         
                label: "Main Category",
                icon: "fas fa-grip-horizontal",
                link: "/main-category",
                permission : "main_category"
              }, 
              {         
                label: "Product Brand",
                icon: "fas fa-grip-horizontal",
                link: "/product-brand",
                permission : "brand"
              }, 
            ]
        },
        {
          label: "Company Management",
          icon : "fas fa-cogs",
          default: 'system',
          subItems: [
            {  
              label: "Company List",
              icon: "fa fa-angle-double-right",
              link: "/company-list",
              permission : "company_list"
            },
            {  
              label: "Company Role",
              icon: "fa fa-angle-double-right",
              link: "/company-role",
              permission : "company_role"
            },
          ]
        },
        {
          label: "Product Management",
          icon : "fas fa-cogs",
          default: 'system',
          subItems: [
            {  
              label: "Attribute",
              icon: "fa fa-angle-double-right",
              link: "/attribute-system",
              permission : "attribute_system"
            },
          ]
        },
      ]
    }
  },
  mounted(){
    
  }
}
</script>