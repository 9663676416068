<template>
    <div class="modal modal-add fade" id="addPaymentModal" tabindex="-1" role="dialog" aria-labelledby="addPaymentModal" aria-hidden="true">
        <div class="modal-dialog modal-md" role="document">
        <form action="ohyesedit" method="POST" @submit.prevent="addPayment()">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Add Payment</strong>
                    <button type="button" class="close" @click="closeModal()">
                        <span class="text-white">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <h5 class="text-danger">Exchange Rate : $ {{ exchangeRate.usd }} = {{ exchangeRate.khr }}</h5>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="mb-1">
                                <label for="paymentMethod" class="form-group">Payment Methods</label>
                                <select v-model="method_id" id="category_id" class="border-page-color selectPicker form-control" data-live-search="false">
                                    <option value="">PLEASE SELECT ONE</option>
                                    <option :value="method.id" v-for="method in paymentMethod" :key="method.id">{{ method.name }}</option>
                                </select>
                            </div>
                            <div class="mb-1">
                                <label for="addAmountPayment">Amount</label>
                                <div class="input-group">
                                    <input 
                                        id="addAmountPayment"
                                        type="number" 
                                        min="0" 
                                        :max="debt" 
                                        step="0.001"
                                        class="form-control"
                                    >
                                    <div class="input-group-append">
                                        <label class="input-group-text bg-system text-white">$</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="mb-1">
                                <label for="photo" class="form-group">Payment Image</label>
                                <div class="w-100">
                                    <input id="photo" @change="changeImg('photo',$event)" type="file" class="form-control">
                                    <button type="button" @click="resetImg('photo')" v-if="pho" title="Reset Photo" class="border-0 rounded outline-0 float-right btn-outline-dark"><i class="fas fa-redo-alt"></i></button>
                                    <img v-if="phoo" :src="photo" class="my-2 w-25 rounded">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button :disabled="isAdd" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isAdd" src="@/assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Add
                    </button>
                    <button @click="closeModal()" type="button" class="btn btn-danger btn-xs">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>  
</template> 
<script>
import { resource } from '@/networks/domain'
import axios from 'axios';
import { addPayment } from '@/networks/services/pos/invoicePos.service';
import { exchangeRate } from '@/networks/services/pos/exchangeRate.service';

export default {
    emits : ["addDeptPayment"],
    props : {
        debt: {
            type: [String, Number],
            default: 0,
        },
        paymentMethod : {
            type : [Object,Array],
            default : []
        },
        id : {
            type : [Number, String],
            default : 0
        },
        exchangeRate : {
            type : [Object, Array],
            default : {}
        }
    },
    data(){
        return {
            resource : resource,
            isAdd : false,
            method_id: '',
            photo : '',
            pho: false,
            phoo: null,
        }
    },
    methods: {
        resetImg(value){
            if(value == "photo"){
                this.photo = '';
                this.pho = false;
                this.phoo = null;
                $('#photo').val('');
            }
        },
        changeImg(value,obj){
            if(value == "photo"){
                this.photo = URL.createObjectURL(obj.target.files[0]);
                this.phoo = obj.target.files[0];
                this.pho = true;
            }
        },
        closeModal(){
            $('#addPaymentModal').modal('hide');
        },
        async addPayment(){
            if($('#addAmountPayment').val() == ''){
                alertify.set('notifier','position', 'top-right');
                alertify.error('Amount is require');
            } else if(this.method_id == ''){
                alertify.set('notifier','position', 'top-right');
                alertify.error("Payment Method is require !");
            } else {
                this.isAdd = true;
                let amount_khr = Number($('#addAmountPayment').val()) * Number(this.exchangeRate.khr);

                var data = new FormData();
                data.append('photo',this.photo);
                data.append('invoice_id',this.id);
                data.append('payment_method_id',this.method_id);
                data.append('payment_method_name',this.paymentMethod.filter((item)=>item.id == this.method_id)[0].name);
                data.append('amount_usd',$('#addAmountPayment').val());
                data.append('amount_khr',Number(amount_khr).toFixed(2));
                data.append('exchange_rate_khr',this.exchangeRate.khr);
                data.append('exchange_rate_usd', this.exchangeRate.usd);

                await axios.post(addPayment,data)
                .then((res)=>{
                    if(res.data.status == 'success'){
                        this.closeModal();
                        alertify.set('notifier','position', 'top-right');
                        alertify.success(`${res.data.message}`);
                        this.$emit("addDeptPayment","success");   
                    }else if(res.data.status == "401"){
                        alertify.set('notifier','position', 'top-right');
                        alertify.success(`${res.data.message}`);
                        localStorage.removeItem('posUser');
                        this.$router.push('/Unauthenticated');
                    } 
                    else if(res.data.status == "is_permission_no"){
                        this.closeModal();
                        alertify.set('notifier','position', 'top-right');
                        alertify.error(`${res.data.message}`);
                        this.$emit("addDeptPayment","is_permission_no");   
                    } 
                    else if(res.data.status == "error"){
                        alertify.set('notifier','position', 'top-right');
                        alertify.error(`${res.data.message}`); 
                    } else {
                        console.log(res)
                    } 
                })
                .catch((err)=>{
                    console.log(err);
                })
                this.isAdd = false;
            }
        }
    },
}
</script>
<style scoped>
.modal-add {
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>