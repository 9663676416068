<template>
  <content-header title="Exchange Rate" icon="fas fa-money-bill-wave"/>
  <content>
    <loading v-if="isTrue"/>
    <div v-else class="container-fluid">
      <div class="card">



        <!-- button action -->
        <div class="card-header">
          <button v-if="create" class="btn btn-system btn-sm mb-2" data-toggle='modal' data-target='#createModal'>
            <i class="fa fa-plus-circle"></i> CREATE
          </button>
          <span v-if="create" class="mr-2"></span>
          <button @click="reloadData()" type="button" class="btn btn-warning btn-sm mb-2"><i class="fa fa-exchange-alt mr-1"></i>RELOAD DATA</button>      

          
          &nbsp;
          <select v-model="table.orderBy" @change="btnOrderBy()" class="selectPicker form-control-sm float-sm-right mb-2 p-0">
            <option value="DESC">ORDER BY : NEWEST</option>
            <option value="ASC">ORDER BY : OLDEST</option>
          </select>
         
        </div>



        <div class="card-body">



          <!-- button search and option data  -->
          <div class="row">
            <div class="col-sm-12 col-md-6">
              Show
              <label> 
                <select v-model="table.per_page" @change="btnPage('per_page')"  data-live-search="false" class="selectPicker form-control form-control-sm">
                  <option :value="10*index+10" v-for="(p,index) in 5" :key="index">{{10*(index+1)}}</option>
                </select> 
              </label>
              entries
            </div>
            <div class="col-sm-12 col-md-6 col-12">
                <div class="float-md-right">
                  Search:
                  <label>
                    <input v-model="search" type="search" class="form-control form-control-sm">
                  </label>
                </div>
            </div>
          </div>




          <!-- table  -->
          <div class="table-responsive position-relative">
            <table class="table data-table table-sm table-bordered position-relative" style="width: 100%">
              <thead class="bg-system text-light">
                <tr>
                  <th class="d-none"></th>
                  <th>#</th>
                  <th>USD</th>
                  <th>KHR</th>
                </tr>
              </thead>
              <tbody v-if="table.data.length">
                <tr v-for="(data, index) in table.data" :key="data.id" :id="`tr${data.id}table`">
                  <td class="d-none"></td>
                  <td>{{ table.from_index + index}}</td>
                  <td :data-col="`USD`">${{ data.usd }}
                    <button :data-row = "`tr${data.id}table`" onclick="toggleRow(this)" class="clicker btn btn-xs bg-system text-white rounded-circle">
                      <i class="fas fa-plus-circle"></i>
                    </button>
                  </td>
                  <td data-col="KHR" class="txt-kh">{{ data.khr }}៛</td>
                </tr>    
              </tbody>
            </table>
          </div>
           <!-- Processing loader  -->
              
            <div v-if="pageLoad" class="card position-absolute " style="z-index:10; left: 45%; top: 50%;">
                <div class="card-body">
                <span class="txt-system">Processing...</span>
                </div>
            </div>

            <!-- no data -->
            <div v-if="!table.data.length" class="card">
                <div class="card-body text-center">
                  <span class="txt-system">No Data...</span>
                </div>
            </div>
    


          <!-- button table  -->
          <div class="row">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info py-2">Showing {{ table.from_index }} to {{ table.to_index }} of {{ table.totalItem }} entries</div>
            </div>
            <div class="col-sm-12 col-md-7">
              <div class="dataTables_paginate paging_simple_numbers float-md-right">
                <ul class="pagination">
                  <li  class="page-item mr-3">
                    <select @change="btnPage()" v-model="table.page" data-live-search="true" id="page" class="selectPicker border-page-color">
                     <template v-if="table.totalItem != 0">
                      <option v-for="(page,index) in selectOptions" :key="index" :value="index + 1">Page {{ index + 1 }} of {{ table.totalIndexPage }}</option>
                     </template>
                    </select>
                  </li>
                  <li @click="btnPage('prev',this.table.page-1)" class="border-top-left-radius border-bottom-left-radius" :class="table.page > 1 ? 'active' : ''">
                    <button  :disabled="table.page == 1 || table.totalIndexPage == 0"  type="button" class="link-page border-top-left-radius border-bottom-left-radius" :class="table.page == 1 || table.totalIndexPage == 0 ? 'txt-gray' : ''">&lt;&lt;</button>
                  </li>
                  <li @click="btnPage('next',this.table.page+1)" class="page-item">
                    <button :disabled="table.totalIndexPage == 1 || table.totalIndexPage == table.page" type="button" class="link-page-right" :class="table.totalIndexPage == 1 || table.totalIndexPage == table.page ? 'txt-gray' : ''">&gt;&gt;</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>




        </div>
      </div>
    </div>
  </content>
  <content-header/>
  <CreateExchangeRateViewVue v-if="create"  @createModal="JustCreateModal($event)"></CreateExchangeRateViewVue>
</template>

<script>
import axios from 'axios'
import { exchangeRate } from '@/networks/services/pos/exchangeRate.service.js'
import { checkPermission } from '@/networks/services/helper/helper.js'
import Loading from '@/components/loading/Loading'
import { config } from '@/networks/config'
import { remove, bulk_delete , check} from '@/networks/services/helper/button.js'
import CreateExchangeRateViewVue from './CreateExchangeRateView.vue'



export default {
  components: { 
    Loading, CreateExchangeRateViewVue
  },
  data(){
    return {
      table : {
        page: 1,
        totalIndexPage: 0,
        totalItem: 0,
        per_page: 10,
        from_index:0,
        to_index:0,
        data: [],
        search: null,
        orderBy: 'DESC'
      },
      search: null,

      permission_no: false,
      isTrue: true,
      pageLoad: false,
      create: false,
      deleteAll : false,
      edit: false,
      list: false,
    }
  },
  computed:{
    selectOptions(){
        let opts = this.table.totalIndexPage;
        return opts
    }
  },
  watch: {
    search(after, before){  
      this.table.search = this.search
      this.pageLoad = true;
      this.table.page = 1;
      this.getData(false);  
    },
  }
  ,
  methods : {
    reloadData(){
        this.table.orderBy = 'DESC';
        this.table.per_page = 10;
        this.table.page = 1;
        this.isTrue = true;
        this.getData(false);
    },
    btnOrderBy(){
      this.pageLoad = true;
      this.getData(false);
    },
    JustCreateModal(event){
      if(event == "success"){
        if(this.table.data.length){
            this.pageLoad = true;
            this.getData(false);
        } else {
            this.isTrue = true;
            this.getData(false);
        }
      } 
      if(event == "is_permission_no"){
        this.isTrue = true;
        this.getData(false);
      }
    },
    JustUpdateModel(event){
      if(event == "success"){
        this.pageLoad = true;
        this.getData(false);
      } 
      if(event == "is_permission_no"){
        this.isTrue = true;
        this.getData(false);
      }
    },
    btnEdit(id){
      
      $(`[button=edit${id}]`).prop("disabled", true);
      $(`#editImg${id}`).toggleClass('d-none');
      $(`#editFa${id}`).toggleClass('d-none');
      $('#editModal').on('hidden.bs.modal', function () {
        $(this).find('form').trigger('reset');
      })
      
      this.getData(id); 
    },
    alertRemove(id,type=null){
      alertify.confirm('Delete', 'Are you sure to do this stuff ?', function(){ 
        if(type == "single"){
          $(`#hiddenDelete${id}`).trigger("click");
        }
        if(type == "plural"){
          $(`#${id}`).trigger("click");
        }
      }
      , function(){ });
    },
    async btnDelete(id){
      let obj = {
        tbl : 'pos_exchanges',
        per : 'pos_exchange',
      };

      let event =  await remove(id,obj);
      if(event == "success"){
        this.pageLoad = true;
        this.getData(false);
      } 
      if(event == "is_permission_no"){
        this.isTrue = true;
        this.getData(false);
      }
    },
    async btnDeleteAll(){
      let obj = {
        tbl : "pos_exchanges",
        per : "pos_exchange"
      };
      let event = await bulk_delete(obj);
      if(event == "success"){
        this.pageLoad = true;
        this.getData(false);
      } 
      if(event == "is_permission_no"){
        $('#checkBox').prop('checked',false);
        this.isTrue = true;
        this.getData(false);
      }
    },
    btnChecky(obj){
      check(obj);
    },
    btnPage(e,value = null){
      this.pageLoad = true;
      if(e == 'prev'){
        this.table.page = value;
        this.getData(false);
      } 
      if(e == 'next'){
        this.table.page = value;
        this.getData(false);
      } else if(e == 'per_page'){
        this.table.page = 1;
        this.getData(false);
      } else {
        this.getData(false);
      }
    },    
    totalPage(){
      return this.table.totalIndexPage;
    },
    async reloadSelectOption(){
      await this.$nextTick();
      $('.selectPicker').selectpicker('refresh');
      $('.selectPicker').selectpicker();
    },
    async getData(id){
      if(localStorage.getItem('posUser')){
        config();
        axios
          .get(`${exchangeRate(this.table.per_page,this.table.page,id,this.table.search,this.table.orderBy)}`)
          .then(response => {
            if(response.data.status === 'success'){
              if(!id){
                this.pageLoad = false;
                
                //table
                this.table.totalIndexPage = response.data.data.last_page;
                this.table.data = response.data.data.data; 
                this.table.totalItem = response.data.data.total;
                this.table.from_index = response.data.data.from ? response.data.data.from : 0;
                this.table.to_index = response.data.data.to ? response.data.data.to : 0;

                //permission
                this.list = response.data.list;
                this.create = response.data.create;
                this.deleteAll = response.data.delete;
                this.edit = response.data.edit;


                this.isTrue = false;
                $('#checkBox').prop('checked',false);

              } else {
                console.log(response.data)
                $('#eid').val(response.data.data.id);
                $('#ename').val(response.data.data.name);
                $(`#editImg${id}`).toggleClass('d-none');
                $(`#editFa${id}`).toggleClass('d-none');
                $(`[button=edit${id}]`).prop("disabled", false);
                $('#editModal').modal();
              }
                
            } else if(response.data.status === 401) {
              alertify.set('notifier','position', 'top-right');
              alertify.error(`${response.data.message}`);
              localStorage.removeItem('posUser');
              this.$router.push('/Unauthenticated');
            } else if(response.data.status == "is_permission_no"){
              this.permision_no = true;
            }
            else {
              console.log('something wrong with api');
            }
          })
          .then(()=>{
            this.reloadSelectOption();
          })
          .catch(error => {
              console.log(error)
            }
          );
      } else {
        this.$router.push('/Unauthenticated')
      }
    },
  },
  created(){
      this.getData(false);
  },
  mounted(){
    
  }
}
</script>

<style>

</style>