<template>
   <div class="col-lg-3 col-6">
        <!-- small box -->
        <a href="/pos/add-sale" target="_blank" class="small-box bg-system" style="cursor:pointer;">
        <div class="inner">
            <h3 class="text-white">Add</h3>

            <p class="text-white">Sale &nbsp;<i class="fas fa-plus text-sm"></i></p> 
        </div>
        <div class="icon">
            <i class="fas fa-cash-register text-white"></i>
        </div>
        <div class="small-box-footer text-white">More info &nbsp;<i class="fas fa-arrow-circle-right"></i></div>
        </a>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>