<template>
    <div class="row py-3 pt-4 mb-4 px-4 border-0 bottom" style="position:sticky; top:0; left:0; background-color: #ffffff; z-index:1;">
             
        <div class="col-lg-1 col-12 mb-2 response-mb text-center">
            <!-- <router-link :to="{name : 'dashboard'}" class="py-0 px-0 btn text-white">
                <img v-if="logo" :src="resource + logo" style="height : 48px;" class="w-100 rounded shadow-system" alt="">
                <img v-else src="../../assets/noProductImage.jpeg" style="height : 48px;" class="w-100 rounded shadow-system" alt="">
            </router-link> -->
            <div class="py-0 px-0 btn text-white">
                <!-- <i class="fas fa-reply-all"></i> -->
                <img v-if="logo" :src="resource + logo" style="height : 48px;" class="w-100 rounded shadow-system" alt="">
                <img v-else src="../../assets/noProductImage.jpeg" style="height : 48px;" class="w-100 rounded shadow-system" alt="">
            </div>
        </div>
        <div class="col-lg-11 col-8 mb-2">
            <div class="input-group">
                <input type="search"
                     class="form-control p-4 border-0 search text-truncate" 
                     placeholder="Search all product here by barcode only..." 
                     aria-label="Search all product here by barcode only..." 
                     aria-describedby="button-addon2" 
                     v-model="data"
                     @keyup.enter="search()"
                >
                <div class="input-group-append">
                    <button 
                        class="btn btn-system border-0 px-5 rounded-right" 
                        style="letter-spacing:2px;" 
                        type="button" id="button-addon2"
                        @click="search()"
                    ><i class="fas fa-search"></i> Search</button>
                    <button type="button" @click="print()" class="btn btn-outline-system-sm border-system rounded-left ml-2">
                        <i class="fa fa-print"></i>
                    </button>
                </div>
            </div>
        </div>
        <!-- <div class="col-lg-1 col-1 mb-2 m-auto">
            <button type="button" class="btn btn-system float-right">
                <i class="fa fa-print"></i>
            </button>
        </div> -->
    </div>
</template>
<script>
    import { resource } from '@/networks/domain';
    export default {
        props : {
            logo : {
                type : [String],
                
            }
        },
        data(){
            return {
                data : '',
                resource : resource,
                timeout: null
            }
        },
        methods : {
            print(){
                window.print();
            },
            search(){
                // clear timeout variable
                // clearTimeout(this.timeout);
                
                // this.timeout = setTimeout(function () {
                //     // enter this block of code after 1 second
                //     // handle stuff, call search API etc.
                // }, 10000);
            
                // setTimeout(() => {
                //     this.$emit('search',this.data);   
                // }, 1000);
                this.$emit('search',this.data);   
            }
        }
    }

</script>
<style scoped>
@media screen and (max-width: 600px) {
    .px-5{
        padding: 8px 10px 8px 10px !important;
    }
}
</style>