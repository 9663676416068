<template>
    <div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
          <form action="uytyut" method="POST" id='edit_form' @submit.prevent="form_update($event)">
              <input type="hidden" name="id" id='eid' value="">
              <input id="edit_status" type="hidden">
              <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Edit Product</strong>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <!-- <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label>
                                Code <span class="text-danger">*</span>
                            </label>
                            <input type="text" name="code" id="ecode" v-model="edata.code" readonly class="form-control">
                        </div> -->
                        <div class="col-lg-4 col-sm-12form-group mb-1">
                            <label for="com">
                                Category <span class="text-danger">*</span>
                            </label>
                            <select v-model="edata.cat_id" id="ecategory_id" class="border-page-color selectPicker form-control" data-size="5"  data-live-search="true" required>
                                <option value="">PLEASE SELECT ONE</option>
                                <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
                            </select>
                        </div>
                        <div class="col-lg-4 col-sm-12form-group mb-1">
                            <label for="com">
                                Brand <span class="text-danger">*</span>
                            </label>
                            <select v-model="edata.brand_id" id="ecategory_id" class="border-page-color selectPicker form-control" data-live-search="true" required>
                                <option value="">PLEASE SELECT ONE</option>
                                <option :value="brand.id" v-for="brand in brands" :key="brand.id">{{ brand.name }}</option>
                            </select>
                        </div>
                        <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label>
                                Barcode <span class="text-danger">*</span>
                            </label>
                            <input v-model="edata.barcode" type="text" id="ebarcode" name="name" class="form-control" required
                                @keyup="checkBarcode()"
                            >
                            <p v-if="bar" :class="`${is_barcode == 0 ? 'text-success' : 'text-danger'} p-0 m-0`"><small>{{ is_barcode == 0 ? 'Good' : 'Duplicate' }}</small></p>
                        </div>
                        <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label>
                                Name <span class="text-danger">*</span>
                            </label>
                            <input v-model="edata.name" type="text" name="name" id="ename" class="form-control" required>
                        </div>
                        <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label>
                                Alert for low stock <span class="text-danger">*</span>
                            </label>
                            <input v-model="edata.alert" type="text" id="ealert"  name="on_hand" class="form-control" required
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            >
                        </div>
                        <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label>
                                Unit Price ($)<span class="text-danger">*</span>
                            </label>
                            <input v-model="edata.unit_price" type="text" id="eunit_price" name="on_hand" class="form-control" required
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            >
                        </div>
                        <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label for="evat">
                                VAT <span class="text-danger">*</span>
                            </label>
                            <select v-model="edata.vat_id" id="evat" class="border-page-color selectPicker form-control" data-live-search="false" required>
                                <option value="">PLEASE SELECT ONE</option>
                                <option :value="vat.id" v-for="vat in vats" :key="vat.id">{{ vat.name }} @{{ vat.value }}%</option>
                            </select>
                        </div>
                        <div class="col-lg-4 col-sm-12 form-group mb-1">
                            <label>
                                Original Price / Item ($) <span class="text-danger">*</span>
                            </label>
                            <input v-model="edata.original_price" type="number" min="0" step="0.001" name="on_hand" class="form-control"
                                
                                required
                            >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8 col-sm-12">
                            <div class="row my-2">
                                <div class="col-12">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="ecustomSwitch" @click="isCheckToOpen('stock')">
                                        <label class="custom-control-label" for="ecustomSwitch">Wholesales ?</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="edata.is_stock">
                                <div class="col-lg-4 col-sm-12 form-group mb-1">
                                    <label for="eunit_type_id">
                                        Unit Type <span class="text-danger">*</span>
                                    </label>
                                    <select v-model="edata.unit_type_id" id="eunit_type_id" class="border-page-color selectPicker form-control txt-kh" data-live-search="true" required>
                                        <option value="">PLEASE SELECT ONE</option>
                                        <option :value="unit_type.id" v-for="unit_type in unit_types" :key="unit_type.id">{{ unit_type.name }}</option>
                                    </select>
                                </div>
                                <div class="col-lg-4 col-sm-12 form-group mb-1">
                                    <label for="enum">
                                        Quality <span class="text-danger">*</span>
                                    </label>
                                    <input v-model="edata.quality" type="text" id="enum" class="form-control" required>
                                </div>
                                <div class="col-lg-4 col-sm-12 form-group mb-1">
                                    <label for="estock_unit_price_per_item">
                                        Stock Unit Price Per Item ($) <span class="text-danger">*</span>
                                    </label>
                                    <input v-model="edata.stock_unit_price_per_item" type="text" id="estock_unit_price_per_item" class="form-control" required>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-12 form-group my-2">
                            <label for="">Product Image</label>
                            <div class="w-100">
                                <input id="ephooo" @change="changeImg('photo',$event)" type="file">
                                <button type="button" @click="resetImg('photo')" v-if="pho" title="Reset Photo" class="border-0 rounded outline-0 float-right btn-outline-dark"><i class="fas fa-redo-alt"></i></button>
                                <img v-if="edata.photo" :src="edata.photo" id="ephoto" class="my-2 w-25 rounded">
                            </div>
                        </div>  
                    </div>
                </div>
                <div class="modal-footer">
                    <button :disabled="isUpdate" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isUpdate" src="../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Update
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
              </div>
          </form>
        </div>
    </div>  
    </template>
    
    <script>
    import { checkBarcode , update } from '@/networks/services/pos/product.service';
    import axios from 'axios';
    import { config } from '@/networks/config';
    
    export default {
        props : {
            categories: {
                type: [String, Object],
                default: null,
            },
            unit_types: {
                type: [String, Object],
                default: null
            },
            vats: {
                type: [String, Object, Array],
                default: ''
            },
            edata : {
                type: [String, Object, Number , Boolean],
                default : null
            },
            brands : {
                type: [String, Object, Number , Boolean],
                default : null
            }
        },
        data(){
            return {
                isUpdate : false,
                name : '',
                tbl : "pos_products",
                per : "pos_product",
                pho : false,
                phoo : '',
                is_barcode : 0,
                bar : false,
                oldPhoto : '',
            }
        },
        methods: {  
            async checkBarcode(){
                if(this.barcode != ''){
                    this.bar = true;
                    let data = {
                        barcode : this.edata.barcode
                    }
                    let is_barcode = await axios.post(checkBarcode, data)
                                            .catch(error => {
                                                console.log(error.response);
                                            });
                    if(is_barcode.data.status == "401"){
                        alertify.set('notifier','position', 'top-right');
                        alertify.success(`${is_barcode.data.message}`);
                        localStorage.removeItem('posUser');
                        this.$router.push('/Unauthenticated');
                    } else {
                        this.is_barcode = is_barcode.data.data;
                    }
                } else {
                    this.bar = false;
                }
            },
            async isCheckToOpen(value){
                if(value == "stock"){
                    if(this.edata.is_stock == false){
                        this.edata.is_stock = true;
                        await this.$nextTick();
                        $('.selectPicker').selectpicker('refresh');       
                        $('.selectPicker').selectpicker(); 
                    } else {
                        this.edata.is_stock = false;
                    }
                }
            },
            resetImg(value){
                if(value == "photo"){
                    this.edata.photo = this.oldPhoto;
                    this.pho = false;
                    this.phoo = '';
                    $('#ephooo').val('');
                }
            },
            changeImg(value,obj){
                if(value == "photo"){
                    this.oldPhoto = this.edata.photo;
                    this.edata.photo = URL.createObjectURL(obj.target.files[0]);
                    this.phoo = obj.target.files[0];
                    this.pho = true;
                }
            },
            async form_update(){
                config();
                this.isUpdate = true;
                var data = new FormData();
                data.append('id',$('#eid').val());
                data.append('code',this.edata.code);
                data.append('company_category_id',this.edata.cat_id);
                data.append('barcode',this.edata.barcode);    
                data.append('name',this.edata.name);
                data.append('alert',this.edata.alert);
                data.append('unit_price',this.edata.unit_price);
                data.append('is_onhand',this.edata.is_onhand ? 1 : 0);
                data.append('on_hand',this.edata.on_hand);
                data.append('original_price',this.edata.original_price);
                data.append('is_stock',this.edata.is_stock ? 1 : 0);
                data.append('pos_unit_type_id',this.edata.unit_type_id);
                data.append('quality_per_stock',this.edata.quality);
                data.append('vat_id',this.edata.vat_id);
                data.append('original_price',this.edata.original_price);
                data.append('stock_unit_price_per_item',this.edata.stock_unit_price_per_item);
                data.append('photo',this.phoo);
                data.append('brand_id',this.edata.brand_id);

                const result = await axios
                            .post(update,data)
                            .catch(error => {
                                console.log(error);
                            })
                this.isUpdate = false;
                if(result.data.status == "success"){
                    $('#editModal').modal('hide');
                    $("#edit_form")[0].reset();
                    alertify.set('notifier','position', 'top-right');
                    alertify.success(`${result.data.message}`);
                    this.$emit("editModal","success");   
                } else if(result.data.status == "401"){
                    alertify.set('notifier','position', 'top-right');
                    alertify.success(`${result.data.message}`);
                    localStorage.removeItem('posUser');
                    this.$router.push('/Unauthenticated');
                } else if(result.data.status == 'is_permission_no'){
                    $('#editModal').modal('hide');
                    $("#edit_form")[0].reset();
                    alertify.set('notifier','position', 'top-right');
                    alertify.error(`You are no permission to do this stuff!`);
                    this.$emit("editModal","is_permission_no");   
                } else if(result.data.status == "error"){
                    $('#editModal').modal('hide');
                    $("#edit_form")[0].reset();
                    alertify.set('notifier','position', 'top-right');
                    alertify.error(`${result.data.message}`);
                } else {
                    console.log(result)
                }
            },
        },
        mounted(){
            $(document).ready(function() {
                $('.chosen2').select2();
            });
        }
    }
    </script>
    
    <style>
    
    </style>