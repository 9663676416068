<template>
  <content-header title="Message" icon="fas fa-info"/>
  <content>
    <div class="row">
      <div class="col-6">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Messageassasasa</h3>
          </div>
          <div class="card-body">
            Hello Message page
          </div>
          <div class="card-footer">
            Footer Message page
          </div>
        </div>
      </div>
    </div>
  </content>
  <content-header/>
</template>

<script>
import ContentHeader from '@/components/ContentHeader.vue'
export default {
  components: { ContentHeader },

}
</script>

<style>

</style>