<template>
    <content-header title="Product" icon="fa fa-angle-double-right"/>
    <content>
      <loading v-if="isTrue"/>
      <div v-else class="container-fluid">
        <div class="card">
  
  
  
          <!-- button action -->
          <div class="card-header">
            <button v-if="create" class="btn btn-system btn-sm mb-2" @click="createModal" button="create">
              <img v-if="isCreate" src="../../assets/sniper.jpeg" alt="" width="12">
              <i v-else class="fa fa-plus-circle"></i> CREATE
            </button>
            <span v-if="create" class="mr-2"></span>
            <button @click="reloadData()" type="button" class="btn btn-warning btn-sm mb-2"><i class="fa fa-exchange-alt mr-1"></i>RELOAD DATA</button>      
  
            
            &nbsp;
            <select v-model="table.orderBy" @change="btnOrderBy()" class="selectPicker form-control-sm float-sm-right mb-2 p-0">
              <option value="DESC">ORDER BY : NEWEST</option>
              <option value="ASC">ORDER BY : OLDEST</option>
            </select>
           
          </div>
  
  
  
          <div class="card-body">
  
  
            <!-- companies data  -->
            <div class="row">
              <div class="col-sm-12 col-md-6">
                Categories :
                <label>  
                  <select id="companies" v-model="company_category_id" @change="btnOrderBy()" data-size="5" data-live-search="true"  class="selectPicker form-control form-control-sm p-0">
                    <option value="all">All</option>
                    <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
                  </select>
                </label>
              </div>
            </div>
  
            <!-- button search and option data  -->
            <div class="row">
              <div class="col-sm-12 col-md-6">
                Show
                <label> 
                  <select v-model="table.per_page" @change="btnPage('per_page')"  data-live-search="false" class="selectPicker form-control form-control-sm">
                    <option :value="10*index+10" v-for="(p,index) in 5" :key="index">{{10*(index+1)}}</option>
                  </select> 
                </label>
                entries
              </div>
              <div class="col-sm-12 col-md-6 col-12">
                  <div class="float-md-right">
                    Search:
                    <label>
                      <input v-model="search" type="search" class="form-control form-control-sm">
                    </label>
                  </div>
              </div>
            </div>
  
  
  
  
            <!-- table  -->
            <div  class="table-responsive-sm position-relative">
              <table class="table data-table table-sm table-bordered" style="width: 100%">
                <thead class="bg-system text-light">
                  <tr>
                    <th style="width:50px" class="d-none"></th>
                    <th style="width:50px">#</th>
                    <th>{{$t('name')}}</th>
                    <th style="width:80px">photo</th>
                    <th>original</th>
                    <th>unit price</th>
                    <th>category</th>
                    <th>on hand / item</th>
                    <th>stock / unit</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="table.data.length">
                  <template v-for="(data, index) in table.data" :key="data.id">
                    <tr :id="`tr${data.id}table`" :class="`${data.onhand == 0 ? 'dan' : data.onhand <= data.alert ? 'war' : ''}`">
                      <td class="d-none"></td>
                      <td>{{ table.from_index + index}}</td>
                      <td :data-col="`#`+ (table.from_index+index) + `. Name`">{{ data.name }}
                        <button :data-row = "`tr${data.id}table`" onclick="toggleRow(this)" class="clicker btn btn-xs bg-system text-white rounded-circle">
                          <i class="fas fa-plus-circle"></i>
                        </button>
                      </td>
                      <td data-col="Photo">
                        <img v-if="data.photo" :src="resource + data.photo" alt="Photo of Product" style="width: 30px; height:30px;" class="rounded">
                        <span v-else>&nbsp;</span>
                      </td>
                      <td data-col="Original">${{ data.original_price }}</td>
                      <td data-col="Unit Price">${{ data.unit_price }}</td>
                      <td data-col="Category">{{ data.company_category_name }}</td>
                      <td data-col="On Hand">{{ data.onhand }}</td>
                      <td data-col="Stock / Unit Type">{{ data.onhand == 0 || data.is_sale_stock == 0 ? '&nbsp;' : data.onhand % data.quality_per_stock != 0 ? parseFloat(data.onhand / data.quality_per_stock).toFixed(2) : data.onhand / data.quality_per_stock }} {{ data.onhand != 0 ? data.pos_unit_type_name : '' }}</td>
                      <td data-col="Action">
                        <div v-if="(list == 1 && edit == 1 && deleteAll == 1)" class="dropdown dropleft">
                          <button :button="`edit${data.id}`" class="btn btn-outline-system-xs btn-xs dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                            <img :id="`editImg${data.id}`" class="d-none" src="@/assets/sniper.jpeg" alt="" width="12">
                            <span :id="`editFa${data.id}`">Action</span>
                          </button>
                          <div class="dropdown-menu">
                            <router-link v-if="list" :to="{name : 'product-attribute', params : { id : `${data.id}` } }" 
                                class='dropdown-item text-sm txt-system'>
                                Attribute <i class="fa-brands fa-superpowers"></i>
                            </router-link>
                            <div v-if="list == 1 && edit == 1" class="dropdown-divider"></div>
                            <button v-if="edit" type='button' @click="btnEdit(data.id)"  :button="`edit${data.id}`"
                                class='dropdown-item text-sm text-success' title="edit">
                                <i class='fa fa-edit'></i> Edit
                            </button>
                            <div v-if="edit == 1 && deleteAll == 1" class="dropdown-divider"></div>
                            <button v-if="deleteAll" type="button" @click="alertRemove(data.id,'single')" 
                                class='dropdown-item text-sm text-danger' title='delele'>
                                <img :id="`deleteImg${data.id}`" class="d-none" src="@/assets/sniper.jpeg" alt="" width="12">
                                <span>
                                  <i :id="`deleteFa${data.id}`" class='fa fa-trash'></i> Delete  
                                </span>
                            </button>
                            <input :id="`hiddenDelete${data.id}`" type="hidden" @click="btnDelete(data.id)">
                          </div>
                        </div>
                      </td>
                    </tr> 
                  </template>   
                </tbody>
              </table>
            </div>
  
            <!-- Processing loader  -->
            <div v-if="pageLoad" class="card position-absolute " style="z-index:10; left: 45%; top: 50%;">
                <div class="card-body">
                <span class="txt-system">Processing...</span>
                </div>
            </div>
  
            <!-- no data -->
            <div v-if="!table.data.length" class="card">
                <div class="card-body text-center">
                  <span class="txt-system">No Data...</span>
                </div>
            </div>
  
            <!-- button table  -->
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info py-2">Showing {{ table.from_index }} to {{ table.to_index }} of {{ table.totalItem }} entries</div>
              </div>
              <div class="col-sm-12 col-md-7">
                <div class="dataTables_paginate paging_simple_numbers float-md-right">
                  <ul class="pagination">
                    <li  class="page-item mr-3">
                      <select @change="btnPage()" v-model="table.page" data-live-search="true" id="page" class="selectPicker border-page-color">
                       <template v-if="table.totalItem != 0">
                        <option v-for="(page,index) in selectOptions" :key="index" :value="index + 1">Page {{ index + 1 }} of {{ table.totalIndexPage }}</option>
                       </template>
                      </select>
                    </li>
                    <li @click="btnPage('prev',this.table.page-1)" class="border-top-left-radius border-bottom-left-radius" :class="table.page > 1 ? 'active' : ''">
                      <button  :disabled="table.page == 1 || table.totalIndexPage == 0"  type="button" class="link-page border-top-left-radius border-bottom-left-radius" :class="table.page == 1 || table.totalIndexPage == 0 ? 'txt-gray' : ''">&lt;&lt;</button>
                    </li>
                    <li @click="btnPage('next',this.table.page+1)" class="page-item">
                      <button :disabled="table.totalIndexPage == 1 || table.totalIndexPage == table.page" type="button" class="link-page-right" :class="table.totalIndexPage == 1 || table.totalIndexPage == table.page ? 'txt-gray' : ''">&gt;&gt;</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
  
  
  
  
  
          </div>
        </div>
      </div>
    </content>
    <content-header/>
    <CreateProductViewVue 
      v-if="create" 
      :vats="vats" 
      :categories="categories" 
      :unit_types="unit_types" 
      :code="code"
      :brands="brands" 
      @createModal="JustCreateModal($event)"
    />
    <EditProductViewVue 
      v-if="edit" 
      :vats="vats" 
      :categories="categories" 
      :unit_types="unit_types" 
      :edata="edata" 
      :brands="brands" 
      @editModal="JustUpdateModel($event)"
    />
  </template>
  
  <script>
  import axios from 'axios'
  import { resource } from '@/networks/domain'
  import { product, create , deleteProduct } from '@/networks/services/pos/product.service'
  import { checkPermission } from '@/networks/services/helper/helper.js'
  import Loading from '@/components/loading/Loading'
  import { config } from '@/networks/config'
  import { remove, bulk_delete , check} from '@/networks/services/helper/button.js'
  import CreateProductViewVue from './CreateProductView.vue'
  import EditProductViewVue from './EditProductView.vue'
  
  
  
  export default {
    components: { 
      Loading, CreateProductViewVue , EditProductViewVue
    },
    data(){
      return {
        table : {
          page: 1,
          totalIndexPage: 0,
          totalItem: 0,
          per_page: 10,
          from_index:0,
          to_index:0,
          data: [],
          search: null,
          orderBy: 'DESC'
        },
        edata : {
          code : '',
          name : '',
          unit_type_id : '',
          barcode : '',
          on_hand : '',
          alert : '',
          original_price : '',
          unit_price : '',
          cat_id: '',
          photo : '',
          is_stock : false,
          quality : '',
          vat_id : '',
          stock_unit_price_per_item : '',
          brand_id : '',
        },
        resource : resource,
        isCreate : false,
        is_stock : false,
        company_category_id: 'all',
        categories: [],
        code : 0,
        vats : [],
        unit_types : [],
        brands : [],
        search: null,
  
        permission_no: false,
        isTrue: true,
        pageLoad: false,
        create: false,
        deleteAll : false,
        edit: false,
        list: false,
      }
    },
    computed:{
      selectOptions(){
          let opts = this.table.totalIndexPage;
          return opts
      }
    },
    watch: {
      search(after, before){  
        this.table.search = this.search
        this.pageLoad = true;
        this.table.page = 1;
        this.getData(false);  
      },
    }
    ,
    methods : {
      reloadData(){
          this.table.orderBy = 'DESC';
          this.table.per_page = 10;
          this.table.page = 1;
          this.isTrue = true;
          this.getData(false);
      },
      btnOrderBy(){
        this.pageLoad = true;
        this.getData(false);
      },
      JustCreateModal(event){
        if(event == "success"){
          if(this.table.data.length){
              this.pageLoad = true;
              this.getData(false);
          } else {
              this.isTrue = true;
              this.getData(false);
          }
        } 
        if(event == "is_permission_no"){
          this.isTrue = true;
          this.getData(false);
        }
      },
      JustUpdateModel(event){
        if(event == "success"){
          this.pageLoad = true;
          this.getData(false);
        } 
        if(event == "is_permission_no"){
          this.isTrue = true;
          this.getData(false);
        }
      },
      btnEdit(id){
        
        $(`[button=edit${id}]`).prop("disabled", true);
        $(`#editImg${id}`).toggleClass('d-none');
        $(`#editFa${id}`).toggleClass('d-none');
        $('#editModal').on('hidden.bs.modal', function () {
          $(this).find('form').trigger('reset');
        })
        this.getData(id); 
      },
      alertRemove(id,type=null){
        alertify.confirm('Delete', 'Are you sure to do this stuff ?', function(){ 
          if(type == "single"){
            $(`#hiddenDelete${id}`).trigger("click");
          }
          if(type == "plural"){
            $(`#${id}`).trigger("click");
          }
        }
        , function(){ });
      },
      async btnDelete(id){
        axios 
        .get(`${deleteProduct(id)}`)
        .then((response)=>{
          if(response.data.status == 'success'){
            alertify.set('notifier','position', 'top-right');
            alertify.success(`${response.data.message}`);
            this.pageLoad = true;
            this.getData(false);
          }
          else if (response.data.status == 'error'){
            alertify.set('notifier','position', 'top-right');
            alertify.error(`${response.data.message}`);
          }
          else if(response.data.status === 401) {
            alertify.set('notifier','position', 'top-right');
            alertify.error(`${response.data.message}`);
            localStorage.removeItem('posUser');
            this.$router.push('/Unauthenticated');
          } else if(response.data.status == "is_permission_no"){
            this.isTrue = true;
            this.getData(false);
          }
          else {
            console.log('something wrong with api');
          }
        })
        .catch((err) => {
          console.log(err);
        })
      },
      async btnDeleteAll(){
        let obj = {
          tbl : "pos_products",
          per : "pos_product"
        };
        let event = await bulk_delete(obj);
        if(event == "success"){
          this.pageLoad = true;
          this.getData(false);
        } 
        if(event == "is_permission_no"){
          $('#checkBox').prop('checked',false);
          this.isTrue = true;
          this.getData(false);
        }
      },
      async createModal(is_create = true){
        config();
        if(is_create){
          this.isCreate = true;
          $(`[button=create]`).prop("disabled", true);
        }
        const createProduct = await axios
                                .get(`${create}`)
                                .catch(error => { console.log(error.response)});


        if(createProduct.data.status == "success"){
            //categories
            this.categories = createProduct.data.categories;

            //unit type
            this.unit_types = createProduct.data.unit_types;
            //code
            this.code = createProduct.data.code;
            //vat
            this.vats = createProduct.data.vats;
            //brand
            this.brands = createProduct.data.brands;

            if(is_create){
                this.isCreate = false;
                $(`[button=create]`).prop("disabled", false);
                $('#createModal').modal('show');
            }

            this.reloadSelectOption();

        } else if(createProduct.data.status == '401'){

            alertify.set('notifier','position', 'top-right');
            alertify.error("Unauthenticated");
            localStorage.removeItem('posUser');
            this.$router.push('/Unauthenticated');

        } else if(createProduct.data.status = 'is_permission_no'){

            this.isTrue = true;
            this.getData(false); 
            alertify.set('notifier','position', 'top-right');
            alertify.error('You are no permission to do this stuff!');

        } else{
            console.log(createProduct);
        }
      },
      btnChecky(obj){
        check(obj);
      },
      btnPage(e,value = null){
        this.pageLoad = true;
        if(e == 'prev'){
          this.table.page = value;
          this.getData(false);
        } 
        if(e == 'next'){
          this.table.page = value;
          this.getData(false);
        } else if(e == 'per_page'){
          this.table.page = 1;
          this.getData(false);
        } else {
          this.getData(false);
        }
      },    
      totalPage(){
        return this.table.totalIndexPage;
      },
      async reloadSelectOption(){
        await this.$nextTick();
        $('.selectPicker').selectpicker('refresh');
        $('.selectPicker').selectpicker();
      },
      async getData(id){
        if(localStorage.getItem('posUser')){
          config();
          axios
            .get(`${product(this.company_category_id,this.table.per_page,this.table.page,id,this.table.search,this.table.orderBy)}`)
            .then(response => {
              if(response.data.status === 'success'){
                this.reloadSelectOption();

                if(!id){
                  this.pageLoad = false;
                  
                  //table
                  this.table.totalIndexPage = response.data.data.last_page;
                  this.table.data = response.data.data.data; 
                  this.table.totalItem = response.data.data.total;
                  this.table.from_index = response.data.data.from ? response.data.data.from : 0;
                  this.table.to_index = response.data.data.to ? response.data.data.to : 0;

                  //permission
                  this.list = response.data.list;
                  this.create = response.data.create;
                  this.deleteAll = response.data.delete;
                  this.edit = response.data.edit;


                  //company categories
                  this.categories = response.data.categories;

                  //vat
                  this.vats = response.data.vats;

                  //brand
                  this.brands = response.data.brands;

                  //unit type
                  this.unit_types = response.data.unit_types;

                  this.isTrue = false;
                  $('#checkBox').prop('checked',false);

                } else {
                  this.edata = {};

                  $('#eid').val(response.data.data.id);
                  this.edata.code = response.data.data.code;
                  this.edata.name = response.data.data.name;
                  this.edata.unit_type_id = response.data.data.pos_unit_type_id ? response.data.data.pos_unit_type_id : '';
                  this.edata.barcode = response.data.data.barcode;
                  this.edata.alert = response.data.data.alert;
                  this.edata.original_price = response.data.data.original_price;
                  this.edata.unit_price = response.data.data.unit_price;
                  this.edata.cat_id = response.data.data.company_category_id;
                  this.edata.photo = response.data.data.photo ? resource + response.data.data.photo : '';
                  this.edata.is_stock = response.data.data.is_sale_stock == 1 ? true : false;
                  this.edata.quality = response.data.data.quality_per_stock;
                  this.edata.vat_id = response.data.data.vat_id == 0 ? '' : response.data.data.vat_id;
                  this.edata.stock_unit_price_per_item = response.data.data.stock_unit_price_per_item;
                  this.edata.brand_id = response.data.data.brand_id != 0 ? response.data.data.brand_id : '';




                  if(this.edata.is_stock == 1){
                    $('#ecustomSwitch').prop('checked', true);
                  }


                  $(`#editImg${id}`).toggleClass('d-none');
                  $(`#editFa${id}`).toggleClass('d-none');
                  $(`[button=edit${id}]`).prop("disabled", false);
                  $('#editModal').modal();

                }
                  
              } else if(response.data.status === 401) {
                alertify.set('notifier','position', 'top-right');
                alertify.error(`${response.data.message}`);
                localStorage.removeItem('posUser');
                this.$router.push('/Unauthenticated');
              } else if(response.data.status == "is_permission_no"){
                this.permision_no = true;
              }
              else {
                console.log('something wrong with api');
              }
            })
            .then(()=>{
              this.reloadSelectOption();
            })
            .catch(error => {
                console.log(error)
              }
            );
        } else {
          this.$router.push('/Unauthenticated')
        }
      },
    },
    created(){
        this.getData(false);
    },
    mounted(){
      
    }
  }
  </script>
  
  <style>
  
  </style>