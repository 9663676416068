import axios from "axios";
import { domain } from "../../domain";

export {
    user,
    getUser,
    userDetail,
    getSomethingForCreateUser,
    getRole,
    createUser,
    deleteUser,
    updateUser,
    userInfo
}
function user(value){
    if(localStorage.getItem('posUser')){
        const DATA    = JSON.parse(localStorage.getItem('posUser'));
        const TOKEN   = `Bearer ${DATA.token}`;
        const USER_ID = DATA.data.id;
        const COMPANY_ID = DATA.data.company_id;
        const ROLE_ID = DATA.data.role_id;
        const SYSTEM_USER_TYPE_ID = DATA.data.system_user_type_id;
        
        let result = '';
        switch(value){
            case 'token':
                result = TOKEN;
                break;
            case 'user_id':
                result = USER_ID;
                break;
            case 'company_id':
                result = COMPANY_ID;
                break;
            case 'role_id':
                result = ROLE_ID;
                break;
            case 'system_user_type_id':
                result = SYSTEM_USER_TYPE_ID;
                break;
            default:
                result = null;
                break;
        }
        return result;
    }
}

function getUser(per_page=null,page=null,id=null,name=null,orderBy=null,company_id=null){
    if(id){
        return `${domain}/get-user?id=${id}`;
    } else if(name){
        return `${domain}/get-user?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}&company_id=${company_id}`
    } else {
        return `${domain}/get-user?per_page=${per_page}&page=${page}&orderBy=${orderBy}&company_id=${company_id}`;
    }
}



function userDetail(id){
    return `${domain}/get-user-detail?id=${id}`;
}
function getRole(id){
    return `${domain}/getRole?company_id=${id}`;
}

function deleteUser(id){
    return `${domain}/delete-user?id=${id}`;
}


const getSomethingForCreateUser = `${domain}/get-something-for-create-user`;

const createUser = `${domain}/create-user`;

const updateUser = `${domain}/update-user`;

const userInfo = `${domain}/user-info`;






