import { domain } from "../../domain";

export {
    customerType
}

function customerType(per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/get-customerType?id=${id}`;
    } else if(name){
        return `${domain}/pos/get-customerType?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}`
    } else {
        return `${domain}/pos/get-customerType?per_page=${per_page}&page=${page}&orderBy=${orderBy}`;
    }
}

// function attribute(id){
//     return `${domain}/pos/get-attribute?id=${id}`;
// }






