<template>
    <div class="row px-3 m-10 row-height">
        <div class="col-12 left-col">
            <div class="card" style="margin-bottom: 0rem">
                <div class="card-body p-1">
                    <button type="button" class="btn btn-outline-dark m-2" :class="category_id == 'all' ? 'bg-dark' : ''" 
                        @click="clicker('all')"
                    >All 
                    </button>
                    <button type="button" 
                        class="btn btn-outline-dark m-2"
                        :class="data.id == category_id ? 'bg-dark' : ''"
                        v-for="data in categories"
                        :key="data.id"
                        @click="clicker(data.id)"
                    >   {{ data.name }}  
                    </button> 
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { resource } from '@/networks/domain';
    export default {
        props : {
            categories : {
                type : [Object],
                default : null
            },
            category_id : {
                type : [Object, String , Number],
                default : null
            }
        },
        methods : {
            clicker(value){
                this.$emit('clicker',value);
            }
        },
        data(){
            return {
                resource : resource
            }
        }
    }

</script>
<style scoped>
.row-height{
  height: 120px;
}
.left-col{
  height: 120px;
  overflow: scroll;
}
</style>