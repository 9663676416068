import { domain } from "../../domain";

export {
    customer, customerType
}

function customer(customer_type_id=null,per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/get-customer?id=${id}`;
    } else if(name){
        return `${domain}/pos/get-customer?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}&customer_type_id=${customer_type_id}`
    } else {
        return `${domain}/pos/get-customer?per_page=${per_page}&page=${page}&orderBy=${orderBy}&customer_type_id=${customer_type_id}`;
    }
}

const customerType = `${domain}/pos/customerType`;


