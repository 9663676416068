export {
    path
}

function path(id){
    return [
        // main system 
        { link : '/role' , system_id : 0 , default : 0},
        { link : '/user' , system_id : 0 , default : 0},
        { link : '/about-me' , system_id : 0 , default : 0},
        { link : '/company-info' , system_id : 0 , default : 0},
        { link : '/edit-company-info' , system_id : 0, default : 0},
        { link : '/company-list-detail/'+id , system_id : 0, default : 0},


        { link : '/permission' , system_id : 0 },
        { link : '/system-service' , system_id : 0 },
        { link : '/system-category' , system_id : 0 },
        { link : '/company_list' , system_id : 0 },
        { link : '/company-role', system_id : 0 },
        { link : '/attribute' , system_id : 0 },
        { link : '/main-category' , system_id : 0 },
        { link : '/product-brand' , system_id : 0 },
    
        // pos 
        { link : '/customer-type' , system_id : 1 },
        { link : '/customer' , system_id : 1 },
        { link : '/supplier' , system_id : 1 },
        { link : '/stock-in' , system_id : 1 },
        { link : '/stock-out' , system_id : 1 },
        { link : '/low-stock' , system_id : 1 },
        { link : '/out-of-stock' , system_id : 1 },
        { link : '/product' , system_id : 1 },
        { link : '/product/attribute' , system_id : 1 },
        { link : '/product-category' , system_id : 1 },
        { link : '/product-attribute' , system_id : 1 },
        { link : '/unit-type' , system_id : 1 },
        { link : '/pos-exchange-rate' , system_id : 1 },
        { link : '/payment-method' , system_id : 1 },
        { link : '/expense-category' , system_id : 1 },
        { link : '/expense' , system_id : 1 },
        { link : '/product-discount' , system_id : 1 },
        { link : '/pos-vat' , system_id : 1 },
        { link : '/pos/income-expense-report' , system_id : 1 },
        { link : '/pos/income-report' , system_id : 1 },
        { link : '/pos/expense-report' , system_id : 1 },
        { link : '/pos-invoice' , system_id : 1 },
        { link : '/pos-product-barcode' , system_id : 1 },


    
    ];
}