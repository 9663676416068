import { domain } from "../../domain";

export {
    exchangeRate,
    insertExchangeRate,
}


function exchangeRate(per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/get-exchange-rate?id=${id}`;
    } else if(name){
        return `${domain}/pos/get-exchange-rate?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}`
    } else {
        return `${domain}/pos/get-exchange-rate?per_page=${per_page}&page=${page}&orderBy=${orderBy}`;
    }
}


const insertExchangeRate =`${domain}/pos/insert-exchange-rate`;
