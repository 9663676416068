<template>
  <aside class="main-sidebar sidebar-dark-primary">
    <router-link to="/" class="brand-link" style="min-height:60.27px">
      <img
        v-if="companyInfos.logo"
        :src="img(companyInfos.logo)"
        class="brand-image img-circle elevation-3"
        style="opacity: 0.8"
      />
      <img
        v-else
        src="/adminlte/dist/img/boxed-bg.jpg"
        class="brand-image img-circle elevation-3"
        style="opacity: 0.8"
      />
      <span class="brand-text font-weight-light" style="max-width:100%">
        {{ companyInfos.en_name }}
      </span>
    </router-link>
    <div class="sidebar">
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img v-if="userInfos.photo" :src="img(userInfos.photo)" class="img-circle elevation-2" alt="User Image">
          <img v-else src="../../../public/adminlte/dist/img/avatar.png" class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <div class="d-block text-white">{{ userInfos.nick_name }}</div>
        </div>
      </div>
      <SystemSidebarVue 
        v-if="service == 0 && checkNavBar == true"
        :navBar="navBar"
        :checkNavBar="checkNavBar"
      />
      <PosSidebarVue 
        v-if="service == 1 && checkNavBar == true"
        :navBar="navBar"
        :checkNavBar="checkNavBar"
      />
    </div>
  </aside>
</template>

<script>
import { user } from '@/networks/services/auth/user.service.js'
import SystemSidebarVue from "../services/SystemSidebar.vue";
import PosSidebarVue from "../services/PosSidebar.vue";
import axios from "axios";
import { userInfo } from "@/networks/services/auth/user.service";
import { config } from "@/networks/config";
import { resource } from "@/networks/domain";


export default {
  components: {
    SystemSidebarVue, PosSidebarVue
  },
  props : {
    service: {
        type: [Number,String],
        default: 0,
    },
    navBar: {
        type: [Array, Object],
        default: [],
    },
    checkNavBar: {
        type: [Boolean],
        default: false,
    },
    companyInfos: {
        type: [Object],
        default: null,
    },
    userInfos: {
        type: [Object],
        default: null,
    },
  },
  data() {
    return {
      companyInfo : '',
      userInfo : '',
      ser : '',
    }
  },
  methods : {
    img(obj){
      return resource+obj;
    },
    getData(){
      if(localStorage.getItem('posUser')){
        config();
          axios
              .get(`${userInfo}`)
              .then(response => {
                if(response.data.status === 'success'){

                  this.companyInfo = response.data.companyInfo;
                  this.userInfo = response.data.userInfo;
  
                } 
                else if(RES.data.status === 401) {
                  alertify.set('notifier','position', 'top-right');
                  alertify.success(`${RES.data.message}`);
                  localStorage.removeItem('posUser');
                  this.$router.push('/Unauthenticated');
                } 
                else {
                  console.log('something wrong with api');
                }
              })
              .catch(error => {
                        console.log(error)
                    }
              );
      } else {
        this.$router.push('/Unauthenticated')
      }
    },
  },
  mounted(){
      // this.getData();
      $(document).ready(function() {
        $('.chosen2').select2();
        $('.select2-search__field').prop('disabled', true);
      });
  },
};
</script>

<style>
</style>