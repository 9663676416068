import { domain } from '@/networks/domain'
import { HEADER } from '@/networks/services/helper/ajaxHeader'
import axios from 'axios'
import { config } from '@/networks/config'
import router from '@/router'

export { check, bulk_delete, remove, frm_submit, frm_update, reset , deleteImg}

//load config
config();


// checkbox on datatable function


function check(obj)
{
    let check = $(obj).prop('checked');
    let tds = $('table tbody tr td:first-child input');
    if(check)
    {
        for(let i=0; i<tds.length; i++)
        {
            $(tds[i]).prop('checked', true);
        }
    }
    else
    {
        for(let i=0; i<tds.length; i++)
        {
            $(tds[i]).prop('checked', false);
        }
    }
}
// function for bulk delete
// $("#btnDelete").on('click', function(event)

async function bulk_delete(obj)
{
        let tbl = obj.tbl;
        let per = obj.per;
        let tds = $('table tbody tr td:first-child input:checked');
        let arr = [];
        if(tds.length <= 0){
            alertify.error('You are not check it') 
        } else {
            for(let i=0; i<tds.length; i++)
            {
                let id = $(tds[i]).val();
                arr.push(id);
            }
            let data = {
                tbl: tbl,
                per: per,
                ids: arr,
            };
            var result = await axios
                .post(domain+'/bulk-deleteAll',data)
                .catch(error => {
                    console.log(error.response)
                });
            if(result.data.status == 'success'){
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`); 
                return "success";
            } else if(result.data.status == 'error'){
                alertify.set('notifier','position', 'top-right');
                alertify.error(`${result.data.message}`); 
                return "error";
            } else if(result.data.status == '401'){
                alertify.set('notifier','position', 'top-right');
                alertify.error("Unauthenticated");
                localStorage.removeItem('posUser');
                router.push('/Unauthenticated');
            } else if(result.data.status = 'is_permission_no'){
                alertify.set('notifier','position', 'top-right');
                alertify.error('You are no permission to do this stuff!');
                return 'is_permission_no';
            } else{
                console.log(result)
            }
        }
}
// function for delete button
async function remove(id, obj)
{
    var tbl = obj.tbl;
    var per = obj.per;
    
    var result = await axios
                .get(domain+'/bulk-remove/'+ id + '?tbl=' + tbl + '&per=' + per)
                .catch(error => {
                    console.log(error.response)
                });
        if(result.data.status == 'success'){
            alertify.set('notifier','position', 'top-right');
            alertify.success(`${result.data.message}`); 
            return "success";
        } else if(result.data.status == 'error'){
            alertify.set('notifier','position', 'top-right');
            alertify.error(`${result.data.message}`); 
            return "error";
        } else if(result.data.status == '401'){
            alertify.set('notifier','position', 'top-right');
            alertify.error("Unauthenticated");
            localStorage.removeItem('posUser');
            router.push('/Unauthenticated');
        } else if(result.data.status = 'is_permission_no'){
            alertify.set('notifier','position', 'top-right');
            alertify.error('You are no permission to do this stuff!');
            return 'is_permission_no';
        } else{
            console.log(result)
        }
}
// function to save data from a form
async function frm_submit(data)
{
    // evt.preventDefault();
    // let data = $(evt.target).serialize();
    var result = await axios
        .post(domain+'/bulk-insert',data)
        .catch(error => {
            console.log(error.response)
        });
    if(result.data.status == 'success'){
        $('#createModal').modal('hide');
        $("#create_form")[0].reset();
        $("#create_status").val("success");
        alertify.set('notifier','position', 'top-right');
        alertify.success('Insert Succesfully');
    } else if(result.data.status == 'error') {
        $('#createModal').modal('hide');
        $("#create_form")[0].reset();
        $("#create_status").val("error");
        alertify.set('notifier','position', 'top-right');
        alertify.error(`${result.data.message}`);
    } else if(result.data.status == '401'){
        alertify.set('notifier','position', 'top-right');
        alertify.error("Unauthenticated");
        localStorage.removeItem('posUser');
        router.push('/Unauthenticated');
    } else if(result.data.status == 'is_permission_no'){
        $('#createModal').modal('hide');
        $("#create_form")[0].reset();
        $("#create_status").val("is_permission_no");
        alertify.set('notifier','position', 'top-right');
        alertify.error('You are no permission to do this stuff!');
        return 'is_permission_no';
    } else{
        console.log(result)
    }
    return result.data.data;
}
// function to update data from a form
async function frm_update(data)
{
    // let data = $(evt.target).serialize();
    
    var result = await axios
                .post(domain+'/bulk-update',data)
                .catch(error => {
                    console.log(error.response)
                });
  
    if(result.data.status == 'success'){
        $('#editModal').modal('hide');
        $("#edit_form")[0].reset();
        $("#edit_status").val("success");
        alertify.set('notifier','position', 'top-right');
        alertify.success('Update Succesfully');
    } else if(result.data.status == 'error') {
        $('#editModal').modal('hide');
        $("#edit_form")[0].reset();
        $("#edit_status").val("error");
        alertify.set('notifier','position', 'top-right');
        alertify.error("Ops! Nothing was changed!");
    } else if(result.data.status == '401'){
        alertify.set('notifier','position', 'top-right');
        alertify.error("Unauthenticated");
        localStorage.removeItem('posUser');
        router.push('/Unauthenticated');
    } else if(result.data.status == 'is_permission_no'){
        $('#editModal').modal('hide');
        $("#edit_form")[0].reset();
        $("#edit_status").val("is_permission_no");
        alertify.set('notifier','position', 'top-right');
        alertify.error('You are no permission to do this stuff!');
        return 'is_permission_no';
    } else{
        console.log(result)
    }
}
async function deleteImg(data){
    var result = await axios
                .post(domain+'/bulk-deleteImg',data)
                .catch(error => {
                    console.log(error.response)
                });
 
    if(result.data.status == 'success'){
        alertify.set('notifier','position', 'top-right');
        alertify.success('Update Succesfully');
        return 'success';
    } else if(result.data.status == 'error') {
        alertify.set('notifier','position', 'top-right');
        alertify.error("Ops! Nothing was changed!");
        return 'error';
    } else if(result.data.status == '401'){
        alertify.set('notifier','position', 'top-right');
        alertify.error("Unauthenticated");
        localStorage.removeItem('posUser');
        router.push('/Unauthenticated');
    } else if(result.data.status == 'is_permission_no'){
        alertify.set('notifier','position', 'top-right');
        alertify.error('You are no permission to do this stuff!');
        return 'is_permission_no';
    } else{
        console.log(result)
    }
}
function reset(id)
{
    $(id).trigger('reset');
}

