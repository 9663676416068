import { domain } from "../../domain";

export {
    rolePermission,
    changeRolePermission
}

function rolePermission(checkPermission=null,id=null,per_page=null,page=null,name=null,orderBy=null){
    if(name){
        return `${domain}/pos/get-role-permission?id=${id}&per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}&checkPermission=${checkPermission}`
    } else {
        return `${domain}/pos/get-role-permission?id=${id}&per_page=${per_page}&page=${page}&orderBy=${orderBy}&checkPermission=${checkPermission}`;
    }
}

function changeRolePermission(checkPermission){
    return `${domain}/pos/update-role-permission?checkPermission=${checkPermission}`;
}