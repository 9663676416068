<template>
    <div class="col-lg-3 col-6">
        <!-- small box -->
        <router-link to="/pos-invoice" class="small-box bg-warning" style="cursor:pointer;">
        <div class="inner text-white">
            <h3>Invoice</h3>

            <p>{{ $t('POS Invoice') }} &nbsp;<i class="fas fa-sticky-note"></i></p>
        </div>
        <div class="icon">
            <i class="ion ion-pie-graph"></i>
        </div>
        <div class="small-box-footer text-white">More info &nbsp;<i class="fas fa-arrow-circle-right"></i></div>
        </router-link>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>