<template>
    <div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
        <form action="ohyes" method="POST">
            <input id="create_status" type="hidden" value="error">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Payment Detail</strong>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true" >&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card">
                        <div class="card-body position-relative">
                            <AddPaymentView 
                                @addDeptPayment="addDeptPayment($event)" 
                                :id="edata.id" 
                                :debt="Number(edata.debt)" 
                                :paymentMethod="paymentMethod" 
                                :exchangeRate="exchangeRate"
                            />
                            <EditPaymentView 
                                :totalUsd="Number(edata.total_usd)" 
                                :paymentMethod="paymentMethod"
                                :exchangeRate="exchangeRate"
                                :invoiceId="edata.id"
                                :paymentId="editPaymentId"
                                :eMethodId="eMethodId"
                                @updatePayment="updatePayment($event)"
                            />
                            <div class="row my-3 mx-0">
                                <div v-if="ecompanies" class="col-lg-8 col-sm-12 d-sm-block d-none d-flex justify-content-center align-content-center">
                                    <div class="row" style="box-sizing: border-box;">
                                        <div class="col-lg-2 col-sm-12 mr-lg-3 mb-3 display-phone">
                                            <img v-if="ecompanies.logo" :src="resource + ecompanies.logo" width="100" class="rounded-circle">
                                        </div>
                                        <div class="col-lg-8 col-sm-12 display-phone">
                                            <span class="kh_muol p-0 d-block"> {{ ecompanies.kh_name }}</span>
                                            <span class="kh_content p-0 d-block"> {{ ecompanies.en_name }}</span>
                                            <span class="kh_content p-0 d-block">Address ៖ {{ ecompanies.address }}</span>
                                            <span class="kh_content p-0 d-block">Telephone (1) ៖ {{ ecompanies.phone_line1 }}</span>
                                            <span v-if="ecompanies.phone_line2"  class="kh_content p-0 d-block">Telephone (2) ៖ {{ ecompanies.phone_line2 }}</span>
                                            <span v-if="ecompanies.phone_line3"  class="kh_content p-0 d-block">Telephone (3) ៖ {{ ecompanies.phone_line3 }}</span>
                                            <span v-if="ecompanies.phone_line4"  class="kh_content p-0 d-block">Telephone (4) ៖ {{ ecompanies.phone_line4 }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="edata" class="col-lg-4 col-sm-12 p-0">
                                    <table width="100%" class="table-detail">
                                        <tbody>
                                            <tr class="border-bot">
                                                <td class="text-left">Invoice Code</td>
                                                <td class="text-right">:</td>
                                                <td class="text-right">{{ edata.code }}</td>
                                            </tr>
                                            <tr class="border-bot">
                                                <td class="text-left">Total Price (USD)</td>
                                                <td class="text-right">:</td>
                                                <td class="text-right">$ {{ returnMoneyUsd }}</td>
                                            </tr>
                                            <tr class="border-bot">
                                                <td class="text-left">Total Price (KHR)</td>
                                                <td class="text-right">:</td>
                                                <td class="text-right">{{ returnMoneyKhr}} ៛</td>
                                            </tr>
                                            <tr class="border-bot">
                                                <td class="text-left">Paid (USD)</td>
                                                <td class="text-right">:</td>
                                                <td class="text-right">$ {{ returnPaid }}</td>
                                            </tr>
                                            <tr class="border-bot">
                                                <td class="text-left">Debt</td>
                                                <td class="text-right">:</td>
                                                <td class="text-right">$ {{ returnDept }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- table  -->
                            <button v-if="edata.paid_status != 1" @click="addPayment()" type="button" class="btn btn-sm btn-outline-dark my-2">
                                <i class="fa fa-plus-circle"></i> Add Payment
                            </button>
                            <div v-if="edetails.length > 0" class="table-responsive position-relative">
                                <table class="table data-table table-sm table-bordered position-relative" style="width: 100%">
                                    <thead class="bg-system text-light">
                                        <tr>
                                            <th class="d-none"></th>
                                            <th class="display-phone-none" style="width:80px">#</th>
                                            <th>Date</th>
                                            <th style="width:80px">photo</th>
                                            <th>Payment Method</th>
                                            <th>Paid (USD)</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(data, index) in edetails" :key="data.id" :id="`tr${data.id}table`">
                                            <td class="d-none"></td>
                                            <td class="display-phone-none">{{ index + 1 }}</td>
                                            <td :data-col="`Date`">{{ new Date(data.date).toLocaleDateString("en-AU", { day : 'numeric', 'month' : 'numeric' , 'year' : '2-digit', 'hour' : 'numeric', "minute" : "numeric" }) }} </td>
                                            <td data-col="Photo">
                                                <img v-if="data.photo" :src="resource + data.photo" alt="Photo of Product" style="width: 30px; height:30px;" class="rounded">
                                                <span v-else>&nbsp;</span>
                                            </td>
                                            <td data-col="Payment Method">{{ data.payment_method_name }}</td>
                                            <td data-col="Paid (USD)">$ {{ formatToCurrency(data.amount_usd,2) }}</td>
                                            <td data-col="Action">
                                                <button v-if="edetails.length - 1 == index " class="btn btn-sm btn-success mr-2" type="button" @click="editPayment(data.id,data.amount_usd,data.payment_method_id,data.photo)">Edit <i class="fa fa-edit"></i></button>
                                                <button class="btn btn-sm btn-danger" type="button" @click="deletePayemnt(data.id)">Delete <i class="fa fa-trash"></i></button>
                                            </td>
                                        </tr>    
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div style="hieght:200px">&nbsp;</div>
                                <div style="hieght:200px">&nbsp;</div>
                                <div style="hieght:200px">&nbsp;</div>
                                <div style="hieght:200px">&nbsp;</div>
                                <div style="hieght:200px">&nbsp;</div>
                                <div style="hieght:200px">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        </div>
    </div>  
</template> 
<script>
import { resource } from '@/networks/domain'
import AddPaymentView from '@/views/Pos/invoices/AddPaymentView'
import EditPaymentView from '@/views/Pos/invoices/EditPaymentView'
import axios from 'axios'
import { deletePayment } from '@/networks/services/pos/invoicePos.service'

export default {
    props : {
        edata: {
            type: [Array, Object],
            default: [],
        },
        ecompanies: {
            type : [Array , Object],
            default: {}
        },
        edetails : {
            type : [Array, Object],
            default : null
        },
        paymentMethod: {
            type : [Array, Object],
            default : []
        },
        exchangeRate:{
            type : [Object, Array],
            default : {}
        }
    },
    components:{
        EditPaymentView,
        AddPaymentView
    },
    data(){
        return {
            resource : resource,
            editAmount : 0,
            isModelAddShow : false,
            editPaymentId : 0,
            eMethodId : 0,
            ephoto : '',
        }
    },
    computed : {
        returnMoneyKhr(){
            return this.formatToCurrency(this.edata.total_khr,0);
        },
        returnMoneyUsd(){
            return this.formatToCurrency(this.edata.total_usd,2);
        },
        returnDept(){
            return this.formatToCurrency(this.edata.debt,2);
        },
        returnPaid(){
            return this.formatToCurrency(this.edata.paid,2);
        }
    },
    methods: {
        addDeptPayment(status){
            this.$emit('createModal',status);
        },
        updatePayment(status){
            this.$emit("updateModal",status);
        },
        formatToCurrency(amount, fixed = 2) {
            if (fixed > 0) {
                return Number(amount)
                            .toFixed(fixed)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,");
            } else {
                let luy = Number(amount)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,");
                let new_luy = luy.split(".");

                return new_luy[0];
            }
        },
        addPayment(){
            $('#addPaymentModal').modal('show',{backdrop: 'static', keyboard: false});
            $('#addAmountPayment').val(this.edata.debt);
        },
        editPayment(id,amount,methodId,photo){
            this.editAmount = amount;
            this.editPaymentId = id;
            this.eMethodId = methodId;
            this.ephoto = photo;

            $("#ecategory_id").val(`${methodId}`);
            $('#amountEditPayment').val(amount)
            $('#editPaymentModal').modal('show',{backdrop: 'static', keyboard: false});
        },
        deletePayemnt(id){
            const self = this;
            alertify.confirm('Delete Payment', 'Are you sure to do this stuff ?', function(){ 
                axios.post(deletePayment,{
                    id : id
                }).then((res)=>{
                    if(res.data.status == 'success'){
                        alertify.set('notifier','position', 'top-right');
                        alertify.success(`${res.data.message}`);
                        self.$emit('createModal',"success");
                    }else if(res.data.status == "401"){
                        alertify.set('notifier','position', 'top-right');
                        alertify.success(`${res.data.message}`);
                        localStorage.removeItem('posUser');
                        self.$router.push('/Unauthenticated');
                    } 
                    else if(res.data.status == "is_permission_no"){
                        alertify.set('notifier','position', 'top-right');
                        alertify.error(`${res.data.message}`);
                        self.$emit('createModal',"is_permission_no");
                    } 
                    else if(res.data.status == "error"){
                        alertify.set('notifier','position', 'top-right');
                        alertify.error(`${res.data.message}`); 
                    } else {
                        console.log(res)
                    } 
                }).catch((err)=>{
                    console.log(err);
                })
            }
            , function(){ });
        }
    },
}
</script>
<style scoped>

    .modal-dialog, .modal-body { 
        background-color: transparent !important;
    }
    .modal-content{
        background-color: #ffffff7d !important;
        backdrop-filter: blur(33px) !important;
        background-blend-mode: overlay !important;
    }
    .modal-header {
        border-bottom: 0px;
    }
    .table-detail{
        table-layout: auto;
        box-sizing: border-box;
    }
    .table-detail tr td {
        padding: 5px 0px 5px 0px !important;
    }
   
    @media screen and (max-width: 600px) {
        table.data-table td {
            display: block;
        }
        .kh_muol {
            font-size: 15px;
        }
        .display-phone-none{
            display: none !important;
        }
    }

</style>
