import { domain } from "../../domain";

export {
    addSalePos,
    store
}

function addSalePos(company_category_id=null,per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/pos-product-list?id=${id}`;
    } else if(name){
        return `${domain}/pos/pos-product-list?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}&company_category_id=${company_category_id}`
    } else {
        return `${domain}/pos/pos-product-list?per_page=${per_page}&page=${page}&orderBy=${orderBy}&company_category_id=${company_category_id}`;
    }
}

const store = `${domain}/pos/sale_pos/store`;



// function deleteRole(id){
//     return `${domain}/pos/get-permission?id=${id}`;
// }



