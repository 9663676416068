import { domain } from "../../domain";

export {
    vatList
}

function vatList(per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/vat-list?id=${id}`;
    } else if(name){
        return `${domain}/pos/vat-list?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}`
    } else {
        return `${domain}/pos/vat-list?per_page=${per_page}&page=${page}&orderBy=${orderBy}`;
    }
}