<template>
<div class="card shadow-system-sm mb-2 p-0" v-for="(data, index) in datas" :key="index">
    <div class="card-body p-2">
        <div class="row mx-0 p-0">
            <div class="col-3 p-0 m-auto">
                <div class="row p-0 m-0">
                    <div class="col-3 p-0 mr-2 my-auto">
                        <div class="row p-0 m-0">
                            <div class="col-12 p-0 m-0 text-center">
                                <span @click="handleIncrement(data,index)" class="badge bg-system text-white rounded-circle" style="cursor: pointer; "><i class="fa-solid fa-plus"></i></span>
                            </div>
                            <div class="col-12 p-0 m-0 text-center">{{ data.quantity }}</div>
                            <div class="col-12 p-0 m-0 text-center">
                                <span @click="handleDecrement(data,index)" class="badge bg-danger rounded-circle" style="cursor: pointer; "><i class="fa-solid fa-minus"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="col p-0 m-0 m-auto">
                        <img v-if="data.photo == ''" src="../../assets/noProductImage.jpeg" style="width: 100%; height: auto;" class="rounded">
                        <img v-else :src="resource + data.photo" style="width: 100%; height: 65px;" class="rounded">
                        <small class="text-danger" style="font-size:12px;" @dblclick="dbClickChangeDiscount(data.discount_price, index, true)">
                            <i>Disc : $ {{ formatToCurrency(data.discount_price,2) }}</i>
                        </small>
                    </div>
                </div>
            </div>
            <div class="col p-0 px-2 position-relative">
                <span @click="handleCancel(data,index)" class="badge bg-danger rounded-circle float-right position-absolute" style="top: 0px; right: 0px; cursor: pointer; ">&#10008;</span>
                <p class="p-0 mb-0 text-lg font">{{ data.name }}</p>
                <template v-if="data.attributes.length > 0">
                    <p class="p-0 m-0 text-xs">
                        <template v-for="(item, indexData) in data.attributes" :key="item.id">
                            <template v-if="item.attribute_id != 1">
                                {{ item.name }}: {{ item.value }}       
                            </template>
                            <template v-else>
                                {{ item.name }} <span class="mr-1 btn btn-sm rounded-circle" :style="`background-color: ${item.value}; height:20px; width:20px;`">&nbsp;</span> 
                            </template>
                            <sub class="ml-1">+${{formatToCurrency(item.attribute_increment,2)}} </sub> <span v-if="indexData+1 != data.attributes.length">| </span>
                        </template>
                    </p>
                </template>
                <div class="row p-0 pt-2 m-0">
                    <div :class="`${data.pro_discount ? 'col-6' : 'col-6'} p-0 m-0`">
                        <p class="p-0 m-0 text-sm">Price : $ {{ unitPrice(data.unit_price,data.attributes) }}</p>
                    </div>
                    <div :class="`${data.pro_discount ? 'col-6' : 'col-6'} p-0 m-0`">
                        <p class="p-0 m-0 text-sm text-right">Sub total : $ {{ formatToCurrency(Number(data.total_price) + Number(data.total_discount),2) }}</p>
                    </div>
                    <div class="col-6 p-0 m-0">
                        <p class="p-0 m-0 text-sm">
                            <span @dblclick="dbClickChangeDiscount(data.total_discount, index, false)">
                                Total Disc : $ {{ formatToCurrency(data.total_discount,2) }}
                            </span>
                        </p>
                    </div>
                    <div :class="`${data.pro_discount ? 'col-6' : 'col-6'} p-0 m-0`">
                        <p class="p-0 m-0 text-sm txt-system text-right">Total : $ {{ formatToCurrency(data.total_price,2) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { resource } from '@/networks/domain';
import axios from 'axios';
export default {
    emits: ["incrementProduct","decrementProduct","cancelProduct","dbClickChangeDiscount"],
    props: {
        datas: {
            type: [Object, Array],
            default: []
        }
    },
    data() {
        return {
            resource : resource
        }
    },
    methods : {
        dbClickChangeDiscount(discount, index, condiction){
            const self = this;
            let alert = '';
            if(condiction){
                alert = 'Please Input Your new discount <span class="text-danger">per item</span> ($)'
            } else {
                alert = 'Please Input Your new discount <span class="text-danger">( Total Discount )</span> ($)'
            }
            alertify.prompt( 'Change Discount', alert , Number(discount).toFixed(2)
            , function(evt, value) { 
                if(value == discount){
                    return 
                }
                else if(!parseFloat(value)){
                    alertify.set('notifier','position', 'top-right');
                    alertify.error('Please Input Number Only.');
                    return
                } else {
                    self.$emit('dbClickChangeDiscount',{discount : value, index : index, condiction : condiction});
                    if(self.datas[index].total_price < 0){
                        self.$emit('dbClickChangeDiscount',{discount : discount, index : index, condiction : condiction});
                        alertify.set('notifier','position', 'top-right');
                        alertify.error('The discount amount is larger than sub total amount');
                        return
                    }
                }
            }
            , function() { });
        },
        unitPrice(unit_price,attributes){
            if(attributes.length > 0){
                let incrementPrice = 0;
                attributes.forEach((data, index) => {
                    let value = data.attribute_increment;
                    incrementPrice = Number(incrementPrice) + Number(value);
                });

                //if add incremment
                // let finalPrice = Number(unit_price) + Number(incrementPrice);

                //not add increment
                let finalPrice = Number(unit_price);
                return this.formatToCurrency(finalPrice,2);
            }
            return this.formatToCurrency(unit_price,2);
        },
        actionHandle(data,index){
            if(data.attributes.length > 0){
                let attributes = data.attributes;
                let incrementPrice = 0;
                attributes.forEach((item, index) => {
                    incrementPrice = Number(incrementPrice) + Number(item.attribute_increment);
                })
                return {
                    id : data.id,
                    index : index,
                    total_discount : Number(data.discount) != 0 ? Number(data.discount_price).toFixed(2) : 0,
                    total_price : Number(data.discount) != 0 ? (Number(data.unit_price) - Number(data.discount_price) + Number(incrementPrice)).toFixed(2) : (Number(data.unit_price) + Number(incrementPrice)).toFixed(2),
                    quantity : 1,
                    total_quantity : data.quantity
                }
            } else {
                return {
                    id : data.id,
                    index : index,
                    total_discount : Number(data.discount) != 0 ? Number(data.discount_price).toFixed(2) : 0,
                    total_price : Number(data.discount) != 0 ? (Number(data.unit_price) - Number(data.discount_price)).toFixed(2) : Number(data.unit_price).toFixed(2),
                    quantity : 1,
                    total_quantity : data.quantity
                }
            }
        },
        formatToCurrency(amount,fixed = 2){
            if(fixed > 0){
                return Number(amount).toFixed(fixed).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
            } else {
                let luy = (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                let new_luy = luy.split(".");
                return new_luy[0]; 
            }
        },
        handleIncrement(data,index){
            this.$emit('incrementProduct',this.actionHandle(data,index))
        },
        handleDecrement(data,index){
            this.$emit('decrementProduct',this.actionHandle(data,index))
        },
        handleCancel(data,index){
            this.$emit('cancelProduct',this.actionHandle(data,index))
        }
    }

}
</script>

<style>

</style>
