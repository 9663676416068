import { domain } from "../../domain";

export {
    paymentMethod,
    action
}


function paymentMethod(per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/get-payment-method?id=${id}`;
    } else if(name){
        return `${domain}/pos/get-payment-method?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}`
    } else {
        return `${domain}/pos/get-payment-method?per_page=${per_page}&page=${page}&orderBy=${orderBy}`;
    }
}

const action = `${domain}/pos/action-payment-method`;


