<template>
    <footer class="main-footer">
        <div class="float-right d-none d-sm-block">
            <b>Version</b> 1.0.0
        </div>
        <strong>Copyright&#169;2022 | All right reserved.</strong>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>