import { domain } from "../../domain";

export {
    productAttribute , attribute
}

function productAttribute(per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/get-productAttribute?id=${id}`;
    } else if(name){
        return `${domain}/pos/get-productAttribute?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}`
    } else {
        return `${domain}/pos/get-productAttribute?per_page=${per_page}&page=${page}&orderBy=${orderBy}`;
    }
}

function attribute(id){
    return `${domain}/pos/get-attribute?id=${id}`;
}






