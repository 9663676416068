<template>
<div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form action="uytyut" method="POST" id='edit_form' @submit.prevent="handleAdd()">
            <input type="hidden" name="id" id='eid'>
            <input id="edit_status" type="hidden">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Select Attribute</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive-sm position-relative">
                        <template v-for="(item, index) in attributes" :key="item.id">
                            <template v-if="product_attributes.find(element => element.attribute_id === item.id)">
                                <h5><b><i>{{ item.name }}</i></b></h5>
                                <table class="table data-table table-sm table-bordered" style="width: 100%">
                                    <thead class="bg-system text-light">
                                        <tr>
                                            <th class="d-none"></th>
                                            <th style="width:50px" class="d-none">#</th>
                                            <th>Value</th>
                                            <th>Increment</th>
                                            <th style="width:80px">photo</th>
                                            <th>Select</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="data in product_attributes" :key="data.id">
                                            <tr v-if="data.attribute_id == item.id"  :id="`tr${data.id}table`">
                                                <td class="d-none"></td>
                                                <td class="d-none">#</td>
                                                <td :data-col="`Value`" v-if="data.attribute_id != 1">{{ data.value }}</td>
                                                <td :data-col="`Value`" v-else> <span class="btn btn-sm rounded-circle" :style="`background-color: ${data.value}; height:20px; width:20px;`">&nbsp;</span></td>
                                                <td data-col="Increment"> $ {{ data.attribute_increment }}</td>
                                                <td data-col="Photo">
                                                    <img v-if="data.photo" :src="resource + data.photo" alt="Photo of Product" style="width: 30px; height:30px;" class="rounded">
                                                    <span v-else>&nbsp;</span>
                                                </td>
                                                <td data-col="Select">
                                                    <div class="icheck-success">
                                                        <input type="radio" :id="`radioSuccess${data.id}`" :name="item.id" :value="data.id" v-model="getAttributes[index]" required>
                                                            <label :for="`radioSuccess${data.id}`">
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </template>
                        </template>
                    </div>
                </div>
                <div class="modal-footer bg-white">
                    <button type="submit" class="btn btn-system btn-xs">
                        <i class="fa fa-plus-circle"></i> Add
                    </button>
                    <button @click="triggerReset()" type="button" class="btn btn-danger btn-xs" data-dismiss="modal">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import { resource } from '@/networks/domain'

export default {
    props: {
        product: {
            type: [Object],
            default: {}
        },
        attributes: {
            type: [Object, Array],
            default: []
        },
        product_attributes: {
            type: [Object, Array],
            default: []
        },
        getAttributes : {
            type : [Object , Array],
            default : []
        },
        productInvoices : {
            type : [Object, Array],
            default : []
        }
    },
    data() {
        return {
            tbl: "",
            per: "",
            resource : resource,
            num : 0,
            i : 0,
        }
    },
    methods: {
        triggerReset() {
            // $('#editModal').on('hidden.bs.modal', function () {
            //     $(this).find('form').trigger('reset');
            // })
        },
        convertToRoman(num) {
            var roman = '';
            var decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
            var romanNum = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I'];
            for (var i = 0; i < decimal.length; i++) {
                while (decimal[i] <= num) {
                    roman += romanNum[i];
                    num -= decimal[i];
                }
            }
            return roman;
        },
        priceForSale(data,is_discount,is_tax = false){
            let unit_price = data.unit_price;
            let discount = data.discount;
            let tax = data.vat_value;
            var total = 0;
            var total_tax = 0;

            if(is_discount == true){
                let price_discount = discount;
                let unit_price_after_dis = Number(unit_price) - Number(price_discount);

                total_tax = Number(unit_price_after_dis) * Number(tax) / Number(100);
                total = Number(unit_price_after_dis) + Number(total_tax);
            }
            if(is_discount == false){
                total_tax = Number(unit_price) * Number(tax) / Number(100);
                total = Number(unit_price) + Number(total_tax);
            }

            if(is_tax == true) return total_tax;
            else return total;
        },
        selectProcut(data,incrementPrice,att){
            let totalPriceWithDic = this.priceForSale(data,true);
            let totalPriceWithOutDic = this.priceForSale(data,false);
            let taxValue = data.discount != 0 ? this.priceForSale(data,true,true) : this.priceForSale(data,false,true);

            const items = {
                id : data.id,
                name : data.name,
                original_price : data.original_price,
                attributes : att,
                // unit_price :  (Number(data.unit_price) + (Number(data.unit_price) * Number(data.vat_value) / 100)).toFixed(2),
                unit_price : data.discount != 0 ? (Number(totalPriceWithDic) + Number(data.discount)).toFixed(2) : (Number(totalPriceWithOutDic) + Number(data.discount)).toFixed(2),
                unit_price_discount : data.discount != 0 ? Number(totalPriceWithDic).toFixed(2) : Number(totalPriceWithOutDic).toFixed(2),
                pro_discount : data.pro_discount ? Number(data.pro_discount).toFixed(2) : '',
                discount_type : data.discount_type ? data.discount_type : '',
                discount_price : data.discount != 0 ? Number(data.discount).toFixed(2) : 0,
                photo : data.photo ? data.photo : '',
                vat_value : data.vat_value,
                vat_name : data.vat_name,
                tax_price_per_item : Number(taxValue).toFixed(2),
                quantity : 1,

                total_discount : data.discount != 0 ? Number(data.discount).toFixed(2) : 0,
                total_price : data.discount != 0 ? (Number(totalPriceWithDic) + Number(incrementPrice)).toFixed(2) :  (Number(totalPriceWithOutDic) + Number(incrementPrice)).toFixed(2),
            }
            this.$emit('selectProduct',items);
        },
        handleAdd(){
            let att = [];
            let incrementPrice = 0;
            this.getAttributes.forEach((item, index) => {
                let id = item
                let attribute = this.product_attributes.filter(item => item.id == id)[0];
                attribute.name = this.attributes.filter(item => item.id == attribute.attribute_id)[0].name;
                att.push(attribute)
                incrementPrice = Number(incrementPrice) + Number(this.product_attributes.filter(item => item.id == id)[0].attribute_increment);
            })
            if(this.productInvoices.length == 0){
                this.selectProcut(this.product, incrementPrice, att);
            } else {
                let check = false;
                let index = 0;
                for(let i = 0 ; i < this.productInvoices.length ; i++){
                    let j = 0;
                    if(this.productInvoices[i].attributes.length > 0){
                        for(let x = 0 ; x < this.productInvoices[i].attributes.length ; x++){
                            for(let z = 0 ; z < att.length ; z++){
                                let proAttribute = JSON.stringify(this.productInvoices[i].attributes[x]);
                                let attibute = JSON.stringify(att[z]);
                                if(proAttribute === attibute){
                                    j = Number(j) + 1
                                    index = i;
                                }
                            }
                        }
                        if(j == att.length && j == this.productInvoices[i].attributes.length){
                            check = true;
                            break;
                        }
                    }
                }

                if(check){
                    const increment = {
                        id : this.product.id,
                        index : index,
                        total_discount : this.product.discount != 0 ? Number(this.product.discount).toFixed(2) : 0,
                        total_price : this.product.discount != 0 ? ((Number(this.product.unit_price) + (Number(this.product.unit_price) * Number(this.product.vat_value) / 100)) - Number(this.product.discount) + Number(incrementPrice)).toFixed(2) : ((Number(this.product.unit_price) + (Number(this.product.unit_price) * Number(this.product.vat_value) / 100)) + Number(incrementPrice)).toFixed(2),
                        quantity : 1
                    }
                    this.$emit('incrementProduct',increment)
                } else {
                    this.selectProcut(this.product,incrementPrice , att);
                }
            }
        }
    },
    mounted() {
        $(document).ready(function () {
            $('.chosen2').select2();
        });
    }
}
</script>
<style scoped>

    .modal-dialog, .modal-body { 
        background-color: transparent !important;
    }
    .modal-content{
        background-color: #ffffff7d !important;
        backdrop-filter: blur(33px) !important;
        background-blend-mode: overlay !important;
    }
    .modal-header {
        border-bottom: 0px;
    }
    .table-detail{
        table-layout: auto;
        box-sizing: border-box;
    }
    .table-detail tr td {
        padding: 5px 0px 5px 0px !important;
    }
   
    @media screen and (max-width: 600px) {
        table.data-table td {
            display: block;
        }
        .kh_muol {
            font-size: 15px;
        }
        .display-phone-none{
            display: none !important;
        }
    }

</style>

