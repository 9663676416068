<template>
    <div @click="triggerReset()" class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
        <form action="ohyes" method="POST" id='create_form' @submit.prevent="form_submit()">
            <input id="create_status" type="hidden" value="error">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Create System</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true" >&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group mb-1">
                        <label>
                            Name <span class="text-danger">*</span>
                        </label>
                        <input v-model="name" type="text" name="name" class="form-control" required>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-system btn-xs">
                        <i class="fa fa-save"></i> Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" 
                        @click="triggerReset()">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>  
</template> 
<script>
import { frm_submit } from '@/networks/services/helper/button'

export default {
    data(){
        return {
            name : null,
            tbl : "systems",
            per : "system",
        }
    },
    methods: {
        triggerReset(){
            $('#createModal').on('hidden.bs.modal', function () {
                $(this).find('form').trigger('reset');
            })
        },
        async form_submit(){
            let data = {
                name : this.name,
                tbl : this.tbl,
                per : this.per
            };
            await frm_submit(data);
            this.triggerReset();
            this.$emit("createModal",$('#create_status').val()); 

        },
    },
    mounted(){
        $(document).ready(function() {
            $('.chosen2').select2();
        });
    }
}
</script>
