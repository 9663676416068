import { domain } from "../../../domain";

export {
    expense
}

function expense(selectDate,fromDate,toDate){
    return `${domain}/pos/pos-report-expense?selectDate=${selectDate}&fromDate=${fromDate}&toDate=${toDate}`;
}


