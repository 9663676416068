<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <!-- <li class="nav-item px-2 d-flex justify-content-center align-content-center" data-widget="pushmenu" role="button" onclick="menu()">
        <div class="border-1">
          <img src="../../assets/ntc.png" id="logoNtc" class="fa-bars-staggered fa-rotate-180" width="43.27" style="filter: invert(21%) sepia(73%) saturate(3410%) hue-rotate(241deg) brightness(88%) contrast(100%);">
        </div>
      </li> -->
      <li class="nav-item" data-widget="pushmenu" role="button" onclick="menu()">
        <a  href="#" class="nav-link">
          <i class="fa-solid fa-bars-staggered txt-system fa-rotate-180"></i>
        </a>
      </li>
      <!-- <li v-if="add_sale" id="AddSale" class="nav-item d-none d-sm-inline-block">
        <router-link :to="{ name : 'pos-add-sale' }" class="nav-link btn btn-outline-system">
          <i class="fas fa-cash-register"></i>
        </router-link>
      </li> -->
      <li class="nav-item m-0 p-0" v-show="false">
        <select v-model="service" class="nav-link selectPicker form-control p-0" @change="changeService(this.service)">
          <option v-if="company_id==0"  value="0">Main System</option>
          <!-- <option v-if="company_id==0"  value="1">Poin Of Sale</option> -->
          <template v-if="services">
            <option  :value="data.id" v-for="data in services" :key="data.id"> {{ data.name }}</option>
          </template>
        </select>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <!-- <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
          <i class="far fa-bell"></i>
          <span class="badge badge-warning navbar-badge">15</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right nav" style="left: inherit; right: 0px;">
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 4 new messages
            <span class="float-right text-muted text-sm">3 mins</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-users mr-2"></i> 8 friend requests
            <span class="float-right text-muted text-sm">12 hours</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-file mr-2"></i> 3 new reports
            <span class="float-right text-muted text-sm">2 days</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
        </div>
      </li> -->
      <!-- <li class="nav-item">
        <a href="#" class="nav-link btn btn-sm p-1">
        </a>
      </li> -->
      <li class="nav-item dropdown" id="language" style="cursor:pointer" title="Change Color">
        <div class="nav-link" data-toggle="dropdown">
          <i class="fas fa-palette txt-system"></i>
        </div>
        <div class="dropdown-menu dropdown-menu-right dob lang">
            <div class="dropdown-item" @click="colorDefault()" id="defaultColor">
              <a style="color:#6357e9;">
                <i class="fas fa-paint-brush"></i> Default &nbsp; 
                  <span class=" float-right rounded-right" style="background-color:#6357e9; width: 25px;">&nbsp;</span>
                  <span class=" float-right rounded-left" style="background-color:#000000; width: 25px;">&nbsp;</span>
              </a>
            </div>
            <div class="dropdown-divider"></div>
            <div class="dropdown-item">
              <i class="fas fa-paint-roller" :style="`color:${sideBarColor}`"></i> <input v-model="sideBarColor" type="color" @change="sideBarColorPicker($event)" class="w-75 float-right border-0 outline-0 rounded btn-outline-warning px-1" id="colorSidebarPicker" title="Choose your sidebar color">
            </div>
            <div class="dropdown-divider"></div>
            <div class="dropdown-item">
              <i class="fas fa-brush" :style="`color:${color}`"></i> <input v-model="color" type="color" @change="colorPicker($event)" class="w-75 float-right border-0 outline-0 rounded btn-outline-warning px-1" id="colorPicker" title="Choose your color">
            </div>
        </div>
      </li>
      <li class="nav-item">
        <router-link to="/login" class="nav-link" title="Reload Page">
          <i class="fas fa-sync-alt txt-system"></i>
        </router-link>      
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a class="nav-link" data-widget="fullscreen" role="button" title="Full Screen">
         <i class="fas fa-expand-arrows-alt txt-system"></i>
        </a>
      </li>
      <li class="nav-item dropdown" id="language" style="cursor:pointer">
        <div v-if="language == 'en'" class="nav-link" data-toggle="dropdown">
          <img src="../../assets/united-states.png" alt="" width="20" class="pb-1">
        </div>
        <div v-else class="nav-link" data-toggle="dropdown">
          <img src="../../assets/cambodia.png" alt="" width="20" class="pb-1">
        </div>
        <div class="dropdown-menu dropdown-menu-right dob lang">
            <div @click="changeLanguage('kh')" class="dropdown-item txt-kh">
                <img src="../../assets/cambodia.png" alt="" width="18" class="mr-2"> ខ្មែរ
            </div>
            <div class="dropdown-divider"></div>
            <div @click="changeLanguage('en')" class="dropdown-item">
              <img src="../../assets/united-states.png" alt="" width="18" class="mr-2"> English
            </div>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link" :data-toggle="!isLogout ? 'dropdown' : ''">
          <i v-if="!isLogout" class="far fa-user txt-system"></i>
          <img v-else src="../../assets/sniper.jpeg" alt="" width="12">
        </a>
        <div id="menu-dropppp" class="dropdown-menu dropdown-menu-right dob">
            <router-link to="/about-me" class="dropdown-item">
                <i class="fas fa-user txt-system mr-2"></i> {{$t('My Profile')}}
            </router-link>
            <div class="dropdown-divider"></div>

            <div @click="logout()" class="dropdown-item">
                <i class="fa fa-sign-out-alt text-danger mr-2"></i> {{$t("log_out")}}
            </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import { checkPermission } from '@/networks/services/helper/helper.js'
import { user } from '@/networks/services/auth/user.service.js'
import { svgColor } from '@/assets/js/svgColor'
import axios from 'axios'
import '../../networks/services/auth/login.service.js'


export default {
    props : {
      services : {
        type : [Object, Array],
        default : null
      },
      service : {
        type : [String, Number],
        default : 0
      }
    },
    data(){
      return {
        // service : '',
        company_id : 0,
        def : false,
        add_sale : false,
        language : 'en',
        color: '#493ce0',
        sideBarColor: '#000000',
        isLogout : false
      }
    },
    watch: {
      async service(after, before){
        if(after != before){
          await this.selectOption();
        }
      }
    },
    methods : {
      async selectOption(){
        await this.$nextTick();
        $('.selectPicker').selectpicker('refresh');       
        $('.selectPicker').selectpicker(); 
      },
      changeService(service){
        this.$emit("changeService",service);
        this.$router.push({name : 'dashboard'});
      },
      getData(){
        //  this.add_sale = await checkPermission("sale_pos","l");
        this.checkLanguage();
        this.company_id = user('company_id');
      },
      checkLanguage(){
        if(!localStorage.language){
          localStorage.language = this.language;
        } else {
          this.language = localStorage.language;
        }
      },
      changeLanguage(lang){
        this.$i18n.locale = lang;
        localStorage.language = lang;
        this.language = localStorage.language
        $('#language').trigger('click');
      },
      checkColor(){
        if(!localStorage.color){
          localStorage.color = '#493ce0';
          this.color = '#493ce0';
          $("body").get(0).style.setProperty("--system-color", `${localStorage.color}`);

          const color = svgColor(this.color);
          $('#logoNtc').attr('style', color);
        } else {
          this.color = localStorage.color;
          $("body").get(0).style.setProperty("--system-color", `${localStorage.color}`);

          const color = svgColor(this.color);
          $('#logoNtc').attr('style', color);
        }
      },
      checkSidebarColor(){
        if(!localStorage.sideBarColor){
          localStorage.sideBarColor = '#000000'; 
          this.sideBarColor = '#000000';
          $("body").get(0).style.setProperty("--sideBar-color", `${localStorage.sideBarColor}`);
        } else {
          this.sideBarColor = localStorage.sideBarColor;
          $("body").get(0).style.setProperty("--sideBar-color", `${localStorage.sideBarColor}`);
        }
      },
      colorDefault(){
        localStorage.color = '#493ce0';
        this.color = '#493ce0';
        localStorage.sideBarColor = "#000000";
        this.sideBarColor = "#000000";
        $("body").get(0).style.setProperty("--system-color", `${localStorage.color}`);
        $("body").get(0).style.setProperty("--sideBar-color", `${localStorage.sideBarColor}`);

        const color = svgColor(this.color);
        $('#logoNtc').attr('style', color);
      },
      colorPicker(obj){
        localStorage.color = obj.target.value;
        $("body").get(0).style.setProperty("--system-color", `${localStorage.color}`);

        const color = svgColor(obj.target.value);
        $('#logoNtc').attr('style', color);
      },
      sideBarColorPicker(obj){
        localStorage.sideBarColor = obj.target.value;
        $("body").get(0).style.setProperty("--sideBar-color", `${localStorage.sideBarColor}`);
      },
      logout(){
        this.isLogout = true;
        $('#menu-dropppp').removeClass('show');
        axios.post('/logout')
        .then((res) => {
            this.isLogout = false;
            this.$router.push('/logout');
            alertify.set('notifier','position', 'top-right');
            alertify.success(res.data.message);
        })
        .catch(function(err){
            console.log(err);
            this.isLogout = false;
        });
      }
    },
    mounted() {
      this.getData();
      this.checkColor();
      this.checkSidebarColor();
      this.$emit('getService',this.service);
    },
};
</script>
