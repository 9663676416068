import { domain } from "../../domain";

export {
    stockIn , createStockIn , store , detail
}

function stockIn(per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/get-stockIn?id=${id}`;
    } else if(name){
        return `${domain}/pos/get-stockIn?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}`
    } else {
        return `${domain}/pos/get-stockIn?per_page=${per_page}&page=${page}&orderBy=${orderBy}`;
    }
}

function detail(id){
    return `${domain}/pos/get-stockInById?id=${id}`
}

const createStockIn = `${domain}/pos/create-stockIn`;
const store = `${domain}/pos/store-stockIn`;