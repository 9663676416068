<template>
    <div class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
        <form action="ohyes" method="POST" id='create_form' @submit.prevent="submit()">
            <input id="create_status" type="hidden" value="error">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Create Service</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true" >&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group mb-1">
                        <label>
                            Service <span class="text-danger">*</span>
                        </label>
                        <select v-model="system_id" class="border-page-color selectPicker form-control" data-live-search="true" required>
                            <option :value="item.id" v-for="item in services" :key="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button :disabled="isCreate" id="save" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isCreate" src="../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" 
                        @click="triggerReset()">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>  
</template> 
<script>
import { createCompanySystemService } from '@/networks/services/dashboard/company.service.js'
import axios from 'axios'
import { config } from '@/networks/config.js'
export default {
    props: {
        services: {
            type: [String, Object],
            default: null,
        },
    },
    data(){
        return {
            system_id : 0,
            isCreate : false,
        }
    },
    methods: {
        triggerReset(){
            this.isCreate = false
        },
        async submit(){
            config();
            this.isCreate = true;
            let data = {
                system_id : this.system_id,
                company_id : this.$route.params.id
            };

            const result = await axios
                            .post(createCompanySystemService,data)
                            .catch(error => {
                                console.log(error.response)
                            })
            this.triggerReset();
            if(result.data.status == "success"){
                $('#createModal').modal('hide');
                $("#create_form")[0].reset();
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
                this.$emit("createModal","success");   
            } else if(result.data.status == "401"){
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
                localStorage.removeItem('posUser');
                this.$router.push('/Unauthenticated');
            } else if(result.data.status == 'is_permission_no'){
                alertify.set('notifier','position', 'top-right');
                alertify.error(`You are no permission to do this stuff!`);
                this.$emit("createModal","is_permission_no");   
            } else if(result.data.status == "error"){
                $('#createModal').modal('hide');
                $("#create_form")[0].reset();
                $("#create_status").val("error");
                alertify.set('notifier','position', 'top-right');
                alertify.error(`${result.data.message}`);
            } else {
                console.log(result)
            }
        },
    },
    mounted(){
      
    }
}
</script>
