import { domain } from "../../../domain";

export {
    incomeExpense
}

function incomeExpense(selectDate,fromDate,toDate){
    return `${domain}/pos/pos-report-income-expense?selectDate=${selectDate}&fromDate=${fromDate}&toDate=${toDate}`;
}


