<template>

    <div class="page404">
    <div class="container mt-xl-5 bg-white">
      <router-link to="/login" class="btn btn-danger mt-3 mb-3" style="color:white !important">Back</router-link>
      <h1 class="text-black mb-4">Service Unavailable or Under Maintenance</h1>

      <div class="card">
      <div class="card-body">
        <div class="error-page">
            <h2 class="headline text-danger">503</h2>
            <div class="error-content">
                <h3 class="mb-3">
                    <i class="fa fa-exclamation-triangle text-danger"></i> 
                    Oops! Server Down.
                </h3>
                <p class="text-monospace txt-kh">
                    ប្រព័ន្ធមានបញ្ហា​ ឬ សេវាមានភាពមិនប្រក្រតី <br> សូមទាក់ទាក់ទៅអ្នកគ្រប់គ្រងប្រព័ន្ធ!
                </p>
                <p>
                    The system you are using is under maintenance or server down, please contact your system administrator!
                </p>
            </div>
        </div>
    </div>
    </div>
    </div>
  </div>

</template>

<script>
export default {

  mounted(){
    
  }
}
</script>

<style>

</style>

