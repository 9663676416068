import { domain } from "../../domain";

export {
    product,
    checkBarcode,
    create,
    store,
    update,
    productAttribute,
    deleteProduct
}

function product(company_category_id=null,per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/product_list?id=${id}`;
    } else if(name){
        return `${domain}/pos/product_list?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}&company_category_id=${company_category_id}`
    } else {
        return `${domain}/pos/product_list?per_page=${per_page}&page=${page}&orderBy=${orderBy}&company_category_id=${company_category_id}`;
    }
}
function productAttribute(id){
    return `${domain}/pos/productAttribute?id=${id}`;
}

function deleteProduct(id){
    return `${domain}/pos/delete-product?id=${id}`;
}




const checkBarcode = `${domain}/pos/check_barcode`;
const create = `${domain}/pos/create`;
const store = `${domain}/pos/store`;
const update = `${domain}/pos/update`;



// function deleteRole(id){
//     return `${domain}/pos/get-permission?id=${id}`;
// }



