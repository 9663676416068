<template>
    <div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <form action="uytyut" method="POST" id='edit_form' @submit.prevent="form_update($event)">
              <input type="hidden" name="eid" id='eid' value="">
              <input id="edit_status" type="hidden">
              <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Edit Product Photo</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label for="">Product Image</label>
                            <div class="w-100">
                                <input id="ephoto" @change="changeImg('ephoto',$event)" type="file" required>
                            </div>
                            <div>
                                <button @click="resetImg('ephoto')" v-if="epho" title="Reset Photo" class="border-0 rounded outline-0 float-right btn-outline-dark"><i class="fas fa-redo-alt"></i></button>
                                <img v-if="ephoo" :src="ephotoo" class="my-2 w-25 rounded">
                            </div>
                        </div>
                    </div>        
                </div>
                  <div class="modal-footer">
                    <button :disabled="isUpdate" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isUpdate" src="../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Update
                    </button>
                    <button @click="triggerReset()" type="button" class="btn btn-danger btn-xs" data-dismiss="modal">
                        <i class="fa fa-times"></i> Close
                    </button>
                  </div>
              </div>
          </form>
        </div>
    </div>  
    </template>
    
    <script>
    import { frm_update } from '@/networks/services/helper/button.js'
    
    export default {
        props : {
            ephoto : {
                type : { String },
                default : null
            },
        },
        data(){
            return {
                tbl : "pos_product_photos",
                per : "pos_product",
                epho: false,
                ephoo: null,
                default : '',
                ephotoo : '',
                isUpdate : false,
            }
        },
        methods: {  
            triggerReset(){
                this.ephotoo = '';
                this.epho = false;
                this.ephoo = null;
                this.isUpdate = false;
            },
            resetImg(value){
                if(value == "ephoto"){
                    this.ephotoo = '';
                    this.epho = false;
                    this.ephoo = null;
                    $('#ephoto').val('');
                }
            },
            changeImg(value,obj){
                if(value == "ephoto"){
                    this.ephotoo = URL.createObjectURL(obj.target.files[0]);
                    this.ephoo = obj.target.files[0];
                    this.epho = true;
                }
            },
            async form_update(){
                if(!this.epho) { return }

                this.isUpdate = true;
                let data = new FormData;
                data.append('id',$('#eid').val())
                data.append('tbl',this.tbl)
                data.append('per',this.per)
                data.append('photo',this.ephoo)

                await frm_update(data);
                let result = {
                    value : $('#edit_status').val(),
                    photo : this.photo
                };
                this.$emit("updateModal",result); 
                this.triggerReset();
            },
        },
        mounted(){
            $(document).ready(function() {
                $('.chosen2').select2();
            });
        }
    }
    </script>
    
    <style>
    
    </style>