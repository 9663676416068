<template>
    <div class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
        <form action="ohyes" method="POST" id='create_form' @submit.prevent="form_submit()">
            <input id="create_status" type="hidden" value="error">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Create Customer Type</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true" >&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group mb-1">
                        <label for="name">
                            Name <span class="text-danger">*</span>
                        </label>
                        <input v-model="name" type="text" id="name" class="form-control" required>
                    </div>
                    <div class="form-group mb-1">
                        <label for="discount">
                            Discount (%) <span class="text-danger">*</span>
                        </label>
                        <input v-model="discount" type="text" id="discount" class="form-control" 
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                            required
                        >
                    </div>
                    <!-- <div class="form-group mb-1"> -->
                        <!-- <label for="type">
                            Type <span class="text-danger">*</span>
                        </label> -->
                        <!-- <select v-model="type" id="type" class="border-page-color selectPicker form-control txt-kh" data-live-search="false" required> -->
                            <!-- <option value="">Please Selete One</option> -->
                            <!-- <option value="$">$</option> -->
                            <!-- <option value="%">%</option> -->
                        <!-- </select> -->
                    <!-- </div> -->
                </div>
                <div class="modal-footer">
                    <button :disabled="isCreate" id="save" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isCreate" src="../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" 
                        @click="triggerReset()">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>  
</template> 
<script>
import { frm_submit } from '@/networks/services/helper/button'
import { user } from '@/networks/services/auth/user.service'

export default {
    data(){
        return {
            name : '',
            type : '%',
            discount : '',
            tbl : "customer_types",
            per : "customer_type",
            isCreate : false,
        }
    },
    methods: {
        triggerReset(){
            // $('#createModal').on('hidden.bs.modal', function () {
            //     $(this).find('form').trigger('reset');
            // })
            this.name = '';
            this.type = '';
            this.discount = '';
            this.isCreate = false;
        },
        async form_submit(){
            this.isCreate = true;
            let com_id = user('company_id');
            let data = {
                name : this.name,
                type : this.type,
                discount : this.discount,
                company_id : com_id,
                tbl : this.tbl,
                per : this.per
            };
            await frm_submit(data);
            this.triggerReset();
            this.$emit("createModal",$('#create_status').val()); 

        },
    },
    mounted(){
        $(document).ready(function() {
            $('.chosen2').select2();
        });
    }
}
</script>
