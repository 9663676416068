<template>
    <div class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
        <form action="ohyes" method="POST" id='create_form' @submit.prevent="form_submit()">
            <input id="create_status" type="hidden" value="error">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Create Product Discount</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true" >&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="reference">
                                    Reference
                                </label>
                                <input v-model="reference" id="reference" type="text" name="name" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="product">
                                    Product <span class="text-danger">*</span>
                                </label>
                                <template v-if="products">
                                    <select v-model="product_id" id="product" class="border-page-color selectPicker form-control" data-live-search="true" required>
                                        <option value="">PLEASE SELECT ONE</option>
                                        <option :value="product.id" v-for="product in products" :key="product.id">{{ product.name }}</option>
                                    </select>
                                </template>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="discount">
                                    Discount <span class="text-danger">*</span>
                                </label>
                                <input v-model="discount" id="discount" type="text" class="form-control" required
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                >
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="type">
                                    Type <span class="text-danger">*</span>
                                </label>
                                <select v-model="type" id="type" class="border-page-color selectPicker form-control txt-kh" data-live-search="false" required>
                                    <option value="">Please Selete One</option>
                                    <option value="$">$</option>
                                    <option value="%">%</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="start_date">
                                    Start Date <span class="text-danger">*</span>
                                </label>
                                <input 
                                    v-model="start_date" 
                                    id="start_date" 
                                    type="date" 
                                    name="name" 
                                    class="form-control" 
                                    required
                                >
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="end_date">
                                    End Date <span class="text-danger">*</span>
                                </label>
                                <input v-model="end_date" id="end_date" type="date" name="name" class="form-control" required>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-1">
                                <label for="description">
                                    Description
                                </label>
                                <textarea v-model="description" id="description" class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" id="save" class="btn btn-system btn-xs">
                        <img v-if="isCreate" src="../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" 
                        @click="triggerReset()">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>  
</template> 
<script>
import axios from 'axios'
import { store } from '@/networks/services/pos/productDiscount.service'
import { user } from '@/networks/services/auth/user.service'

export default {
    props : {
        products : {
            type : [Object, String , Array],
            default : null
        }
    },
    data(){
        return {
            name : null,
            tbl : "pos_product_discounts",
            per : "pos_product_discount",
            product_id : '',
            type : '',
            discount : '',
            start_date : '',
            end_date : '',
            reference : '',
            description : '',
            isCreate : false,
        }
    },
    methods: {
        triggerReset(){
            this.product_id = '';
            this.type = '';
            this.discount = '';
            this.start_date = '';
            this.end_date = '';
            this.reference = '';
            this.description = "";
            this.isCreate = false;
        },
        async form_submit(){
            let com_id = user('company_id');
            let data = {
                company_id : com_id,
                pos_product_id : this.product_id,
                discount_type : this.type,
                discount : this.discount,
                start_date : this.start_date,
                end_date : this.end_date,
                description : this.description,
                reference : this.reference,
            };
            this.isCreate = true;
            $('#save').prop('disabled', true);

            // await frm_submit(data);
            const result = await axios
                                .post(`${store}`,data)
                                .catch(error => {
                                    console.log(error.response);
                                });
            this.isCreate = false;
            this.triggerReset();
            $('#save').prop('disabled', false);

            if(result.data.status == "success"){
                $('#createModal').modal('hide');
                $("#create_form")[0].reset();
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
                this.$emit("createModal","success");   
            } else if(result.data.status == "401"){
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
                localStorage.removeItem('posUser');
                this.$router.push('/Unauthenticated');
            } 
            else if(result.data.status == "is_permission_no"){
                $('#createModal').modal('hide');
                $("#create_form")[0].reset();
                alertify.set('notifier','position', 'top-right');
                alertify.error(`You are no permission to do this stuff!`);
                this.$emit("createModal","is_permission_no");   
            } 
            else if(result.data.status == "error"){
                $('#createModal').modal('hide');
                $("#create_form")[0].reset();
                $("#create_status").val("error");
                alertify.set('notifier','position', 'top-right');
                alertify.error(`${result.data.message}`); 
            } else {
                console.log(result)
            } 
            this.triggerReset();
            this.$emit("createModal",$('#create_status').val()); 

        },
    },
    mounted(){
        $(document).ready(function() {
            $('.chosen2').select2();
        });
    }
}
</script>
