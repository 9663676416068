import { domain } from "../../domain";

export {
    systemCategory
}

function systemCategory(system_id=null,per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/get-system-category?id=${id}`;
    } else if(name){
        return `${domain}/pos/get-system-category?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}&system_id=${system_id}`
    } else {
        return `${domain}/pos/get-system-category?per_page=${per_page}&page=${page}&orderBy=${orderBy}&system_id=${system_id}`;
    }
}





