import { domain } from "../../../domain";

export {
    inventory
}

function inventory(){
    return `${domain}/pos/pos-report-inventory`;
}


