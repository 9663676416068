<template>
    <div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
        <form action="ohyes" method="POST">
            <input id="create_status" type="hidden" value="error">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Stock In Details</strong>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true" >&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card">
                        <div class="card-body">
                            <div class="row my-3 mx-0">
                                <div v-if="ecompanies" class="col-lg-8 col-sm-12 d-sm-block d-none d-flex justify-content-center align-content-center">
                                    <div class="row" style="box-sizing: border-box;">
                                        <div class="col-lg-2 col-sm-12 mr-lg-3 mb-3 display-phone">
                                            <img v-if="ecompanies.logo" :src="resource + ecompanies.logo" width="100" class="rounded-circle">
                                        </div>
                                        <div class="col-lg-8 col-sm-12 display-phone">
                                            <span class="kh_muol p-0 d-block"> {{ ecompanies.kh_name }}</span>
                                            <span class="kh_content p-0 d-block"> {{ ecompanies.en_name }}</span>
                                            <span class="kh_content p-0 d-block">Address ៖ {{ ecompanies.address }}</span>
                                            <span class="kh_content p-0 d-block">Telephone (1) ៖ {{ ecompanies.phone_line1 }}</span>
                                            <span v-if="ecompanies.phone_line2"  class="kh_content p-0 d-block">Telephone (2) ៖ {{ ecompanies.phone_line2 }}</span>
                                            <span v-if="ecompanies.phone_line3"  class="kh_content p-0 d-block">Telephone (3) ៖ {{ ecompanies.phone_line3 }}</span>
                                            <span v-if="ecompanies.phone_line4"  class="kh_content p-0 d-block">Telephone (4) ៖ {{ ecompanies.phone_line4 }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="edata" class="col-lg-4 col-sm-12 p-0">
                                    <table width="100%" class="table-detail">
                                        <tbody>
                                            <tr class="border-bot">
                                                <td class="text-left">Date In</td>
                                                <td class="text-right">:</td>
                                                <td class="text-right">{{ new Date(edata.in_date).toLocaleDateString("en-GB", { weekday : 'long' , day : 'numeric', 'month' : 'numeric' , 'year' : 'numeric' }) }} </td>
                                            </tr>
                                            <tr class="border-bot">
                                                <td class="text-left">Code</td>
                                                <td class="text-right">:</td>
                                                <td class="text-right">{{ edata.code }}</td>
                                            </tr>
                                            <tr class="border-bot" v-if="edata.sup_name">
                                                <td class="text-left">Supplier</td>
                                                <td class="text-right">:</td>
                                                <td class="text-right">{{ edata.sup_name }}</td>
                                            </tr>
                                            <tr class="border-bot">
                                                <td class="text-left">Reference</td>
                                                <td class="text-right">:</td>
                                                <td class="text-right">&nbsp;{{ edata.reference }}</td>
                                            </tr>
                                            <tr class="border-bot">
                                                <td class="text-left">Total Price (Include Delivery)</td>
                                                <td class="text-right">:</td>
                                                <td class="text-right">${{ edata.price }}</td>
                                            </tr>
                                            <tr class="border-bot">
                                                <td class="text-left">Delivery Price</td>
                                                <td class="text-right">:</td>
                                                <td class="text-right">${{ edata.delivery_price }}</td>
                                            </tr>
                                            <tr class="border-bot">
                                                <td class="text-left">Type Name</td>
                                                <td class="text-right">:</td>
                                                <td class="text-right">{{ edata.type_name }}</td>
                                            </tr>
                                            <tr class="border-bot">
                                                <td class="text-left">Description</td>
                                                <td class="text-right">:</td>
                                                <td class="text-right text-wrap" style="max-width: 30px">{{ edata.description }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- table  -->
                            <h3 class="txt-system kh_muol">Details</h3>
                            <div v-if="edetails" class="table-responsive position-relative">
                                <table class="table data-table table-sm table-bordered position-relative" style="width: 100%">
                                    <thead class="bg-system text-light">
                                        <tr>
                                            <th class="d-none"></th>
                                            <th class="display-phone-none">#</th>
                                            <th>Name</th>
                                            <th style="width:80px">photo</th>
                                            <th>Item</th>
                                            <th>Unit Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(data, index) in edetails" :key="data.id" :id="`tr${data.id}table`">
                                            <td class="d-none"></td>
                                            <td class="display-phone-none">{{ index + 1 }}</td>
                                            <td :data-col="`#`+ (index + 1) + `. Name`">{{ data.product_name }}</td>
                                            <td data-col="Photo">
                                                <img v-if="data.product_photo" :src="resource + data.product_photo" alt="Photo of Product" style="width: 30px; height:30px;" class="rounded">
                                                <span v-else>&nbsp;</span>
                                            </td>
                                            <td data-col="Item">&nbsp;{{ data.quantity }}</td>
                                            <td data-col="Unit Type">&nbsp;{{ data.is_stock == 1 ? (data.quantity / data.quantity_per_unit_type)  + ' ' + data.unit_type_name : '' }}</td>
                                        </tr>    
                                    </tbody>
                                </table>
                            </div>
                            <h3 v-if="edata.photo" class="txt-system kh_muol">Document</h3>
                            <div v-if="edata.photo" class="row">
                                <div class="col-12">
                                    <img :src="resource + edata.photo" alt="Invoice Detail" class="w-100">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        </div>
    </div>  
</template> 
<script>
import { resource } from '@/networks/domain'

export default {
    props : {
        edata: {
            type: [Array, Object],
            default: [],
        },
        ecompanies: {
            type : [Array , Object],
            default: {}
        },
        edetails : {
            type : [Array, Object],
            default : null
        }
    },
    data(){
        return {
            resource : resource,
        }
    },
    methods: {
        
    },
    mounted(){
    
    }
}
</script>
<style scoped>

    .modal-dialog, .modal-body { 
        background-color: transparent !important;
    }
    .modal-content{
        background-color: #ffffff7d !important;
        backdrop-filter: blur(33px) !important;
        background-blend-mode: overlay !important;
    }
    .modal-header {
        border-bottom: 0px;
    }
    .table-detail{
        table-layout: auto;
        box-sizing: border-box;
    }
    .table-detail tr td {
        padding: 5px 0px 5px 0px !important;
    }
   
    @media screen and (max-width: 600px) {
        table.data-table td {
            display: block;
        }
        .kh_muol {
            font-size: 15px;
        }
        .display-phone-none{
            display: none !important;
        }
    }

</style>
