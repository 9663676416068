import axios from "axios";
import { domain } from './domain.js'

export function config(){
    if(localStorage.getItem('posUser')){
        const DATA    = JSON.parse(localStorage.getItem('posUser'));
        const TOKEN   = `Bearer ${DATA.token}`;
        const USER_ID = DATA.data.id;
        const COMPANY_ID = DATA.data.company_id;
        const ROLE_ID = DATA.data.role_id;
        const SYSTEM_USER_TYPE_ID = DATA.data.system_user_type_id;
    
        axios.defaults.baseURL = domain;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
        axios.defaults.headers.common['xsrfCookieName'] = 'XSRF-TOKEN';
        axios.defaults.headers.common['xsrfHeaderName'] = 'X-XSRF-TOKEN';
        axios.defaults.headers.common['Content-Type'] = 'multipart/form-data, application/json';
        axios.defaults.headers.common['Cache-Control'] = 'no-cache, no-store, must-revalidate';
        axios.defaults.headers.common['Pragma'] = 'no-cache';
        axios.defaults.headers.common['Expires'] = '0';
        axios.defaults.headers.common['Authorization'] = TOKEN;
        axios.defaults.headers.common['user_id'] = USER_ID;
        axios.defaults.headers.common['company_id'] = COMPANY_ID;
        axios.defaults.headers.common['role_id'] = ROLE_ID;
        axios.defaults.headers.common['system_user_type_id'] = SYSTEM_USER_TYPE_ID;

    }
}



