<template>
    <div class="row px-2 pb-3 m-10">
        <!-- Processing loader  -->
        <div v-if="pageLoad" class="col-12">
            <div class="card position-absolute " style="z-index:10; left: 45%; top: 50%;">
                <div class="card-body">
                <span class="txt-system">Processing...</span>
                </div>
            </div>
        </div>
        <!-- shadow-system-sm -->
        <div v-for="(data,index) in datas.data" :key="index"  class="col-lg-3 col-md-6 col-6 rounded p-3 product-hover" style="cursor:pointer">
            <div :class ="`bg-white ${data.is_add > 0 ? '' : ''} border-radius product-item`" @click="clickProduct(data.id,data.is_attribute,data)">
                <div class="w-100 p-3">
                    <div class="position-relative img-p">
                        <img 
                            v-if="loadingId == data.id && isLoading" src="@/assets/sniper.jpeg" 
                            :id="'loading'+data.id+'img'" 
                            class="img-product border-radius"
                        >
                        <img 
                            v-else-if="data.photo" 
                            :src="resource + data.photo" 
                            class="img-product border-radius" 
                            :id="'img'+data.id+'product'" 
                            :img-title="`Added : ${data.is_add}`" 
                        >
                        <img 
                            v-else src="@/assets/noProductImage.jpeg" 
                            class="img-product border-radius" 
                            :id="'img'+data.id+'product'" 
                            :img-title="`Added : ${data.is_add}`"
                        >
                        <span v-if="data.is_add > 0" class="img-add" :img-title="`Added : ${data.is_add}`"></span>
                    </div>
                </div>
                <div class="px-3 pb-2">
                    <small><i>VAT@ {{ data.vat_value != '' ? data.vat_value : 0 }}%</i></small>
                    <p class="text-truncate m-0">
                        <b>{{ data.name }}</b>
                    </p>
                    <p class="text-truncate text-sm  m-0">
                        <i>{{$t("Quantity")}}</i> : {{ Number(data.onhand).toFixed(0) }}
                    </p>
                    <span v-if="data.discount != 0"><del><small class="text-gray">$ {{ data.discount != 0 ? (Number(priceForSale(data,true)) + Number(data.discount)).toFixed(2) : (Number(priceForSale(data,false)) + Number(data.discount)).toFixed(2)  }}</small></del>&nbsp;</span>
                    <span class="txt-system text-lg text-bold">$ {{ data.discount != 0 ? priceForSale(data,true).toFixed(2) : priceForSale(data,false).toFixed(2) }}</span>
                    <span v-if="data.discount != 0" class="float-right text-lg my-auto text-danger">{{ data.pro_discount }} {{ data.discount_type }}</span>
                </div>
            </div>
        </div>
        <div class="col-12 text-center">
            <p v-if="datas.totalIndexPage == 1 || datas.totalIndexPage == datas.page" class="text-center txt-system">No Data <i class="fa-solid fa-ban"></i></p>
            <button 
                v-else
                :disabled="datas.fetch"
                class="btn btn-outline-system-xs"
                type="button"
                @click="nextPage(datas.page+1)"
            >
                Load More
                <img v-if="datas.fetch" src="../../assets/sniper.jpeg" alt="" width="12">
            </button>
        </div>
    </div>
</template>
<script>
    import { resource } from '@/networks/domain';
    export default {
        props : {
            datas : {
                type : [Object],
                default : null
            },
            pageLoad : {
                type : [Boolean],
                default : false
            },
            isLoading : {
                type : [Boolean],
                default : false
            },
            loadingId : {
                type : [Number, String],
                default : 0
            },
            productInvoices : {
                type : [Object, Array],
                default : []
            }
        },
        data(){
            return {
                resource : resource
            }
        },
        methods : {
            priceForSale(data,is_discount,is_tax = false){
                let unit_price = data.unit_price;
                let discount = data.discount;
                let tax = data.vat_value;
                var total = 0;
                var total_tax = 0;

                if(is_discount == true){
                    let price_discount = discount;
                    let unit_price_after_dis = Number(unit_price) - Number(price_discount);

                    total_tax = Number(unit_price_after_dis) * Number(tax) / Number(100);
                    total = Number(unit_price_after_dis) + Number(total_tax);
                }
                if(is_discount == false){
                    total_tax = Number(unit_price) * Number(tax) / Number(100);
                    total = Number(unit_price) + Number(total_tax);
                }

                if(is_tax == true) return total_tax;
                else return total;
            },
            selectProcut(data){
                let totalPriceWithDic = this.priceForSale(data,true);
                let totalPriceWithOutDic = this.priceForSale(data,false);
                let taxValue = data.discount != 0 ? this.priceForSale(data,true,true) : this.priceForSale(data,false,true);
                const items = {
                    id : data.id,
                    name : data.name,
                    original_price : data.original_price,
                    attributes : [],
                    // unit_price : (Number(data.unit_price) + (Number(data.unit_price) * Number(data.vat_value) / 100)).toFixed(2),
                    unit_price : data.discount != 0 ? (Number(totalPriceWithDic) + Number(data.discount)).toFixed(2) : (Number(totalPriceWithOutDic) + Number(data.discount)).toFixed(2),
                    unit_price_discount : data.discount != 0 ? Number(totalPriceWithDic).toFixed(2) : Number(totalPriceWithOutDic).toFixed(2),
                    pro_discount : data.pro_discount ? Number(data.pro_discount).toFixed(2) : '',
                    discount_type : data.discount_type ? data.discount_type : '',
                    discount_price : data.discount != 0 ? Number(data.discount).toFixed(2) : 0,
                    photo : data.photo ? data.photo : '',
                    vat_value : data.vat_value,
                    vat_name : data.vat_name,
                    tax_price_per_item : Number(taxValue).toFixed(2),
                    quantity : 1,

                    total_discount : data.discount != 0 ? Number(data.discount ).toFixed(2) : 0,
                    total_price : data.discount != 0 ? Number(totalPriceWithDic).toFixed(2) : Number(totalPriceWithOutDic).toFixed(2),
                    
                }
                this.$emit('selectProduct',items);
            },
            clickProduct(id, is_attribute, data){
                if(this.isLoading == true) return ''
                if(!this.isLoading && is_attribute > 0){
                    this.$emit('clickProduct',id);
                } else {
                    if(this.productInvoices.length == 0){
                        this.selectProcut(data);
                    } else {
                        const check = this.productInvoices.find(item => item.id == data.id);
                        if(check){
                            const increment = {
                                id : data.id,
                                index : this.productInvoices.findIndex(item => item.id == data.id),
                                total_discount : data.discount != 0 ? Number(data.discount).toFixed(2) : 0,
                                total_price : data.discount != 0 
                                ? 
                                    // ((Number(data.unit_price) + (Number(data.unit_price) * Number(data.vat_value) / 100)) - Number(data.discount)).toFixed(2) 
                                    this.priceForSale(data,true)
                                : 
                                    // (Number(data.unit_price) + (Number(data.unit_price) * Number(data.vat_value) / 100)).toFixed(2),
                                    this.priceForSale(data,false),
                                quantity : 1
                            }
                            this.$emit('incrementProduct',increment)
                        } else {
                            this.selectProcut(data);
                        }
                    }
                }
            },
            nextPage(value){
                this.$emit('nextPage',value);
            }
        }
    }

</script>
<style scoped>

</style>