<template>
    <div class="modal fade" id="createModalAttribute" tabindex="-1" role="dialog" aria-labelledby="createModalAttribute" aria-hidden="true">
        <div class="modal-dialog" role="document">
        <form action="ohyes" method="POST" id='create_form' @submit.prevent="form_submit()">
            <input id="create_status" type="hidden" value="error">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Add Product Attribute</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true" >&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-sm-12 form-group mb-1">
                            <label for="att_id">
                                Attribute <span class="text-danger">*</span>
                            </label>
                            <select v-model="att_id" id="att_id" class="border-page-color selectPicker form-control" data-live-search="true" required
                                @change="changeAtt()"
                            >
                                <option value="">PLEASE SELECT ONE</option>
                                <option :value="attribute.id" v-for="attribute in attributes" :key="attribute.id">{{ attribute.name }}</option>
                            </select>
                        </div>
                        <div v-if="!isShow" class="col-lg-6 col-sm-12 form-group mb-1">
                            <label for="name">
                                Value <span class="text-danger">*</span>
                            </label>
                            <input type="text" id="name" v-model="name" class="form-control" required>
                        </div>
                        <div v-if="isShow" class="col-lg-6 col-sm-12 form-group mb-1">
                            <label for="value">
                                Color <span class="text-danger">*</span>
                            </label>
                            <input type="color" id="value" v-model="value" class="form-control" required>
                        </div>
                        <div v-if="isShow" class="col-lg-6 col-sm-12 form-group mb-1">
                            <label for="val">
                                Value of Color <span class="text-danger">*</span>
                            </label>
                            <input type="text" id="val" readonly :value="value" class="form-control">
                        </div>
                        <div class="col-lg-6 col-sm-12 form-group mb-1">
                            <label for="increment">
                                Increment <span class="text-danger">*</span>
                            </label>
                            <input type="text" id="increment" v-model="increment" class="form-control" required
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            >
                        </div>
                        <div class="col-lg-6 col-sm-12 form-group mb-1">
                            <label for="type">
                                Increment Type <span class="text-danger">*</span>
                            </label>
                            <select v-model="type" id="type" class="border-page-color selectPicker form-control" data-live-search="false" required>
                                <option value="%">%</option>
                                <option value="$">$</option>
                            </select>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label for="attphoto">Product Image</label>
                            <div class="w-100">
                                <input id="attphoto" @change="changeImg('photo',$event)" type="file">
                            </div>
                            <div>
                                <button @click="resetImg('photo')" v-if="pho" title="Reset Photo" class="border-0 rounded outline-0 float-right btn-outline-dark"><i class="fas fa-redo-alt"></i></button>
                                <img v-if="phoo" :src="photo" class="my-2 w-25 rounded">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-system btn-xs">
                        <i class="fa fa-save"></i> Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" 
                        @click="triggerReset()">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>  
</template> 
<script>
import { frm_submit } from '@/networks/services/helper/button'
import { user } from '@/networks/services/auth/user.service'

export default {
    props : {
        product_id: {
            type: [String, Object , Number],
            default: null,
        },
        attributes : {
            type : [ Object ],
            default : null
        }
    },
    data(){
        return {
            tbl : "pos_product_attributes",
            per : "pos_product",
            att_id : '',
            name : '',
            value : '#000000',
            increment : '',
            type : '$',
            photo : '',
            pho: false,
            phoo: null,
            isShow : false,
        }
    },
    methods: {
        triggerReset(){
            this.photo = '';
            this.pho = false;
            this.phoo = null;
            this.att_id = '';
            this.isShow = false;
            this.increment = '';
            this.type = '$';
            this.name = '';
            this.value = '#000000';
            $('#attphoto').val('');
        },
        resetImg(value){
            if(value == "photo"){
                this.photo = '';
                this.pho = false;
                this.phoo = null;
                $('#attphoto').val('');
            }
        },
        changeImg(value,obj){
            if(value == "photo"){
                this.photo = URL.createObjectURL(obj.target.files[0]);
                this.phoo = obj.target.files[0];
                this.pho = true;
            }
        },
        changeAtt(){
            this.isShow = false;
            if(this.att_id == 1){
                this.isShow = true;
            }
        },
        async form_submit(){
            let com_id = user('company_id');
    
            var data = new FormData();
            data.append('photo',this.phoo);
            data.append('company_id',com_id);
            data.append('pos_product_id',this.product_id);
            data.append('attribute_id',this.att_id);
            data.append('type',this.type);
            data.append('value',this.isShow ? this.value : this.name);
            data.append('increment',this.increment)
            data.append('tbl',this.tbl);
            data.append('per',this.per);

            await frm_submit(data);
            $('#createModalAttribute').modal('hide');
            let result = {
                value : $('#create_status').val(),
                photo : this.photo
            };
            this.triggerReset();
            this.$emit("updateModal",result);
        },
    },
    mounted(){
        $(document).ready(function() {
            $('.chosen2').select2();
        });
    }
}
</script>
