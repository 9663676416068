<template>
<div class="modal fade" id="submitOrderModal" tabindex="-1" role="dialog" aria-labelledby="submitOrderModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form action="ohyes" method="POST" id='submitOrder' @submit.prevent="form_submit()">
            <!-- <input id="create_status" type="hidden" value="error"> -->
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Confirm Order</strong>
                    <button @click="triggerReset(false)" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <h5 class="text-danger" v-if="exchangeRate"><i>${{ exchangeRate.usd }} = {{ exchangeRate.khr }}៛</i></h5>
                    <div class="form-group mb-1">
                        <label for="paidStatus">
                            Paid Status
                        </label>
                        <!-- <input type="text" value="Paid" class="form-control" disabled> -->
                        <select v-model="paidStatus" @change="selectPaidStatus()" id="paidStatus" class="border-page-color selectPickerInvoice form-control txt-kh" data-live-search="false" required>
                            <option value="1">Paid</option>
                            <option value="2">Unpaid</option>
                            <option value="3">Due</option>
                        </select>
                    </div>
                    <template v-if="paidStatus != '' && paidStatus != 2">
                        <div class="form-group mb-1">
                            <label for="paid">
                                Total
                            </label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input v-model="confirmInfo.amount" type="text" id="paid" class="form-control" disabled>
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-system text-white">ឬ</span>
                                </div>
                                <input v-model="confirmInfo.amount_khr" type="text" id="paid" class="form-control" disabled>
                                <div class="input-group-prepend">
                                    <span class="input-group-text">៛</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="paidStatus == 3" class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group mb-2">
                                    <label for="moneyPaidDollar">
                                        Paid Amount ($) <span class="text-danger">*</span>
                                    </label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input v-model="moneyPaid" @input="paid()" type="number" step="0.001" min="0" id="moneyPaidDollar" class="form-control" required>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group mb-2">
                                    <label for="debt">
                                        Debt Amount ($)
                                    </label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input v-model="debt" type="number" step="0.001" id="debt" class="form-control" disabled>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- <h5 class="text-danger mb-0 pb-0">Please Enter Real Data *</h5>
                        <small class="text-danger">( <i>It will show in the invoice</i> )</small> -->
                        <div class="form-group mb-1 mt-1">
                            <label for="amountDollar">
                                Amount Received <span class="text-danger">*</span>
                            </label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input v-model="amount_usd" type="number" step="0.001" min="0" id="amountDollar" class="form-control" required>
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-system text-white">និង</span>
                                </div>
                                <input v-model="amount_khr" type="number" step="0.001" min="0" id="amountDollar" class="form-control" required>
                                <div class="input-group-prepend">
                                    <span class="input-group-text">៛</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-1">
                            <label for="extra_amount">
                                Change Amount <span class="text-danger">*</span>
                            </label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input v-model="extra_amount_usd" type="number" step="0.001" min="0" id="extra_amount" class="form-control" required>
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-system text-white">និង</span>
                                </div>
                                <input v-model="extra_amount_khr" type="number" step="0.001" min="0" id="extra_amount" class="form-control" required>
                                <div class="input-group-prepend">
                                    <span class="input-group-text">៛</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-1">
                            <label for="photo">
                                Photo
                            </label>
                            <div class="w-100">
                                <input id="photo" @change="changeImg($event)" type="file">
                                <button type="button" @click="resetImg('photo')" v-if="pho" title="Reset Photo" class="border-0 rounded outline-0 float-right btn-outline-dark"><i class="fas fa-redo-alt"></i></button>
                                <img v-if="phoo" :src="photo" class="my-2 w-25 rounded">
                            </div>
                        </div>
                        <div class="form-group mb-1">
                            <label for="change">
                                Note
                            </label>
                            <textarea v-model="note" type="text" id="note" class="form-control"></textarea>
                        </div>
                    </template>
                    <div v-if="paidStatus == 2" class="form-group mb-1">
                        <label for="change">
                            Note
                        </label>
                        <textarea v-model="note" type="text" id="note" class="form-control"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button :disabled="isCreate" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isCreate" src="../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Confirm Order
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" @click="triggerReset(false)">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import {
    config
} from '@/networks/config'
import {
    store
} from '@/networks/services/pos/AddSalePos.service'

export default {
    props: {
        productInvoices: {
            type: [Object, Array],
            default: []
        },
        invoiceDatails: {
            type: [Object, Array],
            default: []
        },
        customerInfo: {
            type: [Object],
            default: {}
        },
        exchangeRate: {
            type: [Object],
            default: {}
        },
        paymentMethodInfo: {
            type: [Object],
            default: {}
        },
        confirmInfo: {
            type: [Object],
            default: {}
        }
    },
    data() {
        return {
            tbl: "invoices",
            per: "sale_pos",
            isCreate: false,
            photo: '',
            pho: false,
            phoo: null,
            amount_usd: 0,
            amount_khr: 0,
            extra_amount_usd: 0,
            extra_amount_khr: 0,
            note: '',
            paidStatus: 1,
            moneyPaid: '',
            debt : 0
        }
    },
    methods: {
        paid(){
            if(Number(this.moneyPaid) > Number(this.confirmInfo.amount)){
                this.moneyPaid = this.confirmInfo.amount
            }
            this.debt = (Number(this.confirmInfo.amount) - Number(this.moneyPaid)).toFixed(2);
        },
        async loadSelectOption() {
            await this.$nextTick();
            $('.selectPickerInvoice').selectpicker('refresh');
            $('.selectPickerInvoice').selectpicker();
        },
        triggerReset(check = true) {
            if(check){
                this.paidStatus = 1;
            }
            this.debt = 0;
            this.moneyPaid = '';
            this.amount_usd = 0;
            this.amount_khr = 0;
            this.extra_amount_usd = 0;
            this.extra_amount_usd = 0;
            this.note = '';
            this.photo = '';
            this.pho = false;
            this.phoo = null;
            this.isCreate = false;
            this.resetImg("photo");
            this.loadSelectOption();
        },
        selectPaidStatus() {
            this.triggerReset(false);
            if(this.paidStatus == 3){
                this.debt = this.confirmInfo.amount;
            }
        },
        resetImg(value) {
            if (value == "photo") {
                this.photo = '';
                this.pho = false;
                this.phoo = null;
                $('#photo').val('');
            }
        },
        changeImg(obj) {
            this.photo = URL.createObjectURL(obj.target.files[0]);
            this.phoo = obj.target.files[0];
            this.pho = true;
        },
        async form_submit() {
            config();

            var data = new FormData();
            data.append('paid_status', this.paidStatus);
            data.append('productInvoices', JSON.stringify(this.productInvoices));
            data.append('invoiceDatails', JSON.stringify(this.invoiceDatails));
            data.append('customerInfo', JSON.stringify(this.customerInfo));
            data.append('exchangeRate', JSON.stringify(this.exchangeRate));
            data.append('paymentMethodInfo', JSON.stringify(this.paymentMethodInfo));
            data.append('amount_receive_usd', this.amount_usd);
            data.append('amount_receive_khr', this.amount_khr);
            data.append('extra_amount_usd', this.extra_amount_usd);
            data.append('extra_amount_khr', this.extra_amount_khr);
            data.append('moneyPaid', this.moneyPaid);
            data.append('mustPaidUsd', this.confirmInfo.amount);
            data.append('mustPaidKhr', this.confirmInfo.amount_khr);
            data.append('debt',this.debt);
            data.append('note', this.note);
            data.append('photo', this.phoo);

            this.isCreate = true;
            const result = await axios
                                .post(`${store}`,data)
                                .catch(error => {
                                    console.log(error.response);
                                });
            this.isCreate = false;

            if(result.data.status == "success"){
                $('#submitOrder').modal('hide');
                $("#submitOrder")[0].reset();
                this.triggerReset();
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
                this.$emit("confirmInvoiceOrder",result.data.data);   
            } else if(result.data.status == "401"){
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
                localStorage.removeItem('posUser');
                this.$router.push('/Unauthenticated');
            } 
            else if(result.data.status == "is_permission_no"){
                alertify.set('notifier','position', 'top-right');
                alertify.error(`You are no permission to do this stuff!`);
            } 
            else if(result.data.status == "error"){
                alertify.set('notifier','position', 'top-right');
                alertify.error(`${result.data.message}`); 
            } else {
                console.log(result)
            } 
        },
    },
    mounted() {
        $(document).ready(function () {
            $('.chosen2').select2();
        });
    }
}
</script>
