<template>
    <transition v-if="!checkNavBar" name="fade">
        <div class="loader flex-column justify-content-center align-items-center">
            <div class="loading loading-child">{{$t('loading...')}}</div>
        </div>
    </transition>
 
    <div class="wrapper">
        <Navbar 
            @changeService="JustChangeService($event)" 
            :services="services"
            :service="service"
            @getService="getTheService($event)"
        />
        <Sidebar 
            v-if="checkNavBar"
            :service="service"
            :navBar="navBar"
            :checkNavBar="checkNavBar"
            :companyInfos="companyInfos"
            :userInfos="userInfos"
        />
        
        <div class="content-wrapper position-relative">
            <router-view></router-view>
        </div>

        <Footer></Footer>

        <aside class="control-sidebar control-sidebar-dark"></aside>
    </div>
</template>

<script>
    import Navbar from "./inc/Navbar.vue"
    import Sidebar from "./inc/Sidebar.vue"
    import Footer from './inc/Footer.vue'
    import LoginView from '../views/auth/LoginView.vue'
    import { user } from "@/networks/services/auth/user.service"
    import { config } from "@/networks/config"
    import axios from "axios"
    import { getComService } from "@/networks/services/dashboard/company.service"
    import { path } from "@/networks/services/dashboard/path.service"
   
    export default {
        components: {
        Navbar,
        Sidebar,
        Footer,
        LoginView,
    },
    data(){
        return {
           islog : true,
           service : 0,
           services : [],
           navBar : [],
           checkNavBar : false,
           companyInfos : {},
           userInfos : {}
        }
    },
    methods : {
        async seleteOption(){
            await this.$nextTick();
            $('.selectPicker').selectpicker('refresh');       
            $('.selectPicker').selectpicker(); 
        },
        loading(){
            setTimeout(function(){
                this.islog = false;
            }, 2000);
        },
        JustChangeService(event){
            this.service = event;
        },
        getTheService(value){
            this.service = value;
        },
        checkPath(){
            if(this.$route.path == '/'){
                this.service = user('company_id') == 0 ? this.service = 0 : this.services[0].id;
            } else {
                const param = path(this.$route.params.id).filter(item => item.link == this.$route.path)[0];

                if(param){
                    if(param.default == 0 && user('company_id') == 0){
                        this.service = 0;
                    } else if(param.default == 0 && user('company_id') != 0){
                        this.service = this.services[0].id;
                    } else {
                        this.service = param.system_id;
                    }
                } else {
                    this.service = user('company_id') == 0 ? this.service = 0 : this.services[0].id;
                }
            }
        },
        async getData(){
            config();
            let res = await axios.get(getComService).catch(err => console.log(err));
            if(res.status == 200){
                if(res.data.status == 'success'){
                    this.services = res.data.data;
                    this.navBar = res.data.navBar;
                    this.checkNavBar = true;
                    this.companyInfos = res.data.companyInfo;
                    this.userInfos = res.data.userInfo;

                    this.checkPath();
                } else if(res.data.status == '401'){
                    alertify.set('notifier','position', 'top-right');
                    alertify.error("Unauthenticated");
                    localStorage.removeItem('posUser');
                    this.$router.push('/Unauthenticated');
                } else{
                    console.log(result);
                }
            }
        }
    },
    async created(){
        if(localStorage.posUser){
            await this.getData().then(()=>{
                this.seleteOption();
            });
        } else {
            this.$router.push('/Unauthenticated');
        }
    },  
    mounted() {

        // const plugin = document.createElement("script");
        // plugin.setAttribute(
        //     "src",
        //     "./assets/js/custom.js"
        // );
        // plugin.async = true;
        // document.head.appendChild(plugin);
    },
}
</script>