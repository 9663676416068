<template>
  <div class="card">
    <div class="card-header">
      <button v-if="btnCreate" class="btn btn-system btn-sm mb-2 mr-2" data-toggle='modal' data-target='#createModalAttribute' @click="create()"><i class="fa fa-plus-circle"></i> Add New Attribute</button>
      <span class="badge badge-info float-right">{{$t("product_attribute")}}</span>
    </div>
    <div class="card-body">
      <!-- no data -->
      <div v-if="!product_attributes.length" class="card-body text-center">
        <span class="txt-system">No Data...</span>
      </div>
      <div v-else class="row">
        <!-- Processing loader  -->
        <div v-if="pageLoad" class="card position-absolute " style="z-index:10; left: 45%; top: 50%;">
          <div class="card-body">
              <span class="txt-system">Processing...</span>
          </div>
        </div>
        <div  class="table-responsive-sm position-relative">
            <template v-for="item in attributes" :key="item.id">
              <template v-if="product_attributes.find(element => element.attribute_id === item.id)">
                <h5><b><i>{{ item.name }}</i></b></h5>
                <table class="table data-table table-sm table-bordered" style="width: 100%">
                  <thead class="bg-system text-light">
                    <tr>
                      <th class="d-none"></th>
                      <th style="width:50px">#</th>
                      <th>Value</th>
                      <th>Increment</th>
                      <th style="width:80px">photo</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in product_attributes" :key="data.id" :id="`tr${data.id}table`">
                      <template v-if="data.attribute_id == item.id">
                        <td class="d-none"></td>
                        <td>{{index + 1}}</td>
                        <td :data-col="`#`+ (index + 1) + `. Value`"
                          v-if="data.attribute_id != 1"
                        >{{ data.value }}
                          <button :data-row = "`tr${data.id}table`" onclick="toggleRow(this)" class="clicker btn btn-xs bg-system text-white rounded-circle">
                            <i class="fas fa-plus-circle"></i>
                          </button>
                        </td>
                        <td :data-col="`#`+ (index + 1) + `. Value`"
                          v-else
                        > <span class="btn btn-sm rounded-circle" :style="`background-color: ${data.value}; height:20px; width:20px;`">&nbsp;</span>
                          <button :data-row = "`tr${data.id}table`" onclick="toggleRow(this)" class="clicker btn btn-xs bg-system text-white rounded-circle">
                            <i class="fas fa-plus-circle"></i>
                          </button>
                        </td>
                        <td data-col="Increment">{{ data.increment + ' ' +data.type}}</td>
                        <td data-col="Photo">
                          <img v-if="data.photo" :src="resource + data.photo" alt="Photo of Product" style="width: 30px; height:30px;" class="rounded">
                          <span v-else>&nbsp;</span>
                        </td>
                        <td data-col="Action">
                          <button v-if="btnEdit" type='button' @click="btnEditAttribute(data.id)"  :button="`edit${data.id}`"
                              class='btn btn-success btn-xs mr-1' title="edit">
                              <img :id="`editImg${data.id}`" class="d-none" src="../../assets/sniper.jpeg" alt="" width="12">
                              <i :id="`editFa${data.id}`" class='fa fa-edit'></i>
                          </button>
                          <button v-if="btnDelete" type="button" @click="alertRemove(data.id,'single')" 
                              class='btn btn-danger btn-xs' title='delele'>
                              <img :id="`deleteImg${data.id}`" class="d-none" src="../../assets/sniper.jpeg" alt="" width="12">
                              <i :id="`deleteFa${data.id}`" class='fa fa-trash'></i>
                          </button>
                          <input :id="`hiddenDelete${data.id}`" type="hidden" @click="btnDeleteAttribute(data.id)">
                        </td>
                      </template>
                    </tr>    
                  </tbody>
                </table>
              </template>
            </template>
        </div>
      </div>
    </div>
  </div>
  <createVue 
    v-if="btnCreate" 
    :product_id="product.id" 
    :attributes="attributes" 
    @updateModal="JustUpdateModal($event)"
  />
  <editVue 
    v-if="btnEdit" 
    :product_id="product.id" 
    :attributes="attributes" 
    :edata="edata" 
    :eisShow="eisShow" 
    :ephoto="ephoto" 
    :ephoo="ephoo"
    :epho="epho"
    @edataAtt="edataAtt($event)"
    @editPhoto="editPhoto($event)"
    @resetPhoto="resetPhoto()"
    @editAttribute="editAttribute($event)"
  />
</template>

<script>
import axios from 'axios';
import { config } from '@/networks/config';
import { attribute } from '@/networks/services/pos/productAttribute.service'
import createVue from './create.vue';
import editVue from './edit.vue';
import { resource } from '@/networks/domain';
import { remove } from '@/networks/services/helper/button.js'
export default {
  emits: ["updateDataAttribute"],
  components : {
    createVue, editVue
  },
  props: {
    btnBack : { Boolean },
    pageLoad : { Boolean },
    btnDelete : { Boolean },
    btnEdit : { Boolean },
    btnCreate : { Boolean },
    product : { 
      type: [String, Object, Array],
      default: null, 
    },
    attributes : { 
      type: [String, Object, Array],
      default: null, 
    },
    product_attributes : { 
      type: [String, Object, Array],
      default: null, 
    },
  },
  data(){
    return {
      resource : resource,
      edata : [],
      eisShow : false,
      ephoto : '',
      ephoo : false,
      epho : false,
      eImageDefault : ''
    }
  },
  methods : {
    async create(){
      await this.$nextTick();
      $('.selectPicker').selectpicker('refresh');       
      $('.selectPicker').selectpicker(); 
    },
    convertToRoman(num) {
      var roman = '';
      var decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
      var romanNum = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I'];
      for (var i = 0; i < decimal.length; i++) {
          while (decimal[i] <= num) {
              roman += romanNum[i];
              num -= decimal[i];
          }
      }
      return roman;
    },
    JustUpdateModal(event){
      this.$emit("updateDataAttribute",event); 
    },
    editAttribute(event){
      this.$emit("updateDataAttribute",{
        value : event
      })
    },
    alertRemove(id,type=null){
      alertify.confirm('Delete', 'Are you sure to do this stuff ?', function(){ 
        if(type == "single"){
          $(`#hiddenDelete${id}`).trigger("click");
        }
        if(type == "plural"){
          $(`#${id}`).trigger("click");
        }
      }
      , function(){ });
    },
    async btnDeleteAttribute(id){
      let obj = {
        tbl : 'pos_product_attributes',
        per : 'pos_product',
      };

      let event =  await remove(id,obj);
      if(event == "success"){
        this.JustUpdateModal({
          value : "success"
        });
      } 
      if(event == "is_permission_no"){
        this.JustUpdateModal({
          value : "is_permission_no"
        });
      }
    },
    btnEditAttribute(id){
      $(`[button=edit${id}]`).prop("disabled", true);
      $(`#editImg${id}`).toggleClass('d-none');
      $(`#editFa${id}`).toggleClass('d-none');
      $('#editModalAttribute').on('hidden.bs.modal', function () {
        $(this).find('form').trigger('reset');
      })

      config();
      axios.get(attribute(id))
           .then(response => {
              this.edata = response.data.data;
              this.eisShow = this.edata.attribute_id == 1 ? true : false;
              this.ephoto = this.eImageDefault = this.edata.photo ? resource + this.edata.photo : '';
              this.ephoo = this.edata.photo != '' ? true : false;
              this.create();

              $(`#editImg${id}`).toggleClass('d-none');
              $(`#editFa${id}`).toggleClass('d-none');
              $(`[button=edit${id}]`).prop("disabled", false);
              $('#editModalAttribute').modal();
              $('#eidattribute').val(response.data.data.id);
            })
           .catch(error => {
              console.log(error)
           })
    },
    edataAtt(event){
      this.eisShow = false;
      if(event == 1){
        this.eisShow = true;
      }
    },
    editPhoto(obj){
      this.ephoto = URL.createObjectURL(obj.target.files[0]);
      this.ephoo = obj.target.files[0];
      this.epho = true;
    },
    resetPhoto(){
      this.ephoto = this.eImageDefault;
      this.epho = false;
      this.ephoo = '';
    }
  }
}
</script>

<style>

</style>