import { domain } from "../../../domain";

export {
    stockIn
}

function stockIn(selectDate,fromDate,toDate){
    return `${domain}/pos/pos-report-stockIn?selectDate=${selectDate}&fromDate=${fromDate}&toDate=${toDate}`;
}


