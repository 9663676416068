<template>
<div @click="triggerReset()" class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form action="uytyut" method="POST" id='edit_form' @submit.prevent="form_update($event)">
            <input type="hidden" name="id" id='eid' v-model="edata.id">
            <input id="edit_status" type="hidden">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Edit Expense</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group mb-1">
                        <label>
                            Expense Category <span class="text-danger">*</span>
                        </label>
                        <template v-if="expense_categories">
                            <select v-model="edata.expense_category_id" id="product" class="border-page-color selectPicker form-control" data-live-search="true" required>
                                <option value="">PLEASE SELECT ONE</option>
                                <option :value="expense.id" v-for="expense in expense_categories" :key="expense.id">{{ expense.name }}</option>
                            </select>
                        </template>
                    </div>
                    <div class="form-group mb-1">
                        <label>
                            Expense Date <span class="text-danger">*</span>
                        </label>
                        <input v-model="edata.expense_date" type="date" class="form-control" required>
                    </div>
                    <div class="form-group mb-1">
                        <label>
                            Amount <span class="text-danger">*</span>
                        </label>
                        <input v-model="edata.amount" type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" required>
                    </div>
                    <div class="form-group mb-1">
                        <label>
                            Description / Note
                        </label>
                        <input v-model="edata.description" type="text" class="form-control">
                    </div>
                    <div class="form-group mb-1">
                        <label for="">Image</label>
                        <div class="w-100">
                            <input id="ephoto" @change="changeImg('photo',$event)" type="file">
                            <button @click="resetImg('photo')" type="button" v-if="pho" title="Reset Photo" class="border-0 rounded outline-0 float-right btn-outline-dark"><i class="fas fa-redo-alt"></i></button>
                            <img v-if="phoo" :src="photo" class="my-2 w-25 rounded-circle">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" id="update" class="btn btn-system btn-xs">
                        <img v-if="isUpdate" src="../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Update
                    </button>
                    <button @click="triggerReset()" type="button" class="btn btn-danger btn-xs" data-dismiss="modal">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

    
    
<script>
import {
    frm_update
} from '@/networks/services/helper/button.js'

export default {
    props: {
        expense_categories: {
            type: [Object, Array],
            default: []
        },
        photo: {
            type: [String, Object, Boolean],
            default: null
        },
        edata: {
            type: [String, Object, Boolean],
            default: []
        },
        phoo: {
            type: [String, Object, Boolean],
            default: null
        },
        pho: {
            type: [String, Object, Boolean],
            default: null
        }
    },
    data() {
        return {
            tbl: "expenses",
            per: "expense",
            isUpdate : false,
        }
    },
    methods: {
        triggerReset() {
            $('#editModal').on('hidden.bs.modal', function () {
                $(this).find('form').trigger('reset');
            })
        },
        resetImg(value) {
            if (value == "photo") {
                $('#ephoto').val('');
                this.$emit("resetImg");
            }
        },
        changeImg(value, obj) {
            if (value == "photo") {
                this.$emit("changeImg", obj);
            }
        },
        async form_update() {
            this.isUpdate = true;
            $('#update').prop('disabled', true);


            let data = new FormData();
            data.append('id', this.edata.id);
            data.append('tbl', this.tbl);
            data.append('per', this.per);
            data.append('expense_category_id', this.edata.expense_category_id);
            data.append('expense_date', this.edata.expense_date);
            data.append('amount', this.edata.amount);
            data.append('photo',this.phoo);
            data.append('description', this.edata.description ? this.edata.description : '');
            await frm_update(data);
            this.isUpdate = false;
            $('#update').prop('disabled', false);
            this.$emit("editModal", $('#edit_status').val());
        },
    },
    mounted() {
        $(document).ready(function () {
            $('.chosen2').select2();
        });
    }
}
</script>
    
    
<style>
    
    </style>
