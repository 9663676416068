<template>
  <content-header title="Dashboard" icon="fas fa-home"/>
  <content>
    <loading v-if="is_show"/>
    <div v-else class="container-fluid">
      <div class="row" v-if="company_id != 0"> 
        <sale-card v-if="add_sale"/>
        <recent-report-card v-if="view_invoice"/>
        <template v-for="data in datas" :key="data">
          <infomation-card :title="data.title" :icon="data.icon" :info="data.info" :bg="data.bg"/>
        </template>
        <!-- <router-link :to="{ name: 'detail', params: { id: 'hello word' }}" class="btn btn-success">Go to Detail page with param</router-link> -->
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row mb-5">
                <div class="col-12">
                  <Bar
                    id="my-chart-id"
                    :options="chartOptions"
                    :data="chartData"
                  />
                </div>
              </div>
              <!-- <h5>Recent Invoice</h5> -->
              <!-- table  -->
              <!-- <div class="table-responsive position-relative">
                <table class="table data-table table-sm table-bordered position-relative" style="width: 100%">
                  <thead class="bg-system text-light">
                    <tr>
                      <th style="width:50px" class="d-none"></th>
                      <th style="width:50px" >#</th>
                      <th>Code</th>
                      <th>Customer</th>
                      <th>Date</th>
                      <th>Total USD</th>
                      <th>Sale Status</th>
                      <th>Payment Status</th>
                    </tr>
                  </thead>
                  <tbody v-if="table.data.length">
                    <tr v-for="(data, index) in table.data" :key="data.id" :id="`tr${data.id}table`">
                      <td class="d-none"></td>
                      <td>{{index + 1}}</td>
                      <td :data-col="`#`+ (index + 1) + `. Code`">{{ data.code }}
                        <button :data-row = "`tr${data.id}table`" onclick="toggleRow(this)" class="clicker btn btn-xs bg-system text-white rounded-circle">
                          <i class="fas fa-plus-circle"></i>
                        </button>
                      </td>
                      <td data-col="Customer">{{ data.cus_name }}</td>
                      <td data-col="Date">{{ new Date(data.date).toLocaleDateString("en-AU", { day : 'numeric', 'month' : 'numeric' , 'year' : 'numeric', 'hour' : 'numeric', "minute" : "numeric" }) }} </td>
                      <td data-col="Total USD">$ {{ data.total_usd }}</td>
                      <td data-col="Sale Status">
                          <span class="rounded btn btn-xs text-white" 
                              :class="
                                  data.sale_status == 1 ? 'bg-system' :
                                  data.sale_status == 2 ? 'bg-danger' :
                                  'bg-info'
                              "
                              style="cursor:auto;"
                          >
                              {{
                                  data.sale_status == 1 ? 'Completed' :
                                  data.sale_status == 2 ? 'Voided' :
                                  'changed'
                              }}
                          </span>
                      </td>
                      <td data-col="Payment Status">
                          <span class="rounded btn btn-xs text-white" 
                              :class="
                                  data.paid_status == 1 ? 'bg-dark' :
                                  data.paid_status == 2 ? 'bg-danger' :
                                  'bg-warning'
                              "
                              style="cursor:auto;"
                          >
                              {{
                                  data.paid_status == 1 ? 'Paid' :
                                  data.paid_status == 2 ? 'UnPaid' :
                                  'Due'
                              }}
                          </span>
                      </td>
                    </tr>    
                  </tbody>
                </table>
              </div> -->
            </div>
          </div>
          <!-- Processing loader  -->
            
          <!-- <div v-if="is_true" class="card position-absolute " style="z-index:10; left: 45%; top: 50%;">
              <div class="card-body">
              <span class="txt-system">Processing...</span>
              </div>
          </div> -->

          <!-- no data -->
          <!-- <div v-if="!table.data.length" class="card">
              <div class="card-body text-center">
                <span class="txt-system">No Data...</span>
              </div>
          </div> -->
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-12">
          <h3 class="txt-system">Welcome to Admin Page</h3>
        </div>
      </div>
    </div>
  </content>
  <content-header/>
</template>

<script>
import ContentHeader from '@/components/ContentHeader.vue'
import axios from 'axios';
import Loading from '@/components/loading/Loading.vue'
import SaleCard from '@/components/card/SaleCard.vue'
import RecentReportCard from '@/components/card/RecentReportCard.vue'
import InfomationCard from '@/components/card/InfomationCard.vue';
import { home } from '../../networks/services/pos/home.service.js'
import { config } from '../../networks/config.js'
import { checkPermission } from '@/networks/services/helper/helper.js';
import { user } from '@/networks/services/auth/user.service';
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  components: { ContentHeader, Loading , SaleCard, RecentReportCard, InfomationCard, Bar},
  data(){
    return {
      chartData: {
        labels: [ 'January', 'February', 'March' , 'April', 'May' , 'June' , 'July', 'August' , 'September', 'October', 'November', 'December' ],
        datasets: [{ 
          label: 'Total sale for each month in year ' + new Date().getFullYear(),
          backgroundColor: localStorage.color ? localStorage.color : '#f87979',
          data: [2, 22, 12, 12, 12, 5, 20, 12, 2, 3, 4, 5] 
        }]
      },
      chartOptions: {
        responsive: true
      },
      start : 1,
      limit : 10,
      status : false,
      datas : [
        {
          title : 'Total Sale Today',
          icon : 'ion ion-stats-bars',
          info : '0',
          bg : 'bg-success'
        },
        {
          title  : 'All Total Sale',
          icon : 'fas fa-globe',
          info : '0',
          bg : 'bg-danger'
        }
      ],
      company_id : 0,
      add_sale : 0,
      view_invoice : 0,
      table : {
        page: 1,
        totalIndexPage: 0,
        totalItem: 0,
        per_page: 10,
        from_index:0,
        to_index:0,
        data: [],
        search: null,
        orderBy: 'DESC'
      },
      search: null,
      is_true : false,
      is_show : true,
    }
  },
  beforeMount(){
    if(!localStorage.getItem('posUser')){
        this.$router.push('/login');
    }
  },
  methods : {
      
    pad(num, size) {
        if(num == 0) return num;
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    },

    async getData(){
      if(localStorage.getItem('posUser')){
        config();
        var stt = true;
        const RES = await axios
                          .get(`${home(this.start,this.limit)}`)
                          .catch(function (err){
                            if(err.response){
                              if(!err.response.date){
                                stt = false;
                              } 
                            }
                            console.log(err.response)
                          });
        if(stt){
          if(RES.data.status === 'success'){
            this.is_true = false;
            this.add_sale = RES.data.salePos;
            this.view_invoice = RES.data.report;
            this.datas[1].info = this.pad(RES.data.total_sales,2);
            this.datas[0].info = this.pad(RES.data.total_recent_sales,2);
            this.status = true;

            this.is_show = false;

            //table
            this.table.data = RES.data.recent_sales; 

            //chart js
            this.chartData.datasets[0].data = RES.data.totalInvoiceEachMonth;
          }
          else if(RES.data.status === 401) {
            localStorage.removeItem('posUser');
            this.$router.push('/Unauthenticated');
          } else {
            alert('something wrong with api');
          }
        } else {
          localStorage.removeItem('posUser');
          this.$router.push('/server-down')
        }
      } 
      // else {
      //   this.$router.push('/Unauthenticated')
      // }
    },
    async check(){
      await checkPermission("sale_pos",'l');
      this.add_sale = 1;
    }
  },
  mounted(){
    // this.check();
    if(localStorage.getItem('posUser')){
      this.company_id = user('company_id');
      if(user('company_id') != 0){
        this.getData()
      } else {
        this.is_show = false;
      }
    }
  }
}
</script>

<style>
</style>