import { domain } from "../../../domain";

export {
    invoice
}

function invoice(selectDate,fromDate,toDate){
    return `${domain}/pos/pos-report-invoice?selectDate=${selectDate}&fromDate=${fromDate}&toDate=${toDate}`;
}


