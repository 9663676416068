<template>

    <div class="page404">
      <div class="container mt-xl-5 bg-white">
        <button class="btn btn-danger mb-3" @click="$router.push('/')">Back</button>
        <h1 class="text-black">Page Not Found .</h1>

        <div class="card">
          <div class="card-body">
          <div class="error-page">
              <h2 class="headline text-danger">404</h2>
              <div class="error-content">
                  <h3>
                      <i class="fa fa-exclamation-triangle text-danger"></i> 
                      Oops! Page not found.
                  </h3>
                  <p class="txt-kh">
                      ផ្នែកដែលលោកអ្នកកំពុងរក មិនមានក្នុងប្រព័ន្ធទេ!
                  </p>
                  <p>
                      The page you are looking for is not found, please contact your system administrator!
                  </p>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
export default {

  created(){
    // if(localStorage.getItem('posUser')){
    //   this.$router.go(-1);
    // } else {
    //   window.history.back();
    // }
  },
}
</script>

<style>

</style>

