<template>
    <div class="modal fade" id="editModalAttribute" tabindex="-1" role="dialog" aria-labelledby="editModalAttribute" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <form action="uytyut" method="POST" id='editModalAttribute' @submit.prevent="form_update($event)">
              <input type="hidden" id='eidattribute' value="">
              <input id="edit_status" type="hidden">
              <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Edit Role</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-sm-12 form-group mb-1">
                            <label for="eatt_id">
                                Attribute <span class="text-danger">*</span>
                            </label>
                            <select v-model="edata.attribute_id" id="eatt_id" class="border-page-color selectPicker form-control" data-live-search="true" required
                                @change="changeAtt()"
                            >
                                <option value="">PLEASE SELECT ONE</option>
                                <option :value="attribute.id" v-for="attribute in attributes" :key="attribute.id">{{ attribute.name }}</option>
                            </select>
                        </div>
                        <div v-if="!eisShow" class="col-lg-6 col-sm-12 form-group mb-1">
                            <label for="evalue">
                                Value <span class="text-danger">*</span>
                            </label>
                            <input type="text" id="evalue" v-model="edata.value" class="form-control" required>
                        </div>
                        <div v-if="eisShow" class="col-lg-6 col-sm-12 form-group mb-1">
                            <label for="ecolor">
                                Color <span class="text-danger">*</span>
                            </label>
                            <input type="color" id="ecolor" v-model="edata.value" class="form-control" required>
                        </div>
                        <div v-if="eisShow" class="col-lg-6 col-sm-12 form-group mb-1">
                            <label for="eval">
                                Value of Color <span class="text-danger">*</span>
                            </label>
                            <input type="text" id="eval" readonly :value="edata.value" class="form-control">
                        </div>
                        <div class="col-lg-6 col-sm-12 form-group mb-1">
                            <label for="eincrement">
                                Increment <span class="text-danger">*</span>
                            </label>
                            <input type="text" id="eincrement" v-model="edata.increment" class="form-control" required
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            >
                        </div>
                        <div class="col-lg-6 col-sm-12 form-group mb-1">
                            <label for="etype">
                                Increment Type <span class="text-danger">*</span>
                            </label>
                            <select v-model="edata.type" id="etype" class="border-page-color selectPicker form-control" data-live-search="false" required>
                                <option value="%">%</option>
                                <option value="$">$</option>
                            </select>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label for="eattphoto">Product Image</label>
                            <div class="w-100">
                                <input id="eattphoto" @change="changeImg('photo',$event)" type="file">
                            </div>
                            <div>
                                <button @click="resetImg('photo')" v-if="epho" title="Reset Photo" class="border-0 rounded outline-0 float-right btn-outline-dark"><i class="fas fa-redo-alt"></i></button>
                                <img v-if="ephoo" :src="ephoto" class="my-2 w-25 rounded">
                            </div>
                        </div>
                    </div>    
                </div>
                  <div class="modal-footer">
                    <button type="submit" class="btn btn-system btn-xs">
                        <i class="fa fa-save"></i> Update
                    </button>
                    <button @click="triggerReset()" type="button" class="btn btn-danger btn-xs" data-dismiss="modal">
                        <i class="fa fa-times"></i> Close
                    </button>
                  </div>
              </div>
          </form>
        </div>
    </div>  
    </template>
    
    <script>
    import { frm_update } from '@/networks/services/helper/button.js'
    
    export default {
        props : {
            product_id: {
                type: [String, Object , Number],
                default: null,
            },
            attributes : {
                type : [ Object ],
                default : null
            },
            edata : {
                type: [String, Object , Number],
                default: '',
            },
            ephoto : {
                type : [String],
                default : ''
            },
            eisShow : {
                type : [Boolean, String, Object],
                default : false
            },
            ephoo : {
                type : [Boolean , String , Object],
                default : false
            },
            epho : {
                type : [Boolean],
                default : false
            }
        },
        data(){
            return {
                tbl : "pos_product_attributes",
                per : "pos_product",
                att_id : '',
                name : '',
                value : '#000000',
                increment : '',
                type : '$', 
                photo : '',
                pho: false,
                phoo: null,
                isShow : false,
            }
        },
        methods: {  
            triggerReset(){
                $('#eattphoto').val('');
            },
            resetImg(value){
                if(value == "photo"){
                    $('#eattphoto').val('');
                    this.$emit("resetPhoto");
                }
            },
            changeImg(value,obj){
                if(value == "photo"){
                    this.$emit("editPhoto",obj);
                }
            },
            changeAtt(){
                this.$emit("edataAtt",this.edata.attribute_id);
            },
            async form_update(){
                let data = new FormData();
                data.append('id',$('#eidattribute').val())
                data.append('tbl',this.tbl)
                data.append('per',this.per)
                if(this.ephoo){
                    data.append('photo',this.ephoo);
                }
                data.append('attribute_id',this.edata.attribute_id);
                data.append('type',this.edata.type);
                data.append('value',this.edata.value);
                data.append('increment',this.edata.increment);

                await frm_update(data);
                $('#editModalAttribute').modal('hide');
                this.$emit("editAttribute",$('#edit_status').val());   
            },
        },
        mounted(){
            $(document).ready(function() {
                $('.chosen2').select2();
            });
        }
    }
    </script>
    
    <style>
    
    </style>