<template>
<div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <form action="uytyut" method="POST" id='edit_form' @submit.prevent="form_update($event)">
          <input type="hidden" name="id" id='eid' value="">
          <input id="edit_status" type="hidden">
          <div class="modal-content">
            <div class="modal-header bg-system">
                <strong class="modal-title text-white">Edit Permission</strong>
                <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="text-white" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
            <div id="esms">
            </div>
            <div class="form-group mb-1">
                <label>
                    Permission <span class="text-danger">*</span>
                </label>
                <input type="text" name="name" class="form-control" id='ename' required>
            </div>
            <div class="form-group mb-1">
                <label>
                    Name <span class="text-danger">*</span>
                </label>
                <input type="text" name="alias" class="form-control" id='ealias' required>
            </div>
                
            </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-system btn-xs">
                    <i class="fa fa-save"></i> Update
                </button>
                <button @click="triggerReset()" type="button" class="btn btn-danger btn-xs" data-dismiss="modal">
                    <i class="fa fa-times"></i> Close
                </button>
              </div>
          </div>
      </form>
    </div>
</div>  
</template>

<script>
import { frm_update } from '@/networks/services/helper/button.js'

export default {
    data(){
        return {
            tbl : "permissions",
            per : "permission"
        }
    },
    methods: {  
        triggerReset(){
            
        },
        async form_update(){
            let data = {
                id: $('#eid').val(),
                tbl : this.tbl,
                per : this.per,
                name : $('#ename').val(),
                alias : $('#ealias').val()
            };
            await frm_update(data);
            this.$emit("editModal",$('#edit_status').val());   
        },
    },
    mounted(){
        $(document).ready(function() {
            $('.chosen2').select2();
        });
    }
}
</script>

<style>

</style>