<template>
<div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <form action="uytyut" method="POST" id='edit_form' @submit.prevent="form_update($event)">
          <input type="hidden" name="id" id='eid' value="">
          <input id="edit_status" type="hidden">
          <div class="modal-content">
            <div class="modal-header bg-system">
                <strong class="modal-title text-white">Edit Product Category</strong>
                <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span class="text-white" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
            <div id="esms">
            </div>
            <div class="form-group mb-1">
                <label>
                    Name <span class="text-danger">*</span>
                </label>
                <input type="text" v-model="edata.name" class="form-control" id='ename' required>
            </div>
            <div class="form-group mb-1">
                <label for="com">
                    Main Category<span class="text-danger">*</span>
                </label>
                <select v-model="emain_cat" id="emain" @change="changeMainCat()" class="border-page-color selectPicker form-control" data-size="5" data-live-search="true" required>
                    <option value="">PLEASE SELECT ONE</option>
                    <option :value="main.id" v-for="main in mainCategories.filter(e => e.parent_id == 0)" :key="main.id">{{ main.name }}</option>
                </select>
            </div>
            <div class="form-group mb-1">
                <label for="com">
                    Sub Category<span class="text-danger">*</span>
                </label>
                <select v-model="edata.main_category_id" id="esub" class="border-page-color selectPicker form-control" data-size="5" data-live-search="true" required>
                    <option value="">PLEASE SELECT ONE</option>
                    <template v-if="esubCat.length > 0">
                        <option :value="esub.id" v-for="esub in esubCat" :key="esub.id">{{ esub.name }}</option>
                    </template>
                </select>
            </div>
                
            </div>
              <div class="modal-footer">
                <button :disabled="isUpdate" type="submit" class="btn btn-system btn-xs">
                    <img v-if="isUpdate" src="../../../assets/sniper.jpeg" alt="" width="12">
                    <i v-else class="fa fa-save"></i> Update
                </button>
                <button @click="triggerReset()" type="button" class="btn btn-danger btn-xs" data-dismiss="modal">
                    <i class="fa fa-times"></i> Close
                </button>
              </div>
          </div>
      </form>
    </div>
</div>  
</template>

<script>
import { frm_update } from '@/networks/services/helper/button.js'

export default {
    props : {
        edata : {
            type : [Object],
            default : null
        },
        mainCategories : {
            type : [Object, Array],
            default : null
        },
        emain_cat : {
            type : [String, Number],
            default : ''
        },
        esubCat : {
            type : [Object, Array],
            default : null
        }
    },
    data(){
        return {
            tbl : "company_categories",
            per : "company_category",
            isUpdate : false,
        }
    },
    methods: {  
        triggerReset(){
            this.isUpdate = false;
        },
        changeMainCat(){
            this.$emit("changeMainCat",this.emain_cat);
        },
        async form_update(){
            this.isUpdate = true;
            let data = {
                id: this.edata.id,
                tbl : this.tbl,
                per : this.per,
                main_category_id : this.edata.main_category_id,
                name : this.edata.name,
            };
            await frm_update(data);
            this.triggerReset();
            this.$emit("editModal",$('#edit_status').val());   
        },
    },
    mounted(){
        $(document).ready(function() {
            $('.chosen2').select2();
        });
    }
}
</script>

<style>

</style>