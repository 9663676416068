import { domain } from "../../domain";
import axios from "axios";
import { config } from '@/networks/config'
import router from "@/router";


export async function checkPermission(permission_name,action){
    if(localStorage.getItem('posUser')){
      config();
      const RES =  await axios
                        .get(`${domain}/pos/check-permission?permission_name=${permission_name}&action=${action}`)
                        .catch(function (err){
                          console.log(err);
                        });
      
      if(RES.data.status == "success"){
        return RES.data.data;
      }  else if(RES.data.status === 401) {
        alertify.set('notifier','position', 'top-right');
        alertify.error(`${RES.data.message}`);
        localStorage.removeItem('posUser');
        router.push('/Unauthenticated');
      } else {
        alert('something wrong with api');
      }
    } else {
      router.push('/Unauthenticated')
    }
  }