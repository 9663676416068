import { domain } from "../../domain";

export {
    invoicePos,
    voidInvoice,
    addPayment,
    updatePayment,
    deletePayment
}

function invoicePos(per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/pos-invoice?id=${id}`;
    } else if(name){
        return `${domain}/pos/pos-invoice?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}`
    } else {
        return `${domain}/pos/pos-invoice?per_page=${per_page}&page=${page}&orderBy=${orderBy}`;
    }
}
function voidInvoice(id=null){
    return `${domain}/pos/void-pos-invoice?id=${id}`;
}

const addPayment = `${domain}/pos/pos-invoice-add-payment`;
const updatePayment = `${domain}/pos/pos-invoice-update-payment`;
const deletePayment = `${domain}/pos/pos-invoice-delete-payment`;
