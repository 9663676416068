import { domain } from "../../domain";

export {
    permission,
    getSystem,
    createPermission,
    deletePermission
}

function permission(per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/get-permission?id=${id}`;
    } else if(name){
        return `${domain}/pos/get-permission?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}`
    } else {
        return `${domain}/pos/get-permission?per_page=${per_page}&page=${page}&orderBy=${orderBy}`;
    }
}
function deletePermission(id){
    return `${domain}/pos/delete-permission?id=${id}`; 
}
const getSystem = `${domain}/pos/get-system-to-permission`;
const createPermission = `${domain}/pos/insert-permission`;
