<template>
    <content-header title="Product Barcode" icon="fa fa-angle-double-right"/>
    <content>
      <loading v-if="isTrue"/>
      <div v-else class="container-fluid">
        <div class="card printNone">
          <!-- button action -->
          <div class="card-header">
            <button type="button" v-if="create && pro_name" class="btn btn-system btn-sm mb-2" @click="printWindow()"><i class="fa fa-print"></i> PRINT</button>
            <span v-if="pro_name">&nbsp;&nbsp;&nbsp;</span>
            <button @click="reloadData()" type="button" class="btn btn-warning btn-sm mb-2"><i class="fa fa-exchange-alt mr-1"></i>RELOAD DATA</button>
            &nbsp;      
            <button @click="refreshPage()" type="button" class="btn btn-outline-info btn-sm mb-2 float-right"><i class="fa-solid fa-arrow-rotate-left"></i> REFRESH PAGE</button>      
          </div>
          <div class="card-body">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <label for="pro">Product</label>
                    <select v-model="proId" id="pro" class="border-page-color selectPicker form-control" data-size="8"  data-live-search="true">
                        <option value="">PLEASE SELECT ONE</option>
                        <option :value="data.id" v-for="data in table.data" :key="data.id">{{ data.name }}</option>
                    </select>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <label for="qauntity">Qauntity</label>
                    <input v-model="qauntity" id="qauntity" type="number" class="form-control">
                </div>
                <div class="col-lg-3 col-sm-6 m-auto">
                    <label for="">&nbsp;</label>
                    <div class="custom-control custom-switch m-auto">
                        <input type="checkbox" :checked="show_name" class="custom-control-input m-auto" id="customSwitch1" @click="isCheckToOpen('show_name')">
                        <label class="custom-control-label m-auto" for="customSwitch1">Show Product Name ?</label>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 m-auto">
                    <label for="">&nbsp;</label>
                    <div class="custom-control custom-switch lg-m-auto">
                        <input type="checkbox" :checked="show_price" class="custom-control-input m-auto" id="customSwitch2" @click="isCheckToOpen('show_price')">
                        <label class="custom-control-label" for="customSwitch2">Show Unit Price ?</label>
                    </div>
                </div>
            </div>
          </div>
        </div>
            <h5>* Barcode Generate Here.</h5>
            <template v-if="isLoadBarcode">
                <div 
                    v-for="i in Number(qauntity)"
                    :key="i"
                    class="barcode" 
                    :product-name="show_name ? pro_name : ''"
                    :product-price="show_price ? `Price : $ ${Number(price).toFixed(2)}` : ''"
                >
                    <BarcodeGenerator
                        :value="barCode"
                        :format="'CODE39'"
                        :lineColor="'#000'"
                        :elementTag="'svg'"
                    /> 
                </div>
            </template>
      </div>
    </content>
    <content-header/>
  </template>
  
  <script>
  import axios from 'axios'
  import BarcodeGenerator from '@/components/barCodeGenerator/BarcodeGenerator'
  import { product } from '@/networks/services/pos/product.service'
  import { checkPermission } from '@/networks/services/helper/helper.js'
  import Loading from '@/components/loading/Loading'
  import { config } from '@/networks/config'
  import { remove, bulk_delete , check} from '@/networks/services/helper/button.js'
  
  
  
  
  export default {
    components: { 
      Loading,
      BarcodeGenerator
    },
    data(){
      return {
        proId:'',
        barCode : '',
        pro_name:'',
        price : '',
        qauntity: 1,
        show_name : true,
        show_price : true,
        isLoadBarcode : true,
        table : {
          page: 1,
          totalIndexPage: 0,
          totalItem: 0,
          per_page: 100000,
          from_index:0,
          to_index:0,
          data: [],
          search: null,
          orderBy: 'DESC'
        },
        search: null,
  
        permission_no: false,
        isTrue: true,
        pageLoad: false,
        create: false,
        deleteAll : false,
        edit: false,
        list: false,
      }
    },
    computed:{
      selectOptions(){
          let opts = this.table.totalIndexPage;
          return opts
      }
    },
    watch: {
      search(after, before){  
        this.table.search = this.search
        this.pageLoad = true;
        this.table.page = 1;
        this.getData(false);  
      },
      proId(after,before){
        if(after != ''){
            let filter = this.table.data.filter(e => e.id == this.proId)[0];
            this.barCode = filter.barcode;
            this.pro_name = filter.name;
            this.price = filter.unit_price;
        } else {
            this.barCode = '';
            this.pro_name = '';
            this.price = '';
        }
        this.reloadBarcode();
      }
    }
    ,
    methods : {
      printWindow(){
        window.print();
      },
      refreshPage(){
        window.location.reload();
      },
      async reloadBarcode(){
        this.isLoadBarcode = false;
        await this.$nextTick();
        this.isLoadBarcode = true;
      },
      isCheckToOpen(condition){
        if(condition == 'show_name'){
            this.show_name = !this.show_name;
        } else {
            this.show_price = !this.show_price;
        }
      },
      reloadData(){
          this.table.orderBy = 'DESC';
          this.table.per_page = 100000;
          this.table.page = 1;
          this.isTrue = true;
          this.getData(false);
      },
      btnOrderBy(){
        this.pageLoad = true;
        this.getData(false);
      },
      JustCreateModal(event){
        if(event == "success"){
          if(this.table.data.length){
              this.pageLoad = true;
              this.getData(false);
          } else {
              this.isTrue = true;
              this.getData(false);
          }
        } 
        if(event == "is_permission_no"){
          this.isTrue = true;
          this.getData(false);
        }
      },
      JustUpdateModel(event){
        if(event == "success"){
          this.pageLoad = true;
          this.getData(false);
        } 
        if(event == "is_permission_no"){
          this.isTrue = true;
          this.getData(false);
        }
      },
      btnEdit(id){
        
        $(`[button=edit${id}]`).prop("disabled", true);
        $(`#editImg${id}`).toggleClass('d-none');
        $(`#editFa${id}`).toggleClass('d-none');
        $('#editModal').on('hidden.bs.modal', function () {
          $(this).find('form').trigger('reset');
        })
        
        this.getData(id); 
      },
      alertRemove(id,type=null){
        alertify.confirm('Delete', 'Are you sure to do this stuff ?', function(){ 
          if(type == "single"){
            $(`#hiddenDelete${id}`).trigger("click");
          }
          if(type == "plural"){
            $(`#${id}`).trigger("click");
          }
        }
        , function(){ });
      },
      async btnDelete(id){
        let obj = {
          tbl : '',
          per : '',
        };
  
        let event =  await remove(id,obj);
        if(event == "success"){
          this.pageLoad = true;
          this.getData(false);
        } 
        if(event == "is_permission_no"){
          this.isTrue = true;
          this.getData(false);
        }
      },
      async btnDeleteAll(){
        let obj = {
          tbl : "",
          per : ""
        };
        let event = await bulk_delete(obj);
        if(event == "success"){
          this.pageLoad = true;
          this.getData(false);
        } 
        if(event == "is_permission_no"){
          $('#checkBox').prop('checked',false);
          this.isTrue = true;
          this.getData(false);
        }
      },
      btnChecky(obj){
        check(obj);
      },
      btnPage(e,value = null){
        this.pageLoad = true;
        if(e == 'prev'){
          this.table.page = value;
          this.getData(false);
        } 
        if(e == 'next'){
          this.table.page = value;
          this.getData(false);
        } else if(e == 'per_page'){
          this.table.page = 1;
          this.getData(false);
        } else {
          this.getData(false);
        }
      },    
      totalPage(){
        return this.table.totalIndexPage;
      },
      async reloadSelectOption(){
        await this.$nextTick();
        $('.selectPicker').selectpicker('refresh');
        $('.selectPicker').selectpicker();
      },
      async getData(id){
        if(localStorage.getItem('posUser')){
          config();
          axios
            .get(`${product('all-barcode',this.table.per_page,this.table.page,id,this.table.search,this.table.orderBy)}`)
            .then(response => {
              if(response.data.status === 'success'){
                if(!id){
                  this.pageLoad = false;
                  
                  //table
                  this.table.totalIndexPage = response.data.data.last_page;
                  this.table.data = response.data.data.data; 
                  this.table.totalItem = response.data.data.total;
                  this.table.from_index = response.data.data.from ? response.data.data.from : 0;
                  this.table.to_index = response.data.data.to ? response.data.data.to : 0;

                  //permission
                  this.list = response.data.list;
                  this.create = response.data.create;
                  this.deleteAll = response.data.delete;
                  this.edit = response.data.edit;

                  this.isTrue = false;
                  $('#checkBox').prop('checked',false);

                } else {
                  $('#eid').val(response.data.data.id);
                  $('#ename').val(response.data.data.name);
                  $(`#editImg${id}`).toggleClass('d-none');
                  $(`#editFa${id}`).toggleClass('d-none');
                  $(`[button=edit${id}]`).prop("disabled", false);
                  $('#editModal').modal();
                }
                  
              } else if(response.data.status === 401) {
                alertify.set('notifier','position', 'top-right');
                alertify.error(`${response.data.message}`);
                localStorage.removeItem('posUser');
                this.$router.push('/Unauthenticated');
              } else if(response.data.status == "is_permission_no"){
                this.permision_no = true;
              }
              else {
                console.log('something wrong with api');
              }
            })
            .then(() => {
              this.reloadSelectOption();
            })
            .catch(error => {
                console.log(error)
              }
            );
        } else {
          this.$router.push('/Unauthenticated')
        }
      },
    },
    created(){
        this.getData(false);
    },
    mounted(){
      $('head').append('<link id="removeLink" rel="stylesheet" href="/assets/style/barCode.css">')
    }
  }
  </script>
  
  <style>
  
  </style>