import { domain } from "../../domain";

export {
    companyCategory,
    deleteCompanyCategory
}

function companyCategory(per_page=null,page=null,id=null,name=null,orderBy=null){
    if(id){
        return `${domain}/pos/get-product-category?id=${id}`;
    } else if(name){
        return `${domain}/pos/get-product-category?per_page=${per_page}&page=${page}&name=${name}&orderBy=${orderBy}`
    } else {
        return `${domain}/pos/get-product-category?per_page=${per_page}&page=${page}&orderBy=${orderBy}`;
    }
}

function deleteCompanyCategory(id=null){
    return `${domain}/pos/delete-product-category?id=${id}`;
}





