<template>
    <div class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
        <form action="ohyes" method="POST" id='create_form' @submit.prevent="form_submit()">
            <input id="create_status" type="hidden" value="error">
            <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Create Role</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true" >&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group mb-1">
                        <label>
                            Name <span class="text-danger">*</span>
                        </label>
                        <input v-model="name" type="text" name="name" class="form-control" required>
                    </div>
                </div>
                <div class="modal-footer">
                    <button :disabled="isCreate" id="save" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isCreate" src="../../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-dismiss="modal" 
                        @click="triggerReset()">
                        <i class="fa fa-times"></i> Close
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>  
</template> 
<script>
import { createRole } from '@/networks/services/pos/role.service.js'
import axios from 'axios'
export default {
    data(){
        return {
            name : '',
            tbl : "roles",
            per : "role",
            isCreate : false,
        }
    },
    methods: {
        triggerReset(){
            // $('#createModal').on('hidden.bs.modal', function () {
            //     $(this).find('form').trigger('reset');
            // })
            this.name = '';
            this.isCreate = false;
        },
        async form_submit(){
            this.isCreate = true;
            let data = {
                name : this.name,
            };
            // await frm_submit(data);
            const result = await axios
                                    .post(`${createRole}`,data)
                                    .catch(error => {
                                        console.log(error.response);
                                    });
            this.triggerReset();
            if(result.data.status == "success"){
                $('#createModal').modal('hide');
                $("#create_form")[0].reset();
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
                this.$emit("createModal","success");   
            } else if(result.data.status == "401"){
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
                localStorage.removeItem('posUser');
                this.$router.push('/Unauthenticated');
            } else if(result.data.status == 'is_permission_no'){
                $('#createModal').modal('hide');
                $("#create_form")[0].reset();
                alertify.set('notifier','position', 'top-right');
                alertify.error(`You are no permission to do this stuff!`);
                this.$emit("createModal","is_permission_no");   
            } else if(result.data.status == "error"){
                $('#createModal').modal('hide');
                $("#create_form")[0].reset();
                $("#create_status").val("error");
                alertify.set('notifier','position', 'top-right');
                alertify.error(`${result.data.message}`);
            } else {
                console.log(result)
            } 
        },
    },
    mounted(){
        $(document).ready(function() {
            $('.chosen2').select2();
        });
    }
}
</script>
