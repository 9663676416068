<template>
    <div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="editModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <form action="uytyut" method="POST" id='edit_form' @submit.prevent="form_update($event)">
              <input type="hidden" name="id" id='eid' value="">
              <input id="edit_status" type="hidden">
              <div class="modal-content">
                <div class="modal-header bg-system">
                    <strong class="modal-title text-white">Edit Unit Type</strong>
                    <button @click="triggerReset()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span class="text-white" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                <div id="esms">
                </div>
                <div class="form-group mb-1">
                    <label>
                        Name <span class="text-danger">*</span>
                    </label>
                    <input type="text" v-model="edata.name" class="form-control" id='ename' required>
                </div>
                <div class="col-lg-4 col-sm-12 form-group my-2">
                    <label for="">Product Image</label>
                    <div class="w-100">
                        <input id="ephooo" @change="changeImg('photo',$event)" type="file">
                        <button type="button" @click="resetImg('photo')" v-if="pho" title="Reset Photo" class="border-0 rounded outline-0 float-right btn-outline-dark"><i class="fas fa-redo-alt"></i></button>
                        <img v-if="edata.photo" :src="edata.photo" id="ephoto" class="my-2 w-25 rounded">
                    </div>
                </div> 
                </div>
                  <div class="modal-footer">
                    <button :disabled="isUpdate" type="submit" class="btn btn-system btn-xs">
                        <img v-if="isUpdate" src="../../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fa fa-save"></i> Update
                    </button>
                    <button @click="triggerReset()" type="button" class="btn btn-danger btn-xs" data-dismiss="modal">
                        <i class="fa fa-times"></i> Close
                    </button>
                  </div>
              </div>
          </form>
        </div>
    </div>  
    </template>
    
    <script>
    import { frm_update } from '@/networks/services/helper/button.js'
    
    export default {
        props : {
            edata : {
                type : [String, Object],
                default : null
            }
        },
        data(){
            return {
                tbl : "brands",
                per : "brand",
                pho : false,
                phoo : '',
                oldPhoto : '',
                isUpdate : false,
            }
        },
        methods: {  
            resetImg(value){
                if(value == "photo"){
                    this.edata.photo = this.oldPhoto;
                    this.pho = false;
                    this.phoo = '';
                    $('#ephooo').val('');
                }
            },
            changeImg(value,obj){
                if(value == "photo"){
                    this.oldPhoto = this.edata.photo;
                    this.edata.photo = URL.createObjectURL(obj.target.files[0]);
                    this.phoo = obj.target.files[0];
                    this.pho = true;
                }
            },
            triggerReset(){
                this.pho = false;
                this.phoo = '';
                $('#ephooo').val('');
                this.oldPhoto = '';
                this.isUpdate = false;
            },
            async form_update(){
                this.isUpdate = true;
                var data = new FormData();
                data.append('id',this.edata.id);
                data.append('name',this.edata.name);
                data.append('tbl',this.tbl);
                data.append('per',this.per);
                data.append('photo',this.phoo);
                await frm_update(data);
                this.triggerReset();
                this.$emit("editModal",$('#edit_status').val());   
            },
        },
        mounted(){
            $(document).ready(function() {
                $('.chosen2').select2();
            });
        }
    }
    </script>
    
    <style>
    
    </style>