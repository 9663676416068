<template>
<content-header title="expense" icon="fa fa-angle-double-right" />
<content>
    <loading v-if="isTrue" />
    <div v-else class="container-fluid">
        <div class="card">

            <!-- button action -->
            <div class="card-header">
                <button v-if="create" class="btn btn-system btn-sm mb-2" @click="createModal" button="create">
                    <img v-if="isCreate" src="../../assets/sniper.jpeg" alt="" width="12">
                    <i v-else class="fa fa-plus-circle"></i> CREATE
                </button>
                <span v-if="create" class="mr-2"></span>
                <button v-if="deleteAll && table.data.length" class="btn btn-danger btnBlock btn-sm mb-2" @click="alertRemove('actionDeleteAll','plural')">
                    <i class="fa fa-trash"></i> Delete All
                    <input type="hidden" id="delete_data">
                </button>
                <input v-if="deleteAll && table.data.length" id="actionDeleteAll" type="hidden" @click="btnDeleteAll()">
                <span v-if="deleteAll && table.data.length" class="mr-2"></span>
                <button @click="reloadData()" type="button" class="btn btn-warning btn-sm mb-2"><i class="fa fa-exchange-alt mr-1"></i>RELOAD DATA</button>

                &nbsp;
                <select v-model="table.orderBy" @change="btnOrderBy()" class="selectPicker form-control-sm float-sm-right mb-2 p-0">
                    <option value="DESC">ORDER BY : NEWEST</option>
                    <option value="ASC">ORDER BY : OLDEST</option>
                </select>

            </div>

            <div class="card-body">

                <!-- companies data  -->
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        Expense Category :
                        <label>
                            <select id="companies" v-model="expense_category_id" @change="btnOrderBy()" data-live-search="true" class="selectPicker form-control form-control-sm p-0">
                                <option value="all">All</option>
                                <option :value="expense.id" v-for="expense in expense_categories" :key="expense.id">{{ expense.name }}</option>
                            </select>
                        </label>
                    </div>
                </div>

                <!-- button search and option data  -->
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        Show
                        <label>
                            <select v-model="table.per_page" @change="btnPage('per_page')" data-live-search="false" class="selectPicker form-control form-control-sm">
                                <option :value="10*index+10" v-for="(p,index) in 5" :key="index">{{10*(index+1)}}</option>
                            </select>
                        </label>
                        entries
                    </div>
                    <div class="col-sm-12 col-md-6 col-12">
                        <div class="float-md-right">
                            Search:
                            <label>
                                <input v-model="search" type="search" class="form-control form-control-sm">
                            </label>
                        </div>
                    </div>
                </div>

                <!-- table  -->
                <div class="table-responsive-sm position-relative">
                    <table class="table data-table table-sm table-bordered" style="width: 100%">
                        <thead class="bg-system text-light">
                            <tr>
                                <th style="width:50px">
                                    <input id="checkBox" type="checkbox" @click="btnChecky($event.target)" value="off">
                                </th>
                                <th style="width:50px">#</th>
                                <th>expense category</th>
                                <th>expense date</th>
                                <th>amount</th>
                                <th style="width:80px">photo</th>
                                <th>description</th>
                                <th style="width:80px">Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="table.data.length">
                            <template v-for="(data, index) in table.data" :key="data.id">
                                <tr :id="`tr${data.id}table`">
                                    <td>
                                        <input type="checkbox" :id="`ch${data.id}`" :value="data.id">
                                    </td>
                                    <td>{{ table.from_index + index}}</td>
                                    <td :data-col="`#`+ (table.from_index+index) + `. Expense Category`">{{ data.expense_category_name }}
                                        <button :data-row="`tr${data.id}table`" onclick="toggleRow(this)" class="clicker btn btn-xs bg-system text-white rounded-circle">
                                            <i class="fas fa-plus-circle"></i>
                                        </button>
                                    </td>
                                    <td data-col="Expense Date">{{ new Date(data.expense_date).toLocaleDateString("en-GB", { weekday : 'long' , day : 'numeric', month : 'numeric' , year : 'numeric' }) }}</td>
                                    <td data-col="amount">${{ data.amount }}</td>
                                    <td data-col="Photo">
                                        <img v-if="data.photo" :src="resource + data.photo" alt="Photo of Product" style="width: 30px; height:30px;" class="rounded">
                                        <span v-else>&nbsp;</span>
                                    </td>
                                    <td data-col="Description">{{ data.description }}</td>
                                    <td data-col="Action">
                                        <button v-if="edit" type='button' @click="btnEdit(data.id)" :button="`edit${data.id}`" class='btn btn-success btn-xs mr-1' title="edit">
                                            <img :id="`editImg${data.id}`" class="d-none" src="../../assets/sniper.jpeg" alt="" width="12">
                                            <i :id="`editFa${data.id}`" class='fa fa-edit'></i>
                                        </button>
                                        <button v-if="deleteAll" type="button" @click="alertRemove(data.id,'single')" class='btn btn-xs' :class="`${data.onhand == 0 ? 'btn-white text-danger' : 'btn-danger'}`" title='delele'>
                                            <img :id="`deleteImg${data.id}`" class="d-none" src="../../assets/sniper.jpeg" alt="" width="12">
                                            <i :id="`deleteFa${data.id}`" class='fa fa-trash'></i>
                                        </button>
                                        <input :id="`hiddenDelete${data.id}`" type="hidden" @click="btnDelete(data.id)">
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>

                <!-- Processing loader  -->
                <div v-if="pageLoad" class="card position-absolute " style="z-index:10; left: 45%; top: 50%;">
                    <div class="card-body">
                        <span class="txt-system">Processing...</span>
                    </div>
                </div>

                <!-- no data -->
                <div v-if="!table.data.length" class="card">
                    <div class="card-body text-center">
                        <span class="txt-system">No Data...</span>
                    </div>
                </div>

                <!-- button table  -->
                <div class="row">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info py-2">Showing {{ table.from_index }} to {{ table.to_index }} of {{ table.totalItem }} entries</div>
                    </div>
                    <div class="col-sm-12 col-md-7">
                        <div class="dataTables_paginate paging_simple_numbers float-md-right">
                            <ul class="pagination">
                                <li class="page-item mr-3">
                                    <select @change="btnPage()" v-model="table.page" data-live-search="true" id="page" class="selectPicker border-page-color">
                                        <template v-if="table.totalItem != 0">
                                            <option v-for="(page,index) in selectOptions" :key="index" :value="index + 1">Page {{ index + 1 }} of {{ table.totalIndexPage }}</option>
                                        </template>
                                    </select>
                                </li>
                                <li @click="btnPage('prev',this.table.page-1)" class="border-top-left-radius border-bottom-left-radius" :class="table.page > 1 ? 'active' : ''">
                                    <button :disabled="table.page == 1 || table.totalIndexPage == 0" type="button" class="link-page border-top-left-radius border-bottom-left-radius" :class="table.page == 1 || table.totalIndexPage == 0 ? 'txt-gray' : ''">&lt;&lt;</button>
                                </li>
                                <li @click="btnPage('next',this.table.page+1)" class="page-item">
                                    <button :disabled="table.totalIndexPage == 1 || table.totalIndexPage == table.page" type="button" class="link-page-right" :class="table.totalIndexPage == 1 || table.totalIndexPage == table.page ? 'txt-gray' : ''">&gt;&gt;</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</content>
<content-header />
<CreateExpenseViewVue v-if="create" :expense_categories="expense_categories" @createModal="JustCreateModal($event)" />
<EditExpenseViewVue v-if="edit" :expense_categories="expense_categories" :edata="edata" @editModal="JustUpdateModel($event)" :photo="photo" :pho="pho" :phoo="phoo" @changeImg="changeImage($event)" @resetImg="resetImg()" />
</template>

  
  
<script>
import axios from 'axios'
import { resource } from '@/networks/domain'
import { expense, create } from '@/networks/services/pos/expense.service'
import { checkPermission } from '@/networks/services/helper/helper.js'
import Loading from '@/components/loading/Loading'
import { config } from '@/networks/config'
import { remove, bulk_delete, check } from '@/networks/services/helper/button.js'
import CreateExpenseViewVue from './CreateExpenseView.vue'
import EditExpenseViewVue from './EditExpenseView.vue'

export default {
    components: {
        Loading,
        CreateExpenseViewVue,
        EditExpenseViewVue
    },
    data() {
        return {
            photo: '',
            pho: false,
            phoo: null,
            edata: {},
            ephotoDefault: '',
            resource: resource,

            table: {
                page: 1,
                totalIndexPage: 0,
                totalItem: 0,
                per_page: 10,
                from_index: 0,
                to_index: 0,
                data: [],
                search: null,
                orderBy: 'DESC'
            },
            isCreate: false,
            is_stock: false,
            expense_category_id: 'all',
            expense_categories: [],
            com_expense_categories: [],
            search: null,

            permission_no: false,
            isTrue: true,
            pageLoad: false,
            create: false,
            deleteAll: false,
            edit: false,
            list: false,
        }
    },
    computed: {
        selectOptions() {
            let opts = this.table.totalIndexPage;
            return opts
        }
    },
    watch: {
        search(after, before) {
            this.table.search = this.search
            this.pageLoad = true;
            this.table.page = 1;
            this.getData(false);
        },
    },
    methods: {
        reloadData() {
            this.table.orderBy = 'DESC';
            this.table.per_page = 10;
            this.table.page = 1;
            this.isTrue = true;
            this.getData(false);
        },
        btnOrderBy() {
            this.pageLoad = true;
            this.getData(false);
        },
        JustCreateModal(event) {
            if (event == "success") {
                if (this.table.data.length) {
                    this.pageLoad = true;
                    this.getData(false);
                } else {
                    this.isTrue = true;
                    this.getData(false);
                }
            }
            if (event == "is_permission_no") {
                this.isTrue = true;
                this.getData(false);
            }
        },
        JustUpdateModel(event) {
            if (event == "success") {
                this.pageLoad = true;
                this.getData(false);
            }
            if (event == "is_permission_no") {
                this.isTrue = true;
                this.getData(false);
            }
        },
        btnEdit(id) {

            $(`[button=edit${id}]`).prop("disabled", true);
            $(`#editImg${id}`).toggleClass('d-none');
            $(`#editFa${id}`).toggleClass('d-none');
            $('#editModal').on('hidden.bs.modal', function () {
                $(this).find('form').trigger('reset');
            })
            this.getData(id);
        },
        alertRemove(id, type = null) {
            alertify.confirm('Delete', 'Are you sure to do this stuff ?', function () {
                if (type == "single") {
                    $(`#hiddenDelete${id}`).trigger("click");
                }
                if (type == "plural") {
                    $(`#${id}`).trigger("click");
                }
            }, function () {});
        },
        async btnDelete(id) {
            let obj = {
                tbl: 'expenses',
                per: 'expense',
            };

            let event = await remove(id, obj);
            if (event == "success") {
                this.pageLoad = true;
                this.getData(false);
            }
            if (event == "is_permission_no") {
                this.isTrue = true;
                this.getData(false);
            }
        },
        async btnDeleteAll() {
            let obj = {
                tbl: "expenses",
                per: "expense"
            };
            let event = await bulk_delete(obj);
            if (event == "success") {
                this.pageLoad = true;
                this.getData(false);
            }
            if (event == "is_permission_no") {
                $('#checkBox').prop('checked', false);
                this.isTrue = true;
                this.getData(false);
            }
        },
        async createModal(is_create = true) {
            config();
            if (is_create) {
                this.isCreate = true;
                $(`[button=create]`).prop("disabled", true);
            }
            const createExpense = await axios
                .get(`${create}`)
                .catch(error => {
                    console.log(error.response)
                });

            if (createExpense.data.status == "success") {

                //expense categories
                this.expense_categories = createExpense.data.expense_categories;

                if (is_create) {
                    this.isCreate = false;
                    $(`[button=create]`).prop("disabled", false);
                    $('#createModal').modal('show');
                }

                this.reloadSelectOption();

            } else if (createExpense.data.status == '401') {

                alertify.set('notifier', 'position', 'top-right');
                alertify.error("Unauthenticated");
                localStorage.removeItem('posUser');
                this.$router.push('/Unauthenticated');

            } else if (createExpense.data.status = 'is_permission_no') {

                this.isTrue = true;
                this.getData(false);
                alertify.set('notifier', 'position', 'top-right');
                alertify.error('You are no permission to do this stuff!');

            } else {
                console.log(createExpense);
            }
        },
        btnChecky(obj) {
            check(obj);
        },
        btnPage(e, value = null) {
            this.pageLoad = true;
            if (e == 'prev') {
                this.table.page = value;
                this.getData(false);
            }
            if (e == 'next') {
                this.table.page = value;
                this.getData(false);
            } else if (e == 'per_page') {
                this.table.page = 1;
                this.getData(false);
            } else {
                this.getData(false);
            }
        },
        totalPage() {
            return this.table.totalIndexPage;
        },
        changeImage(obj) {
            this.photo = URL.createObjectURL(obj.target.files[0]);
            this.phoo = obj.target.files[0];
            this.pho = true;
        },
        resetImg() {
            this.photo = resource + this.ephotoDefault;
            this.pho = false;
            this.phoo = this.ephotoDefault;
        },
        async reloadSelectOption(){
            await this.$nextTick();
            $('.selectPicker').selectpicker('refresh');
            $('.selectPicker').selectpicker();
        },
        async getData(id) {
            if (localStorage.getItem('posUser')) {
                config();
                axios
                    .get(`${expense(this.expense_category_id,this.table.per_page,this.table.page,id,this.table.search,this.table.orderBy)}`)
                    .then(response => {
                        if (response.data.status === 'success') {
                            if (!id) {
                                this.pageLoad = false;

                                //table
                                this.table.totalIndexPage = response.data.data.last_page;
                                this.table.data = response.data.data.data;
                                this.table.totalItem = response.data.data.total;
                                this.table.from_index = response.data.data.from ? response.data.data.from : 0;
                                this.table.to_index = response.data.data.to ? response.data.data.to : 0;

                                //permission
                                this.list = response.data.list;
                                this.create = response.data.create;
                                this.deleteAll = response.data.delete;
                                this.edit = response.data.edit;

                                //expenses
                                this.expense_categories = response.data.expense_categories;

                                this.isTrue = false;
                                $('#checkBox').prop('checked', false);

                            } else {
                                $('#eid').val(response.data.data.id);
                                this.edata = response.data.data;
                                this.ephotoDefault = response.data.data.photo;
                                this.phoo = response.data.data.photo ? resource + response.data.data.photo : '';
                                this.photo = response.data.data.photo ? resource + response.data.data.photo : '';

                                $(`#editImg${id}`).toggleClass('d-none');
                                $(`#editFa${id}`).toggleClass('d-none');
                                $(`[button=edit${id}]`).prop("disabled", false);
                                $('#editModal').modal();
                            }

                        } else if (response.data.status === 401) {
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.error(`${response.data.message}`);
                            localStorage.removeItem('posUser');
                            this.$router.push('/Unauthenticated');
                        } else if (response.data.status == "is_permission_no") {
                            this.permision_no = true;
                        } else {
                            console.log('something wrong with api');
                        }
                    })
                    .then(()=>{
                        this.reloadSelectOption();
                    })
                    .catch(error => {
                        console.log(error)
                    });
            } else {
                this.$router.push('/Unauthenticated')
            }
        },
    },
    created() {
        this.getData(false);
    },
    mounted() {

    }
}
</script>
  
  
<style>
  
  </style>
