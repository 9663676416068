<template>
  <content-header title="Company Role" icon="fas fa-shield-alt"/>
  <content>
    <loading v-if="isTrue"/>
    <div v-else class="container-fluid">
      <div class="card">



        <!-- button action -->
        <div class="card-header">
            <button @click="$router.back()" class="btn btn-dark btn-sm mb-2"><i class="fas fa-reply-all"></i>&nbsp; BACK</button>
            &nbsp;
            <button @click="reloadData()" type="button" class="btn btn-warning btn-sm mb-2"><i class="fa fa-exchange-alt"></i>&nbsp; RELOAD DATA</button>      
            &nbsp;
          <select v-model="table.orderBy" @change="btnOrderBy()" class="selectPicker form-control-sm float-sm-right mb-2 p-0">
            <option value="DESC">ORDER BY : NEWEST</option>
            <option value="ASC">ORDER BY : OLDEST</option>
          </select>
         
        </div>



        <div class="card-body">



          <!-- button search and option data  -->
          <div class="row">
            <div class="col-sm-12 col-md-6">
              Show
              <label> 
                <select v-model="table.per_page" @change="btnPage('per_page')"  data-live-search="false" class="selectPicker form-control form-control-sm">
                  <option :value="10*index+10" v-for="(p,index) in 5" :key="index">{{10*(index+1)}}</option>
                </select> 
              </label>
              entries
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="float-md-right">
                  Search:
                  <label>
                    <input v-model="search" type="search" class="form-control form-control-sm">
                  </label>
                </div>
            </div>
          </div>




          <!-- table  -->
          <div  class="table-responsive-sm position-relative">
            <table class="table data-table table-sm table-bordered" style="width: 100%">
              <thead class="bg-system text-light">
                <tr>
                    <th class="d-none"></th>
                    <th>#</th>
                    <th>Permission</th>
                    <th>View</th>
                    <th>Create</th>
                    <th>Update</th>
                    <th>Delete</th>
                    <th>Import Excel</th>
                    <th>Export Excel</th>
                    <th>Export PDF</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in table.data" :key="data.id" :id="`tr${data.id}table`">
                  <td class="d-none"></td>
                  <td>{{ table.from_index + index}}</td>
                  <td :data-col="`#`+ (table.from_index+index) + `. Permission`">{{ data.name }}
                     <button :data-row = "`tr${data.id}table`" onclick="toggleRow(this)" class="clicker btn btn-xs bg-system text-white rounded-circle">
                      <i class="fas fa-plus-circle"></i>
                    </button>
                  </td>
                  <td data-col="View">
                    <label class="switch">
                        <input type="checkbox" :checked="data.list == 1" @click="change(data.id,'list',data.list)">
                        <span class="slider"></span>
                    </label>
                  </td>
                  <td data-col="Create">
                    <label class="switch">
                        <input type="checkbox" :checked="data.insert == 1" @click="change(data.id,'insert',data.insert)">
                        <span class="slider"></span>
                    </label>
                  </td>
                  <td data-col="Update">
                    <label class="switch">
                        <input type="checkbox" :checked="data.update == 1" @click="change(data.id,'update',data.update)">
                        <span class="slider"></span>
                    </label>
                  </td>
                  <td data-col="Delete">
                    <label class="switch">
                        <input type="checkbox" :checked="data.delete == 1" @click="change(data.id,'delete',data.delete)">
                        <span class="slider"></span>
                    </label>
                  </td>
                  <td data-col="Import Excel">
                    <label class="switch">
                        <input type="checkbox" :checked="data.import_excel == 1" @click="change(data.id,'import_excel',data.import_excel)">
                        <span class="slider"></span>
                    </label>
                  </td>
                  <td data-col="Export Excel">
                    <label class="switch">
                        <input type="checkbox" :checked="data.export_excel == 1" @click="change(data.id,'export_excel',data.export_excel)">
                        <span class="slider"></span>
                    </label>
                  </td>
                  <td data-col="Export PDF">
                    <label class="switch">
                        <input type="checkbox" :checked="data.pdf == 1" @click="change(data.id,'pdf',data.pdf)">
                        <span class="slider"></span>
                    </label>
                  </td>
                </tr>    
              </tbody>
            </table>
          </div>



          <!-- Processing loader  -->
          <div v-if="pageLoad" class="card position-absolute" style="z-index:10; left: 40%; top: 40%;">
            <div class="card-body">
              <span class="txt-system">Processing...</span>
            </div>
          </div>



          <!-- button table  -->
          <div class="row">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info py-2">Showing {{ table.from_index }} to {{ table.to_index }} of {{ table.totalItem }} entries</div>
            </div>
            <div class="col-sm-12 col-md-7">
              <div class="dataTables_paginate paging_simple_numbers float-md-right">
                <ul class="pagination">
                  <li  class="page-item mr-3">
                    <select @change="btnPage()" v-model="table.page" data-live-search="true" id="page" class="selectPicker border-page-color">
                     <template v-if="table.totalItem != 0">
                      <option v-for="(page,index) in selectOptions" :key="index" :value="index + 1">Page {{ index + 1 }} of {{ table.totalIndexPage }}</option>
                     </template>
                    </select>
                  </li>
                  <li @click="btnPage('prev',this.table.page-1)" class="border-top-left-radius border-bottom-left-radius" :class="table.page > 1 ? 'active' : ''">
                    <button  :disabled="table.page == 1 || table.totalIndexPage == 0"  type="button" class="link-page border-top-left-radius border-bottom-left-radius" :class="table.page == 1 || table.totalIndexPage == 0 ? 'txt-gray' : ''">&lt;&lt;</button>
                  </li>
                  <li @click="btnPage('next',this.table.page+1)" class="page-item">
                    <button :disabled="table.totalIndexPage == 1 || table.totalIndexPage == table.page" type="button" class="link-page-right" :class="table.totalIndexPage == 1 || table.totalIndexPage == table.page ? 'txt-gray' : ''">&gt;&gt;</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>





        </div>
      </div>
    </div>
  </content>
  <content-header/>
</template>

<script>
import axios from 'axios'
import { checkPermission } from '@/networks/services/helper/helper.js'
import Loading from '@/components/loading/Loading'
import { config } from '@/networks/config'
import { rolePermission , changeRolePermission} from '@/networks/services/pos/rolePermission.service.js'



export default {
  components: { 
    Loading,
  },
  props: {
    id : {
      type : [String, Number],
      default : ''
    }
  },
  data(){
    return {
      params : {
        id : this.id,
      },
      table : {
        page: 1,
        totalIndexPage: 0,
        totalItem: 0,
        per_page: 50,
        from_index:0,
        to_index:0,
        data: [],
        search: null,
        orderBy: 'DESC',
      },
      search: null,
      dataTable: 1,

      permission_no: false,
      isTrue: true,
      pageLoad: false,
      list: false,
    }
  },
  computed:{
    selectOptions(){
        let opts = this.table.totalIndexPage;
        return opts
    }
  },
  watch: {
    search(after, before){  
      this.table.search = this.search
      this.pageLoad = true;
      this.table.page = 1;
      this.getData(false);  
    },
  }
  ,
  methods : {
    change(id,condition,value){
        const data = {
            id : id,
            condition : condition,
            value : value
        }
        axios
            .post(`${changeRolePermission("role")}`,data)
            .then(response => {
                if(response.data.status == "success"){
                    this.btnOrderBy();
                    alertify.set('notifier','position', 'top-right');
                    alertify.success(`${response.data.message}`); 
                } else if(response.data.status == "401"){
                    alertify.set('notifier','position', 'top-right');
                    alertify.success(`${response.data.message}`);
                    localStorage.removeItem('posUser');
                    this.$router.push('/Unauthenticated');
                } else if(response.data.status = 'is_permission_no'){
                    this.btnOrderBy();
                    alertify.set('notifier','position', 'top-right');
                    alertify.error(`You are no permission to do this stuff!`);   
                } else if(response.data.status == "error"){
                    this.btnOrderBy();
                    alertify.set('notifier','position', 'top-right');
                    alertify.error(`${response.data.message}`);
                } else {
                    console.log(response)
                }

            })
            .catch(error => {
                console.log(error);
            });


    },
    reloadData(){
        this.table.orderBy = 'DESC';
        this.table.per_page = 50;
        this.table.page = 1;
        this.isTrue = true;
        this.getData(true);
    },
    btnOrderBy(){
        this.pageLoad = true;
        this.getData(false);
    },
    btnPage(e,value = null){
      this.pageLoad = true;
      if(e == 'prev'){
        this.table.page = value;
        this.getData(false);
      } 
      if(e == 'next'){
        this.table.page = value;
        this.getData(false);
      } else if(e == 'per_page'){
        this.table.page = 1;
        this.getData(false);
      } else {
        this.getData(false);
      }
    },
    async reloadSelectOption(){
      await this.$nextTick();
      $('.selectPicker').selectpicker('refresh');
      $('.selectPicker').selectpicker();
    },   
    async getData(check){
      if(this.id == ''){
        this.$router.push('/role');
      }
      if(localStorage.getItem('posUser')){
        config();
        axios
          .get(`${rolePermission('role',this.params.id,this.table.per_page,this.table.page,this.table.search,this.table.orderBy)}`)
          .then(response => {
            if(response.data.status === 'success'){

              //permission
              this.list = 1;

              this.pageLoad = false;

              this.table.totalIndexPage = response.data.data.last_page;
              this.table.data = response.data.data.data; 
              this.table.totalItem = response.data.data.total;
              this.table.from_index = response.data.data.from ? response.data.data.from : 0;
              this.table.to_index = response.data.data.to ? response.data.data.to : 0;
              this.isTrue = false;
                
            } else if(response.data.status === 401) {
              alertify.set('notifier','position', 'top-right');
              alertify.error(`${response.data.message}`);
              localStorage.removeItem('posUser');
              this.$router.push('/Unauthenticated');
            } else if(response.data.status == "is_permission_no"){
              this.permision_no = true;
            }
            else {
              console.log('something wrong with api');
            }
          })
          .then(()=>{
            this.reloadSelectOption();
          })
          .catch((error) => {
              this.$router.push('/role');
            }
          );
      } else {
        this.$router.push('/Unauthenticated')
      }
    },
  },
  created(){
    

    this.getData(true);
  },
  mounted(){
    $("#Security").addClass("menu-open");
    $("#Security>a").addClass("active");
    $("#Security>ul").css({'display' : 'block'});
    $("#Role").addClass("active");
  }
}
</script>

<style>

</style>