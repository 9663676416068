<template>
    <div class="col-lg-3 col-6">
        <div :class="`small-box ${bg}`">
        <div class="inner">
            <h3>{{info}}</h3>

            <p>{{title}}</p>
        </div>
        <div class="icon">
            <i :class="`${icon}`"></i>
        </div>
        <div :class="`small-box-footer text-white ${bg} border-card`" style="pointer-events: none;">&nbsp;</div>
        </div>
    </div>
</template>

<script>
export default {

    props : {
        icon: {
            type: [String, Object],
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        info: {
            type: [String, Object],
            default: null,
        },
        bg:{
            type : String,
            default: null,
        }
    }
}
</script>

<style>

</style>