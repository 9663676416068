<template>
  <content-header title="Company Info" icon="fas fa-info-circle"/>
  <content>
    <loading v-if="isTrue"/>
    <div v-else class="container-fluid">
        <!-- <form action="ohyes" @submit.prevent="submit()"> -->
            <div class="card">
                <div class="card-header">
                    <button  @click="$router.back()" class="btn btn-dark btn-sm mb-2 mr-2"><i class="fas fa-reply-all"></i>&nbsp; BACK</button>
                    <button v-if="edit" :disabled="isUpdate" @click="submit()" type="submit" class="btn btn-sm btn-system mb-2 mr-2">
                        <img v-if="isUpdate" src="../../assets/sniper.jpeg" alt="" width="12">
                        <i v-else class="fas fa-tools"></i> {{$t("update")}}
                    </button>
                    <span class="badge badge-info float-right">Company Info</span>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group row">
                                <label for="date" class="col-sm-3 form-control border-0 pl-2">
                                EN NAME
                                    <span class="float-right">:</span>
                                </label>
                                <div class="col-sm-8">
                                    <input  v-model="en_name"  type="text" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="date" class="col-sm-3 form-control border-0 pl-2">
                                KH NAME
                                    <span class="float-right">:</span>
                                </label>
                                <div class="col-sm-8 txt-kh">
                                    <input v-model="kh_name" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="date" class="col-sm-3 form-control border-0 pl-2">
                                EMAIL 
                                    <span class="float-right">:</span>
                                </label>
                                <div class="col-sm-8">
                                    <input v-model="email" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="date" class="col-sm-3 form-control border-0 pl-2">
                                ADDRESS
                                    <span class="float-right">:</span>
                                </label>
                                <div class="col-sm-8 txt-kh">
                                    <textarea v-model="address" type="text" class="form-control"></textarea>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="date" class="col-sm-3 form-control border-0 pl-2">
                                PHONE LINE 1
                                    <span class="float-right">:</span>
                                </label>
                                <div class="col-sm-8">
                                    <input v-model="phone_line1" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="date" class="col-sm-3 form-control border-0 pl-2">
                                PHONE LINE 2
                                    <span class="float-right">:</span>
                                </label>
                                <div class="col-sm-8">
                                    <input v-model="phone_line2" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="date" class="col-sm-3 form-control border-0 pl-2">
                                PHONE LINE 3
                                    <span class="float-right">:</span>
                                </label>
                                <div class="col-sm-8">
                                    <input v-model="phone_line3" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="date" class="col-sm-3 form-control border-0 pl-2 form-control border-0 pl-2">
                                PHONE LINE 4
                                    <span class="float-right">:</span>
                                </label>
                                <div class="col-sm-8">
                                    <input v-model="phone_line4" type="text" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group row">
                                <label for="latitude" class="col-sm-4 form-control border-0 pl-2">
                                    Latitude
                                    <span class="float-right">:</span>
                                </label>
                                <div class="col-sm-8 txt-kh">
                                    <input v-model="lat" type="text" id="latitude" class="form-control"/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="latitude" class="col-sm-4 form-control border-0 pl-2">
                                    Longitude
                                    <span class="float-right">:</span>
                                </label>
                                <div class="col-sm-8 txt-kh">
                                    <input v-model="lng" type="text" id="latitude" class="form-control"/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="date" class="col-sm-4 form-control border-0 pl-2">
                                INVOICE HEADER
                                    <span class="float-right">:</span>
                                </label>
                                <div class="col-sm-8 txt-kh">
                                    <textarea v-model="invoice_header" class="form-control"></textarea>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="date" class="col-sm-4 form-control border-0 pl-2">
                                INVOICE FOOTER
                                    <span class="float-right">:</span>
                                </label>
                                <div class="col-sm-8 txt-kh">
                                    <textarea v-model="invoice_footer" class="form-control"></textarea>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="date" class="col-sm-4 form-control border-0 pl-2">
                                LOGO
                                    <span class="float-right">:</span>
                                </label>
                                <div class="col-sm-8">
                                    <input id="logo" @change="changeImg('logo',$event)" type="file">
                                    <button @click="resetImg('logo')" v-if="lo" title="Reset Photo" class="border-0 rounded outline-0 float-right btn-outline-dark"><i class="fas fa-redo-alt"></i></button>
                                    <img v-if="companyInfo.logo" :src="img(logo)" class="my-2 rounded-circle w-25">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="date" class="col-sm-4 form-control border-0 pl-2">
                                Banner
                                    <span class="float-right">:</span>
                                </label>
                                <div class="col-sm-8">
                                    <input id="banner" @change="changeImg('banner',$event)" type="file">
                                    <button @click="resetImg('banner')" v-if="ban" title="Reset Photo" class="border-0 rounded outline-0 float-right btn-outline-dark"><i class="fas fa-redo-alt"></i></button>
                                    <img v-if="companyInfo.banner" :src="img(banner)" class="my-2 w-100 rounded">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        <!-- </form> -->
    </div>
  </content>
  <content-header/>
</template>

<script>
import axios from 'axios'
import { resource } from '@/networks/domain'
import { userInfo } from '@/networks/services/auth/user.service'
import { UpdateCompanyInfo } from '@/networks/services/dashboard/company.service'
import { checkPermission } from '@/networks/services/helper/helper.js'
import Loading from '@/components/loading/Loading'
import { config } from '@/networks/config'




export default {
  components: { 
    Loading 
  },
  data(){
    return {
      companyInfo : {
        logo : '',
        banner : '',
      },
      en_name: '',
      kh_name: '',
      email : '',
      address : '',
      phone_line1: '',
      phone_line2: '',
      phone_line3: '',
      phone_line4: '',
      logo: '',
      banner: '',
      invoice_header: '',
      invoice_footer: '',
      lat : '',
      lng : '',
      lo : false,
      ban: false,

      permission_no: false,
      isTrue: true,
      pageLoad: false,
      create: false,
      deleteAll : false,
      edit: false,
      list: false,
      isUpdate : false,
    }
  },
  methods : {
    img(value){
        return resource+value;
    },
    resetImg(value){
        if(value == "logo"){
            this.logo = null;
            this.lo = false;
            this.companyInfo.logo = null;
            $('#logo').val('');

        }
        if(value == "banner"){
            this.banner = '';
            this.ban = false;
            this.companyInfo.banner = null;
            $('#banner').val('');

            // this.ban = obj.target.files[0];
        }
    },
    changeImg(value,obj){
        if(value == "logo"){
            this.logo = URL.createObjectURL(obj.target.files[0]);
            this.companyInfo.logo = obj.target.files[0];
            this.lo = true;
            // this.lo = obj.target.files[0];
        }
        if(value == "banner"){
            this.banner = URL.createObjectURL(obj.target.files[0]);
            this.companyInfo.banner = obj.target.files[0];
            this.ban = true;
            // this.ban = obj.target.files[0];
        }
    },
    async submit(){
        this.isUpdate = true;
        config();
            var data = new FormData();
            data.append('en_name',this.en_name);
            data.append('kh_name',this.kh_name);
            data.append('address',this.address);
            data.append('email',this.email);
            data.append('invoice_header',this.invoice_header);
            data.append('invoice_footer',this.invoice_footer);
            data.append('phone_line1',this.phone_line1);
            data.append('phone_line2',this.phone_line2);
            data.append('phone_line3',this.phone_line3);
            data.append('latitude',this.lat);
            data.append('longitude',this.lng);
            data.append('phone_line4',this.phone_line4);
            data.append('logo',this.companyInfo.logo);
            data.append('banner',this.companyInfo.banner);
            

        const result = await axios
                            .post(UpdateCompanyInfo,data)
                            .catch(error => {
                                console.log(error)
                            });
            this.resetImg("logo");
            this.resetImg("banner");
            this.isUpdate = false;
            if(result.data.status == "success"){
                this.getData(false);
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
            } else if(result.data.status == "401"){
                alertify.set('notifier','position', 'top-right');
                alertify.success(`${result.data.message}`);
                localStorage.removeItem('posUser');
                this.$router.push('/Unauthenticated');
            } else if(result.data.status == 'is_permission_no'){
                this.getData(true);
                alertify.set('notifier','position', 'top-right');
                alertify.error(`You are no permission to do this stuff!`);
            } else if(result.data.status == "error"){
                this.getData(false);
                alertify.set('notifier','position', 'top-right');
                alertify.error(`${result.data.message}`);
            } else {
                console.log(result)
            }
    },
    async getData(check = true){
      if(localStorage.getItem('posUser')){
        config();
        axios
            .get(`${userInfo}`)
            .then(response => {
            if(response.data.status === 'success'){
                
                this.pageLoad = false;
                // console.log(response.data.companyInfo)
                this.companyInfo.logo = response.data.companyInfo.logo;
                this.companyInfo.banner = response.data.companyInfo.banner;

                this.en_name = response.data.companyInfo.en_name;
                this.kh_name = response.data.companyInfo.kh_name;
                this.email  = response.data.companyInfo.email;
                this.address  = response.data.companyInfo.address ? response.data.companyInfo.address : '';
                this.phone_line1 = response.data.companyInfo.phone_line1;
                this.phone_line2 = response.data.companyInfo.phone_line2;
                this.phone_line3 = response.data.companyInfo.phone_line3;
                this.phone_line4 = response.data.companyInfo.phone_line4;
                this.logo = response.data.companyInfo.logo;
                this.banner = response.data.companyInfo.banner;
                this.lat = response.data.companyInfo.latitude;
                this.lng = response.data.companyInfo.longitude
                this.invoice_header = response.data.companyInfo.invoice_header;
                this.invoice_footer = response.data.companyInfo.invoice_footer;

                //permission
                this.list = response.data.list;
                this.create = response.data.create;
                this.deleteAll = response.data.delete;
                this.edit = response.data.edit;

                this.isTrue = false;
                
            } else if(RES.data.status === 401) {
                localStorage.removeItem('posUser');
                this.$router.push('/Unauthenticated');
            } else if(response.data.status == "is_permission_no"){
                this.permision_no = true;
            }
            else {
                console.log('something wrong with api');
            }
            })
            .catch(error => {
                console.log(error)
            }
            );
      } else {
        this.$router.push('/Unauthenticated')
      }
    },
  },
  created(){
      this.getData();
  },
  mounted(){
    $("#Setting").addClass("menu-open");
    $("#Setting>a").addClass("active");
    $("#Setting>ul").css({'display' : 'block'});
    $("[id='Company Info']").addClass("active");
  }
}
</script>

<style>

</style>