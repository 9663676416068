<template>
    <content-header title="Inventory" icon="fa fa-angle-double-right"/>
    <content>
      <loading v-if="isTrue"/>
      <div v-else class="container-fluid">
        <div class="card">



            <div class="card-header">
                <div class="row">
                    <div class="col-lg-7 col-12">
                        <div class="row my-3 mx-0">
                            <div v-if="table.data.companies" class="col-lg-8 col-sm-8 d-sm-block d-none d-flex justify-content-center align-content-center">
                                <div class="row" style="box-sizing: border-box;">
                                    <div class="col-lg-2 col-sm-12 mr-lg-3 mb-3 display-phone">
                                        <img v-if="table.data.companies.logo" :src="resource + table.data.companies.logo" width="100" class="rounded-circle">
                                    </div>
                                    <div class="col-lg-8 col-sm-12 display-phone">
                                        <span class="kh_muol p-0 d-block"> {{ table.data.companies.kh_name }}</span>
                                        <span class="kh_content p-0 d-block"> {{ table.data.companies.en_name }}</span>
                                        <span class="kh_content p-0 d-block">Address ៖ {{ table.data.companies.address }}</span>
                                        <span class="kh_content p-0">Telephone ៖ {{ table.data.companies.phone_line1 }}</span>
                                        <span v-if="table.data.companies.phone_line2"  class="kh_content p-0 ">, {{ table.data.companies.phone_line2 }}</span>
                                        <span v-if="table.data.companies.phone_line3"  class="kh_content p-0 ">, {{ table.data.companies.phone_line3 }}</span>
                                        <span v-if="table.data.companies.phone_line4"  class="kh_content p-0 ">, {{ table.data.companies.phone_line4 }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-12">
                        <table class="table table-bordered table-lg">
                            <thead>
                                <tr class="text-center table-success">
                                    <th colspan="3"><h4 class="mb-0">Summary</h4></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Total Original Price</td>
                                    <td>$ {{ formatToCurrency(totalOriginalPrice,2) }}</td>
                                </tr>
                                <tr>
                                    <td>Total On Hand (qty)</td>
                                    <td>{{ totalQty }}</td>
                                </tr>
                                <tr class="table-warning">
                                    <td>Grand Total Original Price</td>
                                    <td>$ {{ formatToCurrency(totalPrice,2) }}</td>
                                </tr>
                                <tr>
                                    <td>Total Etimated Sale</td>
                                    <td>$ {{ formatToCurrency(totalEtimatedPrice,2) }}</td>
                                </tr>
                                <tr>
                                    <td>Total Etimated Profit</td>
                                    <td>$ {{ formatToCurrency(totalEtimatedProfitPrice,2) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
  
    
  
  
  
          <div class="card-body">

  
  
            <!-- table  -->
            <div  class="table-responsive-sm position-relative">
              <table class="table data-table table-sm table-bordered" style="width: 100%">
                <thead class="bg-system text-light">
                  <tr>
                    <th style="width:50px" class="d-none"></th>
                    <th style="width:50px">#</th>
                    <th>Product</th>
                    <th>Original Price</th>
                    <th>On Hand</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody v-if="table.data.data.length != 0">
                  <template v-for="(data, index) in table.data.data" :key="index">
                    <tr :id="`tr${index}table`">
                      <td class="d-none"></td>
                      <td :data-col="'#'+(index+1)"> {{ index + 1 }}</td>
                      <td data-col="Product">         <button :data-row = "`tr${index}table`" onclick="toggleRow(this)" class="clicker btn btn-xs bg-system text-white rounded-circle">
                          <i class="fas fa-plus-circle"></i>
                        </button> {{ data.name }}</td>
                      <td data-col="Original Price">{{ '$ ' + formatToCurrency(data.original_price,2) }}</td>
                      <td data-col="On Hand">{{ data.onhand }}</td>
                      <td data-col="Total Price">{{ '$ ' + formatToCurrency(data.original_price * data.onhand,2) }}</td>
                    </tr> 
                  </template>  
                    
                </tbody>
              </table>
            </div>
  

            <!-- Processing loader  -->
            <div v-if="pageLoad" class="card position-absolute " style="z-index:10; left: 45%; top: 50%;">
                <div class="card-body">
                <span class="txt-system">Processing...</span>
                </div>
            </div>

            <!-- no data -->
            <div v-if="table.data.data.length == 0" class="card">
                <div class="card-body text-center">
                  <span class="txt-system">No Data...</span>
                </div>
            </div>
  
  
          </div>
        </div>
      </div>
    </content>
    <content-header/>
  </template>
  
  <script>
  import axios from 'axios'
  import { resource } from '@/networks/domain'
  import { inventory } from '@/networks/services/pos/reports/inventory.service'
  import { checkPermission } from '@/networks/services/helper/helper.js'
  import Loading from '@/components/loading/Loading'
  import { config } from '@/networks/config'
  
  
  
  export default {
    components: { 
      Loading,
    },
    data(){
      return {
        table : {
          data: [],
        },

        fromDate : '',
        toDate : '',
        check_date : 'today',
        resource : resource,
  
        permission_no: false,
        isTrue: true,
        pageLoad: false,
        create: false,
        deleteAll : false,
        edit: false,
        list: false,
      }
    },
    computed : {
        totalOriginalPrice(){
            return this.table.data.data.reduce(function(prev, cur) {
                return prev + +cur.original_price;
            }, 0);
        },
        totalQty(){
            return this.table.data.data.reduce(function(prev, cur) {
                return prev + +cur.onhand;
            }, 0);
        },
        totalPrice(){
            return this.table.data.data.reduce(function(prev, cur) {
                return prev + (+cur.onhand * +cur.original_price);
            }, 0);
        },
        totalEtimatedPrice(){
            return this.table.data.data.reduce(function(prev, cur) {
                return prev + (+cur.onhand * +cur.unit_price);
            }, 0);
        },
        totalEtimatedProfitPrice(){
            return +this.totalEtimatedPrice - +this.totalPrice;
        }
    },
    methods : {
        formatToCurrency(amount,fixed = 2){
            if(fixed > 0){
                let luy = 0;
                if(Number(amount) < 0){
                    luy = -Number(amount);
                } else {
                    luy = Number(amount);
                }
                return Number(luy).toFixed(fixed).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
            } else {
                let luy = (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                let new_luy = luy.split(".");
                return new_luy[0]; 
            }
        },
        clicker_date(){
            $('#reservation').val('');
            this.fromDate = '';
            this.toDate = '';
            this.pageLoad = true;
            this.getData(false);
        },
        clickRangeDate(){
            this.check_date = '',
            this.fromDate = $('#reservation').data('daterangepicker').startDate.format("YYYY-MM-DD");
            this.toDate = $('#reservation').data('daterangepicker').endDate.format("YYYY-MM-DD");
            this.pageLoad = true;
            this.getData(false);
        },
        async reloadSelectOption(){
            await this.$nextTick();
            $('.selectPicker').selectpicker('refresh');
            $('.selectPicker').selectpicker();
        },
        async getData(id){
            if(localStorage.getItem('posUser')){
                config();
                axios
                    .get(`${inventory()}`)
                    .then(response => {
                    if(response.data.status === 'success'){
                        if(!id){
                            this.pageLoad = false;
                        
                            //table
                            this.table.data = response.data; 

                            //permission
                            this.list = response.data.list;
                            this.create = response.data.create;
                            this.deleteAll = response.data.delete;
                            this.edit = response.data.edit;

                           
                            this.isTrue = false;

                        }
                    } else if(response.data.status === 401) {
                        alertify.set('notifier','position', 'top-right');
                        alertify.error(`${response.data.message}`);
                        localStorage.removeItem('posUser');
                        this.$router.push('/Unauthenticated');
                    } else if(response.data.status == "is_permission_no"){
                        this.permision_no = true;
                    }
                    else {
                        console.log('something wrong with api');
                    }
                    })
                    .then(()=>{
                        this.reloadSelectOption();
                       
                    })
                    .catch(error => {
                            console.log(error)
                        }
                    );
            } else {
                this.$router.push('/Unauthenticated')
            }
        },
    },
    created(){
        this.getData(false);
    },
    mounted(){
      
    }
  }
  </script>
  
  <style>
  
  </style>